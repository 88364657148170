import {useEffect, useState} from 'react'
import {LineDivider} from '../../../../reusable/line_divider/LineDivider'
import './SuperAdmin__ParticipantDetails.scss'
import * as Yup from 'yup'

// import {ParticipantContactInfo as ContactInfo} from './components/contact_info/ParticipantContactInfo'
import {ContactInfo as ContactInfoEdit} from '../../../../voter/components/registration/ContactInfo'
import {ParticipantContactInfo as ContactInfo} from './components/contact_info/ParticipantContactInfo'

import {ParticipantLocationInfo as LocationInfo} from './components/location_info/ParticipantLocationInfo'
import {ParticipantPhotoInfo as PhotoInfo} from './components/photo_info/ParticipantPhotoInfo'
import {ParticipantDuplication as DuplicationInfo} from './components/profile_duplication/ParticipantDuplication'
import {useHistory, useParams} from 'react-router-dom'
import AdminVoterAuthService from '../../../../../services/auth/AdminVoterAuthService'
import {Spinner} from 'react-bootstrap-v5'
import {CustomBtn} from '../../../../reusable/custom_btn/CustomBtn'
import {UserInfo} from '../../../../voter/components/registration/UserInfo'
import {
  initialValues,
  step1Schema,
  step1SchemaWithSingleName,
  step2SchemaDefault,
  step2SchemaSecondary,
} from '../../../../voter/pages/VoterRegisterSchema'
import {useFormik} from 'formik'
import CheckBox from '../../../../reusable/check_box/CheckBox'
import {AddressInfo} from '../../../../voter/components/registration/AddressInfo'
import {MailingInfo} from '../../../../voter/components/registration/MailingInfo'
import {UploadPhotoID} from '../../../../voter/components/registration/UploadPhotoID'
import FormikError from '../../../../reusable/formik_error/FormikError'
import {StatusBox} from '../../../../../resuable/status_box/StatusBox'
import AdminVoterService from '../../../../../services/admin/AdminVoterService'
import {BackBtn} from '../../../../reusable/back_btn/BackBtn'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {SurveyResponses} from './views/SurveyResponses'
import {EditVoter} from './views/EditVoter'
import {QRCodeModal} from './components/qrcode/QRCodeModal'
import {beforeUnload} from '../../../../../App'
import {AdminDropdown} from '../../../../voter_admin/reusable/admin_dropdown/AdminDropdown'
import {EditUserModal} from '../../../../voter_admin/pages/nova_scotia/electors_list/edit_user_drodown/EditUserModal'

interface RouteParams {
  voterId: string
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'scroll',
  },
}

export const SuperAdmin__ParticipantDetails = () => {
  const history = useHistory()
  // Explicitly define the type of parameters
  const {voterId} = useParams<RouteParams>()
  const [voter, setVoter] = useState(null)
  const [currentView, setCurrentView] = useState('default')
  const [singleName, setSingleName] = useState(false)
  const [formSchema, setFormSchema] = useState({})
  const [modalView, setModalView] = useState('')
  const [modalIsOpen, setIsOpen] = useState(false)
  const [voterTerms, setVoterTerms] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [onFormatBirthDate, setOnFormatBirthDate] = useState({})
  const [onHasPropertyIgnace, setOnHasPropertyIgnace] = useState(false)
  const [loading, setLoading] = useState(false)
  const [qrCodeModalIsOpen, setQRCodeModalIsOpen] = useState(false)
  const [editUserModal, setEditUserModal] = useState(false)
  const [editUserModalView, setEditUserModalView] = useState('Edit')

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      // setLoading(true)
      setTimeout(async () => {
        try {
          const userInput = {
            // USER INFO
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            singleName: values.singleName ? true : false,
            // BIRTH DATE
            birthMonth: values.birthMonth,
            birthDay: values.birthDay,
            birthYear: values.birthYear,
            dateOfBirth: new Date(`${values.birthDay} ${values.birthMonth} ${values.birthYear}`),

            // CONTACT INFO
            email: values.email,
            confirmEmail: values.confirmEmail,
            phone: values.phoneNumber,

            // RECEIVE SMS
            receiveSMSNotif: values.receiveSMSNotif,

            // RESIDENTIAL ADDRESS
            address: {
              streetAddress: values.streetAddress,
              city: values.city,
              province: values.province,
              postalCode: values.postalCode,
              unitNumberPOBox: values.unitNumber,
            },

            // MAILING ADDRESS
            mailingAddress: {
              streetAddress: values.mailingAddress,
              city: values.mailingCity,
              province: values.mailingProvince,
              postalCode: values.mailingPostalCode,
              unitNumberPOBox: values.mailingUnitNumber,
            },

            // Q/ TERMS: Have you moved to or purchased property in Ignace since November 2023?
            movedOrPurchacedThisYear: values.propertyTerms ? values.propertyTerms : false,

            // photoIDs: [values.photoID, values.photoID2],
          }

          const formData = new FormData()
          for (var key in userInput) {
            if (key === 'address' || key === 'mailingAddress') {
              // append nested object
              for (let addressKey in userInput[key]) {
                formData.append(`${key}[${addressKey}]`, userInput[key][addressKey])
              }
            } else {
              formData.set(key, userInput[key])
            }
          }
          formData.append('photoIDs', values.photoID)
          if (values.photoID2) {
            formData.append('photoIDs', values.photoID2)
          }

          console.log('updating voter', userInput)

          // RegisterUser.setRegistrationUser({formData, user: userInput})
          // history.push('/voting/register-complete')
          // setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  const hasSingleName = () => {
    return formik.values.firstName.length > 0 && formik.values.lastName.length == 0
  }

  const hasMailingAddress = () => {
    return onHasPropertyIgnace
  }

  const getFormSchema = () => {
    let combinedSchema = {}

    // CASE 1 - Normal Name + Residential Address
    if (!hasSingleName() && !hasMailingAddress()) {
      console.log('CASE 1 - Normal Name + Residential Address')
      combinedSchema = Yup.object().shape({
        ...step1Schema.fields,
        ...step2SchemaDefault.fields,
      })
    }

    // CASE 2 - Normal Name + Residential Address + Mailing Address
    if (!hasSingleName() && hasMailingAddress()) {
      console.log('CASE 2 - Normal Name + Residential Address + Mailing Address')
      combinedSchema = Yup.object().shape({
        ...step1Schema.fields,
        ...step2SchemaSecondary.fields,
      })
    }
    // CASE 3 - Single Name + Residential Address
    if (hasSingleName() && !hasMailingAddress()) {
      console.log('CASE 3 - Single Name + Residential Address')
      combinedSchema = Yup.object().shape({
        ...step1SchemaWithSingleName.fields,
        ...step2SchemaDefault.fields,
      })
    }

    // CASE 4 - Single Name + Residential Address + Mailing Address
    if (hasSingleName() && hasMailingAddress()) {
      console.log('CASE 4 - Single Name + Residential Address + Mailing Address')
      combinedSchema = Yup.object().shape({
        ...step1SchemaWithSingleName.fields,
        ...step2SchemaSecondary.fields,
      })
    }

    return combinedSchema
  }

  const getVoterDetails = async () => {
    try {
      let voterDetails = await AdminVoterAuthService.getVoterDetails({voterId})

      setVoter(voterDetails)

      setFormikValues(voterDetails)
    } catch (error) {
      console.log(error)
    }
  }

  const setFormikValues = (voter) => {
    // USER INFO
    formik.values.firstName = voter.voterInfo.firstName
    formik.values.middleName = voter.voterInfo.middleName
    formik.values.lastName = voter.voterInfo.lastName
    formik.values.singleName = voter.voterInfo.singleName

    // BIRTH INFO
    formik.values.birthMonth = getMonthName(voter.voterInfo.monthBorn)
    formik.values.birthDay = voter.voterInfo.dayBorn
    setOnFormatBirthDate({
      monthBorn: voter.voterInfo.monthBorn,
      monthDay: voter.voterInfo.dayBorn,
    })

    formik.values.birthYear = voter.voterInfo.yearBorn
    formik.values.dateOfBirth = new Date(
      `${formik.values.birthDay} ${formik.values.birthMonth} ${formik.values.birthYear}`
    )

    // CONTACT INFO
    formik.values.email = voter.email
    formik.values.confirmEmail = voter.email
    formik.values.phoneNumber = voter.phoneNumber

    // RECEIVE SMS
    formik.values.receiveSMSNotif = true

    // RESIDENTIAL ADDRESS
    formik.values.streetAddress = voter.voterInfo.address.streetAddress
    formik.values.city = voter.voterInfo.address.city
    formik.values.province = voter.voterInfo.address.province
    formik.values.postalCode = voter.voterInfo.address.postalCode
    formik.values.unitNumberPOBox = voter.voterInfo.address.unitNumberPOBox

    setOnHasPropertyIgnace(false)
    formik.values.propertyTerms = null

    // MAILING ADDRESS (if the voter has a mailing address)
    if (voter.voterInfo.mailingAddress.city.length > 0) {
      setOnHasPropertyIgnace(true)
      formik.values.propertyTerms = voter.voterInfo.movedOrPurchacedThisYear
      formik.values.mailingAddress = voter.voterInfo.mailingAddress.streetAddress
      formik.values.mailingCity = voter.voterInfo.mailingAddress.city
      formik.values.mailingProvince = voter.voterInfo.mailingAddress.province
      formik.values.mailingPostalCode = voter.voterInfo.mailingAddress.postalCode
      formik.values.mailingUnitNumber = voter.voterInfo.mailingAddress.unitNumberPOBox
    }

    // PHOTO ID
    formik.values.photoID = voter.voterInfo.identification

    // VOTER TERMS
    formik.values.voterTerms = true
    setVoterTerms(true)

    // SET FORM SCHEMA
    let schema = getFormSchema()
    setFormSchema(schema)
  }

  function getMailingAddress(voter) {
    return voter.voterInfo.mailingAddress.fullAddress === ', , , '
      ? null
      : voter.voterInfo.mailingAddress.fullAddress
  }

  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    // Check if the month number is valid (between 1 and 12)
    if (monthNumber >= 1 && monthNumber <= 12) {
      // Subtract 1 because array indices start from 0
      return months[monthNumber - 1]
    } else {
      return 'Invalid month number'
    }
  }

  const handleDecline = async (user) => {
    setLoading(true)
    await AdminVoterService.declineVoter(user.voterInfo._id)
    await getVoterDetails()
    setLoading(false)
  }

  const handleApprove = async (user) => {
    setLoading(true)
    await AdminVoterService.approveVoter(user.voterInfo._id)
    await getVoterDetails()
    setLoading(false)
  }

  const handleTestUserApprove = async (user) => {
    setLoading(true)
    await AdminVoterService.approveTestVoter(user.voterInfo._id)
    await getVoterDetails()
    setLoading(false)
  }

  useEffect(() => {
    getVoterDetails()
  }, [voterId, currentView])

  useEffect(() => {
    console.log('formik.errors', formik.errors)
  }, [formik.errors])

  if (loading) {
    return <div className='SuperAdmin__ParticipantDetails'>Loading..</div>
  }

  if (!voter) {
    return <Spinner animation={'border'} />
  }

  if (currentView == 'survey-responses') {
    return <SurveyResponses voter={voter} setCurrentView={setCurrentView} />
  }

  if (currentView == 'edit') {
    return (
      <EditVoter
        formik={formik}
        voter={voter}
        onFormatBirthDate={onFormatBirthDate}
        onHasPropertyIgnace={onHasPropertyIgnace}
        setOnHasPropertyIgnace={setOnHasPropertyIgnace}
        voterTerms={voterTerms}
        setVoterTerms={setVoterTerms}
        setCurrentView={setCurrentView}
        loading={loading}
        getFormSchema={() => {
          getFormSchema()
        }}
        setFormSchema={setFormSchema}
      />
    )
  }
  console.log(voter)
  return (
    <div className='SuperAdmin__ParticipantDetails' id='Voter'>
      <QRCodeModal
        modalOpen={qrCodeModalIsOpen}
        setModalOpen={setQRCodeModalIsOpen}
        username={voter.username}
      />
      <EditUserModal
        isOpen={editUserModal}
        onRequestClose={() => {
          setEditUserModal(false)
        }}
        style={customStyles}
        modalView={editUserModalView}
        userToEdit={voter}
        getUsers={getVoterDetails}
      />
      <div className='participantDetails__header'>
        <BackBtn
          navigateTo={'/admin/electors-list'}
          customOnBack={() => {
            window.removeEventListener('beforeunload', beforeUnload)
            window.close()
          }}
          customStyling={{marginBottom: 29}}
        />
        {/* Survey Repsonse Btn */}
        {/* <div
          className='participantDetails__header__surveyBtn'
          onClick={() => {
            setCurrentView('survey-responses')
          }}
        >
          <img
            alt='question text icon'
            src={toAbsoluteUrl('/media/design/icon_question_text.svg')}
          />
          <span className='participantDetails__header__surveyBtn__label'>Survey Response</span>
        </div> */}
      </div>

      {/* Body */}
      <div className='participantDetails__body'>
        {/* Header */}
        <div className='participantDetails__body__header'>
          <div className='participantDetails__body__header__nameContainer'>
            <span className='participantDetails__body__header__voterName'>
              {voter.voterInfo.firstName + ' ' + voter.voterInfo.lastName}
            </span>

            <div className='participantDetails__body__header__statusWrap'>
              {voter.voterInfo.status === 'Approved' && (
                <StatusBox label='Approved' color='#08B055' />
              )}

              {voter.voterInfo.status === 'Pending' && (
                <StatusBox label='Pending' color='#3c1b98' />
              )}

              {voter.voterInfo.status === 'Declined' && (
                <StatusBox label='Declined' color='#FFA800' />
              )}
              {voter.voteReceipts?.length > 0 && <StatusBox label='Voted' color='#08B055' />}

              {/* TODO: ONBOARDED & VOTED fields below: */}
              {/* <StatusBox label='Onboarded' color='#3c1b98' /> */}
              {/* <StatusBox label='Voted' color='#08B055' /> */}
            </div>
          </div>

          <span className='participantDetails__body__header__voterID'>
            Voter ID: <strong>{voter.voterId}</strong>
            <br />
            Username: <strong>{voter.username}</strong>
          </span>

          <span>
            <AdminDropdown
              view='electorDecision'
              user={voter}
              onCallback={(value) => {
                if (value == 'Edit') {
                  setEditUserModalView(value)
                  setEditUserModal(true)
                }

                if (value === 'Resend access email') {
                  if (
                    !voter.keycloakId ||
                    (voter.voterInfo && voter.voterInfo?.status !== 'Approved')
                  ) {
                    alert('User not approved')
                  } else {
                    setEditUserModalView(value)
                    setEditUserModal(true)
                  }
                }
              }}
            />
          </span>
        </div>

        {/* {voter.voterInfo.status === 'Approved' && (
          <>
            <LineDivider />
            <div className='qrcode-btn-container'>
              <CustomBtn
                view='ActionBtn'
                text={'Generate Auth QR Code'}
                height={48}
                type='button'
                onClick={() => {
                  setQRCodeModalIsOpen(true)
                }}
              />
            </div>
          </>
        )} */}
        <LineDivider />
        <ContactInfo dob={voter.voterInfo.dateOfBirth} phone={voter.phone} email={voter.email} />

        <LineDivider />

        <LocationInfo
          resAddress={voter.voterInfo.address.fullAddress}
          mailAddress={getMailingAddress(voter)}
        />

        <LineDivider />

        <PhotoInfo photos={voter.voterInfo.identification} />

        {voter.duplicates && voter.duplicates.length ? (
          <>
            <LineDivider />
            <DuplicationInfo profiles={voter.duplicates} />
          </>
        ) : null}

        {/* Footer */}
        {/* TODO: handle voted */}
        <div className='ModalFooter'>
          {voter.voterInfo.status === 'Pending' && (
            <div style={{marginRight: 15}}>
              <CustomBtn
                view='PrimaryBtn'
                text={'Decline'}
                height={48}
                onClick={() => {
                  handleDecline(voter)
                }}
              />
            </div>
          )}

          {/* <CustomBtn
            view='PrimaryBtn'
            text={'Edit'}
            height={48}
            customStyling={{marginLeft: 25, marginRight: 25}}
            onClick={() => {
              setCurrentView('edit')
            }}
          /> */}
          {voter.voterInfo.status === 'Pending' && (
            <div style={{marginRight: 15}}>
              <CustomBtn
                view='ActionBtn'
                text={'Approve'}
                height={48}
                onClick={() => {
                  handleApprove(voter)
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
