import {useContext, useEffect, useState} from 'react'
import Modal from 'react-modal'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import TextField from '../../../../../../modules/auth/reusable/TextField'
import {ModalHeader} from '../../../../../modals/ModalHeader'
import {ModalBtn} from '../../../../../modals/voter_modal/ModalBtn'
import './EditUserModal.scss'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import AdminVoterService from '../../../../../../services/admin/AdminVoterService'
import CustomAdminDropdown from '../../../../reusable/admin_dropdown/AdminFormDropDown'
import {useTranslation} from '../../../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'auto',
  },
}

const initialValues = {
  email: '',
  phone: '',
}

export function EditUserModal(props) {
  const {translate, translateObj} = useTranslation()

  const {userToEdit, modalView, getUsers} = props

  const [onlineEvents, setOnlineEvents] = useState([])
  const [userIsNotValid, setUserIsNotValid] = useState(false)
  const [eventId, setEventId] = useState('')
  const [roleErr, setRoleErr] = useState(false)
  const [updateErr, setupdateErr] = useState(false)

  const schema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    phone: Yup.string().required('Phone is required'),
  })

  // function isEmpty(obj) {
  //   return Object.keys(obj).length === 0
  // }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      // uncomment if eventId required for updating voters

      // if (!eventId) {
      //   setRoleErr(true)
      //   return
      // }

      let {voterId} = userToEdit

      let response = await AdminVoterService.updateVoter({
        voterId,
        email: values.email,
        phone: values.phone,
        updateType: modalView == 'Edit' ? 'edit' : 'email',
        // tiviId: eventId,
      })
      // just add error checking here
      // console.log('update user: ', response)
      if (response.success) {
        props.onRequestClose()
        getUsers()
      } else {
        setupdateErr(true)
      }
      resetComponent()
    },
  })

  const getOnlineEvents = async () => {
    console.log('userToEdit.district', userToEdit.district)

    const res = await AdminVoterService.getOnlineEventsList(userToEdit.district)
    console.log('res', res)

    if (res) {
      //@ts-ignore
      let events = res.map((e) => {
        return {...e, value: e.tiviId, label: translateObj(e.name)}
      })

      console.log('events', events)
      setOnlineEvents(events)
    }
  }

  const resetComponent = async () => {
    console.log('userToEdit', userToEdit)
    formik.values.email = userToEdit.email
    formik.values.phone = userToEdit.phone
    setEventId('')
    setRoleErr(false)
    setupdateErr(false)
    await getOnlineEvents()
  }

  useEffect(() => {
    console.log('userToEdit.votingChannel', userToEdit.district)

    resetComponent()

    Modal.setAppElement('#Voter')
  }, [props.isOpen])

  useEffect(() => {
    if (roleErr && eventId) {
      setRoleErr(false)
    }
    setupdateErr(false)
  }, [formik.values.email, formik.values.phone, eventId])

  if (userToEdit.votingChannel == 'inPerson') {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={props.isOpen}
        onAfterOpen={props.onAfterOpen}
        onRequestClose={props.onRequestClose}
        style={customStyles}
      >
        <div className='ModalWrapper' style={{flexGrow: 1}}>
          <ModalHeader onRequestClose={props.onRequestClose} title={''} />

          {/* BODY */}
          <div className='EditUserModal__error'>
            <img
              className='EditUserModal__error__icon'
              src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
            />
            <span className='EditUserModal__error__txt'>
              {translate('In person voters cannot be updated')}
            </span>
          </div>

          <div className='ModalFooter'>
            <ModalBtn
              view='ActionBtn'
              text={translate('Ok')}
              onClick={() => {
                setUserIsNotValid(false)
                props.onRequestClose()
              }}
            />
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        <ModalHeader
          onRequestClose={props.onRequestClose}
          title={
            (modalView == 'Edit' ? `${translate('Edit Voter')} -` : translate('Resend email to')) +
            ` ${userToEdit.username}`
          }
        />

        {/* BODY */}
        <div className='EditUserModal'>
          <div className='mb-5'>
            <TextField
              formik={formik}
              type='email'
              field='email'
              placeholder={translate('Email')}
              label={translate('Email')}
              isRequired={true}
              view='voter'
              disabled={modalView == 'Edit' ? false : true}
            />{' '}
          </div>
          <div className='mb-5'>
            <TextField
              formik={formik}
              type='text'
              field='phone'
              placeholder={translate('Phone')}
              label={translate('Phone')}
              isRequired={true}
              view='voter'
              disabled={modalView == 'Edit' ? false : true}
            />
          </div>

          {/* <CustomAdminDropdown
            isRequired={true}
            options={onlineEvents}
            value={eventId}
            setValue={setEventId}
            title={translate('Event')}
            isForm={false}
          /> */}

          {formik.touched['email'] && formik.errors['email'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate(formik.errors['email'])}
                </span>
              </div>
            </div>
          )}

          {formik.touched['phone'] && formik.errors['phone'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate(formik.errors['phone'])}
                </span>
              </div>
            </div>
          )}

          {roleErr && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate('Event is required')}
                </span>
              </div>
            </div>
          )}
          {updateErr && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate('Error updating user')}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='ModalFooter'>
          <ModalBtn
            view='CancelBtn'
            text={translate('Cancel')}
            onClick={() => {
              props.onRequestClose()
            }}
          />

          <ModalBtn
            view='ActionBtn'
            text={translate(
              modalView == 'Edit' ? translate('Update User') : translate('Send Email')
            )}
            onClick={() => {
              formik.submitForm()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
