import React, {useState, useEffect, useRef} from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import config from '../../../config'
const apiKey = config.GOOGLE_PLACES_API_KEY

const GooglePlacesAutoCompleteInput = (props) => {
  const {
    formik,
    field,
    placeholder,
    label,
    isRequired,
    noLabel,
    types = [],
    width = '100%',
    view = 'default',
    onSelect,
    hideErrorLabel,
  } = props
  const [value, setValue] = useState(formik.values[field])

  if (view == 'voter') {
    return (
      <div>
        {!noLabel && (
          <label
            className={
              isRequired
                ? 'form-label fs-6 fw-bold text-dark required'
                : 'form-label fs-6 fw-bold text-dark'
            }
          >
            {label}
          </label>
        )}
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            componentRestrictions: {country: 'ca'},
            types,
          }}
          selectProps={{
            onChange: (newValue) => {
              console.log('newValue', newValue)

              formik.setFieldValue(field, newValue.label)
              setValue(newValue)

              props.onSelect(newValue)
            },
            placeholder: value ? value : placeholder,
            name: field,
            value,
            styles: {
              container: (provided) => ({
                ...provided,
                background: '#FFF',
                borderRadius: 8,
                border: '1px solid #000',
              }),
              control: (provided) => ({
                ...provided,
                'height': 45,
                'paddingLeft': 10,
                'borderRadius': 8,
                'background': '#fff',

                'width': width,

                'color': '#3f4254',

                'fontSize': 16,
                'fontWeight': '400',

                '&:focus': {
                  outline: 'none',
                },
              }),
              input: (provided) => ({
                ...provided,
                fontSize: 16,
                fontWeight: '500',
                borderRadius: 8,
              }),
              placeholder: (provided) => ({
                ...provided,
                fontSize: 16,
                color: '#a1a5b7',
              }),
              menu: (provided) => ({
                ...provided,
              }),
            },
            theme: (theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary: '#f5f8fa',
              },
            }),
          }}
          apiKey={apiKey}
        />
        {!hideErrorLabel && (
          <div>
            {formik.touched[field] && formik.errors[field] && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors[field]}</span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      {!noLabel && (
        <label
          className={
            isRequired
              ? 'form-label fs-6 fw-bold text-dark required'
              : 'form-label fs-6 fw-bold text-dark'
          }
        >
          {label}
        </label>
      )}
      <GooglePlacesAutocomplete
        autocompletionRequest={{
          componentRestrictions: {country: 'ca'},
          types,
        }}
        selectProps={{
          onChange: (newValue) => {
            console.log('newValue', newValue)

            formik.setFieldValue(field, newValue.label)
            setValue(newValue)
          },
          placeholder: value ? value : placeholder,
          name: field,
          value,
          styles: {
            container: (provided) => ({
              ...provided,
              background: '#f5f8fa',
              borderRadius: 8,
            }),
            control: (provided) => ({
              ...provided,
              'height': 45,
              'paddingLeft': 15,
              'borderRadius': 8,
              'background': '#f5f8fa',

              'border': 'none',
              'width': '100%',
              'color': '#3f4254',

              'fontSize': 14,
              'fontWeight': '500',

              '&:focus': {
                outline: 'none',
              },
            }),
            input: (provided) => ({
              ...provided,
              fontSize: 14,
              fontWeight: '500',
              borderRadius: 8,
            }),
            placeholder: (provided) => ({
              ...provided,
              fontSize: 14,
              color: '#a1a5b7',
            }),
            menu: (provided) => ({
              ...provided,
            }),
          },
          theme: (theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary: '#f5f8fa',
            },
          }),
        }}
        apiKey={apiKey}
      />
      {formik.touched[field] && formik.errors[field] && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>{formik.errors[field]}</span>
        </div>
      )}
    </div>
  )
}

export default GooglePlacesAutoCompleteInput
