export const languageValueToKey = {
  Spanish: 'es',
  English: 'en',
  French: 'fr',
}
export const languageKeyToValue = {
  es: 'Spanish',
  en: 'English',
  fr: 'French',
}
