import {createContext, useContext, useEffect, useState} from 'react'

export const SuperAdminUserContext = createContext()

export const SuperAdminUserProvider = ({children}) => {
  const [adminUser__currentTab, setAdminUser__currentTab] = useState('Users')
  const [adminUser__userStatus, setAdminUser__userStatus] = useState('Active')
  const [adminUser, setAdminUser] = useState(null)
  const [tiviLink, setTiviLink] = useState('')

  const clearSuperAdminUserContext = () => {
    setAdminUser__currentTab('')
  }

  return (
    <SuperAdminUserContext.Provider
      value={{
        adminUser__currentTab,
        setAdminUser__currentTab,
        adminUser__userStatus,
        setAdminUser__userStatus,
        adminUser,
        setAdminUser,
        tiviLink,
        setTiviLink,
      }}
    >
      {children}
    </SuperAdminUserContext.Provider>
  )
}

const PROVIDER_ERROR = 'useSuperAdminUserContext must be used within a SuperAdminUserProvider'

export const useSuperAdminUserContext = () => {
  const context = useContext(SuperAdminUserContext)
  if (!context) {
    throw new Error(PROVIDER_ERROR)
  }
  return context
}

export default SuperAdminUserContext
