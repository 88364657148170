/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import './MEXICO__PhoneVerify.scss'
import '../../../../voter/pages/ConfirmCode.scss'
import '../../../../voter/pages/VerifyMethod.scss'

import '@djthoms/pretty-checkbox'
import VerificationInput from 'react-verification-input'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import {VoterOnboardingContext} from '../../../../../contexts/VoterOnboardingContext'

import {ProgressBar} from './reusable/ProgressBar'
import './Common.scss'
import './MEXICO__AuthVerify.scss'
import {VoterLoginFlowContext} from '../../../../../contexts/VoterLoginFlowContext'
import {VoterAuthContext} from '../../../../../contexts/VoterAuthContext'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {ProgressBarMobile} from './reusable/ProgressBarMobile'
import VoterAuthService from '../../../../../services/auth/VoterAuthService'
import OnlineVoterAuthService from '../../../../../services/auth/OnlineVoterAuthService'
import {VoterUserContext} from '../../../../../contexts/VoterUserContext'
import AdminVoterAuthService from '../../../../../services/auth/AdminVoterAuthService'
import {useTranslation} from '../../../../../hooks/useTranslation'
import RecapatchaDisclaimer from '../../../../../resuable/recapatcha/RecapatchaDisclaimer'

export function MEXICO__AuthVerify(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(null)
  const {customBannerImg} = useVoterStyles()
  const {authMethod, setAuthMethod} = useContext(VoterOnboardingContext)
  const factorSid = props.location?.state?.factorSid

  const [notValid, setNotValid] = useState(true)

  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)

  const {currentLanguage} = useContext(LanguageContext)
  const [invalidCodeErr, setInvalidCodeErr] = useState(false)

  const verifyVoterOnbaording = async () => {
    let resp
    if (authMethod === 'SMS') {
      resp = await OnlineVoterAuthService.verifySMSCode(code)
    } else if (authMethod === 'Email') {
      resp = await OnlineVoterAuthService.verifyEmailCode(code)
    } else {
      resp = await OnlineVoterAuthService.verifyAuthAppQRCode(code)
    }
    if (resp.success) {
      history.push('/voting/voter-onboarding/create-password')
    } else if (resp.error && resp.error.message === 'Invalid code') {
      setInvalidCodeErr(true)
    }
  }

  const verifyAdminLogin = async () => {
    let resp = await AdminVoterAuthService.verifyAuthAppCode(code)
    if (resp.error && resp.error.message === 'Invalid code') {
      setInvalidCodeErr(true)
    }
    // else automatic redirect
  }

  const verifyAdminOnboarding = async () => {
    // 2nd step verify sms & setup auth app
    if (authMethod === 'SMS') {
      let resp = await AdminVoterAuthService.verifySMSCodeOnboard(code)
      if (resp.error && resp.error.message === 'Invalid code') {
        setInvalidCodeErr(true)
      }
      if (resp.success) {
        history.push('/admin/auth/auth-app-setup', {
          qrCodeDataUrl: resp.qrCodeDataUrl,
          factorSid: resp.factorSid,
        })
      }
    }
    // 3rd step verify auth app
    else {
      let resp = await AdminVoterAuthService.verifyAuthAppCodeOnboard(code, factorSid)
      if (resp.error && resp.error.message === 'Invalid code') {
        setInvalidCodeErr(true)
      }
      if (resp.passwordResetRequired) {
        history.push('/admin/auth/change-password', {flow: 'Onboarding'})
      }
    }
  }

  const verifyAdminForgotPassword = async () => {
    let resp = await AdminVoterAuthService.verifyAuthAppCodePasswordResetFlow(code)
    if (resp.success) {
      history.push('/admin/auth/change-password', {flow: 'Forgot Password'})
    }
    if (resp.error && resp.error.message === 'Invalid code') {
      setInvalidCodeErr(true)
    }
  }

  const verifyVoterForgotPassword = async () => {
    let resp
    if (authMethod === 'SMS') {
      resp = await VoterAuthService.verifySMSCodePasswordResetFlow(code)
    } else if (authMethod === 'Email') {
      resp = await VoterAuthService.verifyEmailCodePasswordResetFlow(code)
    } else {
      resp = await VoterAuthService.verifyAuthAppCodePasswordResetFlow(code)
    }
    if (resp.success) {
      history.push('/voting/voter-auth/change-password', {flow: 'Forgot Password'})
    }
    if (resp.error) {
      setInvalidCodeErr(true)
    }
  }

  const verifyVoterLogin = async () => {
    let resp
    if (authMethod === 'SMS') {
      resp = await VoterAuthService.verifySMSCode(code)
    } else if (authMethod === 'Email') {
      resp = await VoterAuthService.verifyEmailCode(code)
    } else {
      resp = await VoterAuthService.verifyAuthAppCode(code)
    }
    if (resp.error) {
      setInvalidCodeErr(true)
    }
    // else if  successful user will be logged in and redirected to dashboard
  }

  const onClickVerify = async () => {
    switch (VOTER_LOGIN_CONTEXT.loginFlow) {
      case 'Onboarding':
        verifyVoterOnbaording()
        break
      case 'Admin Login':
        verifyAdminLogin()
        break
      case 'Admin Onboarding':
        verifyAdminOnboarding()
        break
      case 'Admin Forgot Password':
        verifyAdminForgotPassword()
        break
      case 'Forgot Password':
        verifyVoterForgotPassword()
        break
      default:
        verifyVoterLogin()
        break
    }
  }

  const onClickSendSMS = () => {
    setAuthMethod('SMS')

    switch (VOTER_LOGIN_CONTEXT.loginFlow) {
      case 'Onboarding':
        OnlineVoterAuthService.sendSMSCode(currentLanguage)
        break
      // TODO: Add endpoint to resend SMS admin
      // case 'Admin Onboarding':

      // case 'Admin Forgot Password':

      case 'Forgot Password':
        VoterAuthService.sendSMSCodePasswordResetFlow(currentLanguage)
        break

      default:
        // default for voters, note admin only use auth app
        VoterAuthService.sendSMSCode(currentLanguage)
        break
    }
  }

  const onClickSendEmail = () => {
    setAuthMethod('Email')

    switch (VOTER_LOGIN_CONTEXT.loginFlow) {
      case 'Onboarding':
        OnlineVoterAuthService.sendEmailCode()
        break
      case 'Forgot Password':
        VoterAuthService.sendEmailCodePasswordResetFlow()
        break

      default:
        // default for voters, note admin only use auth app
        VoterAuthService.sendEmailCode()
        break
    }
  }

  useEffect(() => {
    // disabled button handler
    if (code.length == 6) {
      setNotValid(false)
    }

    setInvalidCodeErr(false)
  }, [code])

  return (
    <div className='VerifyMethod' id='Voter'>
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />
        <div
          // style={currentLanguage == 'French' ? {paddingBottom: 100} : {}}
          className={
            VOTER_LOGIN_CONTEXT.loginFlow === 'Onboarding' && currentLanguage === 'English'
              ? 'authVerify__form '
              : VOTER_LOGIN_CONTEXT.loginFlow === 'Onboarding' && currentLanguage !== 'English'
              ? 'authVerify__form--spanishLoginFlow'
              : currentLanguage !== 'English'
              ? 'authVerify__form--spanishLoginFlow'
              : 'authVerify__form--loginFlow'
          }
        >
          {VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding' && <ProgressBar currentStep={3} />}
          {VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding' && <ProgressBarMobile currentStep={3} />}

          {/*  */}

          <div
            className={
              VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding'
                ? 'authVerify__form__body'
                : 'authVerify__form__body--loginFlow'
            }
          >
            <div className='verifyMethod__formHeader'>
              <span className='verifyMethod__formHeaderTitle'>
                {translate('Login verification')}
              </span>

              <div className='verifyMethod__formHeaderTxtWrap'>
                <span className='authVerify__form__body__subtitle'>
                  {authMethod == 'SMS'
                    ? translate('Enter the 6 digit verification code sent to your phone number')
                    : authMethod === 'Email'
                    ? translate('Enter the 6 digit verification code sent to your email')
                    : translate(
                        'Enter the 6 digit verification code shown in the authentication app installed on your phone'
                      )}
                </span>
              </div>
            </div>

            <div className='phoneVerify__inputsWrap'>
              <VerificationInput
                autoFocus={true}
                inputProps={{autoComplete: 'one-time-code'}}
                value={code}
                onChange={setCode}
                validChars='0-9'
                length={6}
                classNames={{
                  container: 'TextField__confirmCode__container',
                  character: 'TextField__confirmCode',
                  characterInactive: 'TextField__confirmCode__inactive',
                  characterSelected: 'TextField__confirmCode__selected__MEXICO',
                }}
              />

              {codeError && (
                <div className='voterRegister__singleInputErr confirmCode__errorWrap'>
                  <span className='TextField__voterErrTxt' role='alert'>
                    {translate('You must enter the entire code!')}
                  </span>
                </div>
              )}
            </div>

            {invalidCodeErr && (
              <div className='authVerify__invalidCode'>
                <img
                  className='authVerify__invalidCode__icon'
                  src='/media/design/icon_x_circle_dark_red.svg'
                />
                <span className='authVerify__invalidCode__txt'>{translate(`Invalid code`)}.</span>
              </div>
            )}
            {!VOTER_LOGIN_CONTEXT?.loginFlow?.startsWith('Admin') && (
              <div className='confirmCode__formFooter'>
                <span className='phoneVerify__formFooter__disclaimerTxt'>
                  {authMethod === 'SMS' || authMethod === 'Email' ? (
                    <>
                      {translate('Didn’t get it?')}{' '}
                      <span
                        className='phoneVerify__formFooter__disclaimerLink'
                        onClick={() =>
                          authMethod === 'Email' ? onClickSendEmail() : onClickSendSMS()
                        }
                      >
                        {translate('Send again')}
                      </span>
                    </>
                  ) : (
                    <>
                      {translate('Do not have an auth app?')}{' '}
                      <span
                        className='phoneVerify__formFooter__disclaimerLink'
                        onClick={onClickSendSMS}
                      >
                        {translate('Send SMS instead')}
                      </span>
                    </>
                  )}
                </span>{' '}
              </div>
            )}
          </div>
          {/*  */}

          {VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding' ? (
            <div className='phoneVerify__form__actionBar'>
              <button
                className={
                  notValid ? 'authMethodLogin__nextBtn disabled' : 'authMethodLogin__nextBtn'
                }
                onClick={onClickVerify}
                disabled={notValid}
              >
                {translate('Next')}
              </button>
            </div>
          ) : (
            <div className={'authVerify__form__actionBar--loginFlow'}>
              <button
                className={notValid ? 'common__loginBtn disabled' : 'common__loginBtn'}
                onClick={onClickVerify}
                disabled={notValid}
              >
                {!VOTER_LOGIN_CONTEXT.loginFlow?.includes('Onboarding')
                  ? translate('Login')
                  : translate('Next')}
              </button>
            </div>
          )}
        </div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt400' view='mobile' />
    </div>
  )
}
