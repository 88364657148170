/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import * as Yup from 'yup'
import './pagination.scss'

import FeatherIcon from 'feather-icons-react'
import TextField from '../../../../../modules/auth/reusable/TextField'
import {AdminDropdown} from '../../../reusable/admin_dropdown/AdminDropdown'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {FilterModal} from './FilterModal'
import {ModalWrapper} from '../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'

import AdminVoterService from '../../../../../services/admin/AdminVoterService'
import {EmailSendModal} from './EmailSendModal'
import KeycloakAdminService from '../../../../../../KeycloakAdminService'

import {EditUserModal} from './edit_user_drodown/EditUserModal'
import ReactPaginate from 'react-paginate'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {districts} from '../../../../../portalData/Districts'

import '../../../../super_admin/mexico/pages/admin_users/MEXICO__SuperAdmin__Users.scss'
import '../../nova_scotia/address_list/AdminAddressList.scss'
import '../../nova_scotia/electors_list/AdminElectorsList.scss'
import {ViewIdModal} from './view_id_modal/ViewIdModal'
import {useHistory} from 'react-router-dom'
import {VoterDecisionModal} from './voter_decision_modal/VoterDecisionModal'
import PDFViewerPage from '../../../../../modules/reusable/pdf_viewer/PdfViewerPage'
import {beforeUnload} from '../../../../../App'

const NUM_VOTERS_PER_PAGE = 20
export const initialValues = {}

export const loginSchema = Yup.object().shape({})

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'scroll',
  },
}
export function ElectorsListTable(props) {
  const {translate, translateObj} = useTranslation()

  const history = useHistory()
  const tiviTest = KeycloakAdminService.hasRole('tivi-test')

  const [filterModal, setFilterModal] = useState(false)
  const [emailFilterModal, setEmailFilterModal] = useState(false)
  const [thanksModal, setThanksModal] = useState(false)
  const [downloadURL, setDownloadURL] = useState(null)
  const [downloadingList, setDownloadingList] = useState(false)
  const [districtFilter, setDistrictFilter] = useState('all')
  const [channelFilter, setChannelFilter] = useState('all')
  const [users, setUsers] = useState([])
  // total number of users
  const [numUsers, setNumUsers] = useState(0)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('all')
  const [currentPageNum, setCurrentPageNum] = useState(0)
  const [onlineEvents, setOnlineEvents] = useState([])
  const [editUserModal, setEditUserModal] = useState(false)
  const [userToEdit, setUserToEdit] = useState({email: ''})
  const [voterToEditId, setVoterToEditId] = useState(null)
  const [userToView, setUserToView] = useState({voterInfo: {identification: []}})
  const [editUserModalView, setEditUserModalView] = useState('Edit')
  const [decisionModalOpen, setDecisionModalOpen] = useState(false)
  const [decisionModalView, setDecisionModalView] = useState('decline')
  const [isViewIdModalOpen, setIsViewIdModalOpen] = useState(false)
  const [sort, setSort] = useState('asc')

  // const [fileToView, setFileToView] = useState(null)
  const [pdfViewURL, setPdfViewURL] = useState(null)

  const viewOnly =
    !KeycloakAdminService.hasRole('tivi-administrator') &&
    !KeycloakAdminService.hasRole('tivi-administration-module-administrator')

  const getUsers = async () => {
    let resp = await AdminVoterService.fetchVoters(
      districtFilter,
      channelFilter,
      NUM_VOTERS_PER_PAGE,
      currentPageNum * NUM_VOTERS_PER_PAGE,
      search,
      status,
      sort
    )
    if (resp?.users) {
      console.log('resp?.users', resp?.users)
      //@ts-ignore
      setUsers(resp.users)
      setNumUsers(resp.numUsers)
    }
  }
  useEffect(() => {
    getUsers()
  }, [districtFilter, channelFilter, status, sort, currentPageNum])

  useEffect(() => {
    setCurrentPageNum(0)
    getUsers()
  }, [search])

  const getDowloadUrl = async () => {
    const res = await AdminVoterService.getVoterCsv(districtFilter, channelFilter)
    if (res?.success) {
      // @ts-ignore
      const href = URL.createObjectURL(res.data)
      setDownloadURL(href)
      const link = document.createElement('a')
      let districtName = districts.find((o) => o.value === districtFilter)?.label || districtFilter
      link.download = `voterlist-${districtName}-${channelFilter}`
      link.href = href
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setDownloadingList(false)
  }

  // get online events for email
  useEffect(() => {
    const getOnlineEvents = async () => {
      const res = await AdminVoterService.getOnlineEventsList(districtFilter)
      if (res) {
        //@ts-ignore
        let events = res.map((e) => {
          return {...e, value: e.tiviId, label: translateObj(e.name)}
        })
        setOnlineEvents(events)
      }
    }
    getOnlineEvents()
  }, [districtFilter, channelFilter])

  const sendEmailsOnlineDistrict = async (tivId) => {
    const res = await AdminVoterService.sendEmailsOnlineVoters(tivId)
    if (res.success) {
      setEmailFilterModal(false)
      alert(translate('Success'))
    } else {
      alert('Error')
    }
  }

  const handleApprove = async ({voter}) => {
    setVoterToEditId(voter.voterInfo?._id)
    await AdminVoterService.approveVoter(voter.voterInfo?._id)
    getUsers()
    // Show approval modal
    setDecisionModalView('approve')
    setDecisionModalOpen(true)
  }
  const handleDecline = async ({voter}) => {
    setVoterToEditId(voter.voterInfo?._id)

    // Show approval modal
    setDecisionModalView('decline')
    setDecisionModalOpen(true)
  }

  const onViewPDF = async ({url}) => {
    const encodedPDFURL = encodeURIComponent(url)
    history.push(`/admin/view-pdf/${encodedPDFURL}`)
  }

  function hasPdfExtension(filename) {
    return filename.toLowerCase().endsWith('.pdf')
  }

  return (
    <div id='Voter'>
      {/* MODAL */}
      {/* <PropertyInfoModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      /> */}
      {/* <VoterInfoModal
        isOpen={voterInfoModal}
        onAfterOpen={afterOpenVoterModal}
        onRequestClose={() => {
          setVoterInfoModal(false)
        }}
        style={customStyles}
      /> */}

      <VoterDecisionModal
        onModalOpen={decisionModalOpen}
        onModalClose={() => {
          setDecisionModalOpen(false)
        }}
        modalView={decisionModalView}
        setModalView={setDecisionModalView}
        voterId={voterToEditId}
        onGetUsers={() => {
          getUsers()
        }}
      />

      <ViewIdModal
        modalOpen={isViewIdModalOpen}
        onClose={() => {
          setIsViewIdModalOpen(false)
          setUserToView({voterInfo: {identification: []}})
        }}
        identification={userToView.voterInfo?.identification}
      />
      <EditUserModal
        isOpen={editUserModal}
        onRequestClose={() => {
          setEditUserModal(false)
        }}
        style={customStyles}
        modalView={editUserModalView}
        userToEdit={userToEdit}
        getUsers={getUsers}
      />
      <ModalWrapper
        isOpen={thanksModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setThanksModal(false)
        }}
        style={customStyles}
        modalView={downloadingList ? 'Downloading' : 'Downloaded'}
        headerLabel={translate('Export Electors List')}
        downloadURL={downloadURL}
        districtFilter={districtFilter}
        channelFilter={channelFilter}
      />

      <FilterModal
        isOpen={filterModal}
        // onAfterOpen={after}
        onRequestClose={() => {
          setFilterModal(false)
        }}
        style={customStyles}
        onUpdateFilter={(district, channel, status) => {
          setDistrictFilter(district)
          setChannelFilter(channel)
          setStatus(status)
        }}
        districtFilter={districtFilter}
        channelFilter={channelFilter}
        statusFilter={status}
      />
      <EmailSendModal
        isOpen={emailFilterModal}
        // onAfterOpen={after}
        onRequestClose={() => {
          setEmailFilterModal(false)
        }}
        style={customStyles}
        onSend={sendEmailsOnlineDistrict}
        options={onlineEvents}
      />
      {/* CONSOLE */}
      <div className='voterAddrList__tableContanier'>
        {/* TABLE */}
        <div className='voterAddrList__table'>
          <div className='voterAddrList__table__actionBar'>
            <TextField
              type='text'
              field='search'
              placeholder=''
              label=''
              isRequired={false}
              noLabel={true}
              view='admin_searchbar-no-formik'
              customStyling={{width: 230, marginRight: 25}}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />{' '}
            <div />
            <div>
              <img
                className='voterAddrList__table__actionBar__filterIcon'
                src={toAbsoluteUrl('/media/design/refresh.svg')}
                alt='refresh'
                onClick={() => getUsers()}
              />
              {/* <span
                style={{
                  marginRight: 15,
                  marginLeft: 15,
                  cursor: 'pointer',
                  fontSize: 14,
                }}
              >
                <select
                  name='sort'
                  id='sort-select'
                  onChange={(e) => setSort(e.target.value)}
                  value={sort}
                >
                  <option value='name'>Sort By Name</option>
                  <option value='asc'>Sort By Registration Date Ascending</option>
                  <option value='desc'>Sort By Registration Date Descending</option>
                </select>
              </span> */}
              {!tiviTest && (
                <img
                  onClick={() => {
                    setFilterModal(true)
                  }}
                  className='voterAddrList__table__actionBar__filterIcon'
                  src={toAbsoluteUrl('/media/design/icon_funnel.svg')}
                  alt='filter'
                />
              )}
              {!viewOnly && (
                <img
                  onClick={() => {
                    setDownloadingList(true)
                    setThanksModal(true)
                    getDowloadUrl()
                  }}
                  className='voterAddrList__table__actionBar__downloadIcon'
                  src={toAbsoluteUrl('/media/design/icon_file_download.svg')}
                  alt='download'
                />
              )}
              {/* {!viewOnly && (
                <img
                  onClick={() => {
                    setEmailFilterModal(true)
                  }}
                  className='voterAddrList__table__actionBar__downloadIcon'
                  src={toAbsoluteUrl('/media/design/icon_email.svg')}
                  alt='email'
                />
              )} */}
            </div>
          </div>

          <div className='voterAddrList__tableHeader'>
            {/* <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--1'>
              Voter ID
            </span> */}
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--2'>
              {translate('Username')}
            </span>
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--3'>
              {translate('Email')}
            </span>
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--4'>
              {translate('Phone')}
            </span>
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--4'>
              {translate('Jurisdiction')}
            </span>

            {/* 
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--5'>
              {translate('Address')}
            </span> */}

            {/* <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--6'>
              {translate('Status')}
            </span> */}
            {/* <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--4'>
              {translate('Username')}
            </span> */}
            {/* <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--6'>
              {translate('DOB')}
            </span> */}

            {/* <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--7'>
              Status
            </span> */}
            {(KeycloakAdminService.hasRole('tivi-administrator') ||
              KeycloakAdminService.hasRole('tivi-administration-module-administrator') ||
              KeycloakAdminService.hasRole('tivi-contactinfo')) && (
              <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--8'>
                {translate('Action')}
              </span>
            )}
          </div>

          <div className='voterAddrList__tableBody'>
            {users.map((user) => (
              <div className='voterAddrList__tableBody__row'>
                {/* <span
                  className='electorListTable__rowItem--1 '
                  onClick={() => {
                    setVoterInfoModal(true)
                  }}
                >
                  123455
                </span> */}

                <span className='electorListTable__rowItem--2'>
                  {/* {user.voterInfo?.firstName}{' '}
                  {user.voterInfo?.lastName ? user.voterInfo?.lastName : ''} */}
                  {user.username}
                </span>
                <span className='electorListTable__rowItem--3'>{user.email}</span>
                <span className='electorListTable__rowItem--4'>{user.phoneNumber}</span>
                <span className='electorListTable__rowItem--4'>
                  {' '}
                  {translate(
                    districts.find((o) => o.value === user.district)?.label || user.district
                  )}
                </span>
                {/* <span className='electorListTable__rowItem--5'>
                  {user.voterInfo?.address?.city?.toLowerCase()?.includes('ignace')
                    ? user.voterInfo?.address?.fullAddress
                    : user.voterInfo?.mailingAddress?.city?.toLowerCase()?.includes('ignace')
                    ? user.voterInfo?.mailingAddress.fullAddress
                    : user.voterInfo?.address?.fullAddress}
                </span> */}
                {/* <span className='electorListTable__rowItem--6'>
                  {' '}
                  <span
                    className={`importAddrList__statusLabel ${
                      user.voterInfo?.status === 'Approved'
                        ? 'importAddrList__statusLabel--success '
                        : user.voterInfo?.status === 'Declined'
                        ? 'importAddrList__statusLabel--failed '
                        : 'importAddrList__statusLabel--na '
                    }`}
                  >
                    {user.voteReceipts?.length > 0 ? 'Voted' : user.voterInfo?.status}
                  </span>
                </span> */}
                {/* <span className='electorListTable__rowItem--4'>{user.username}</span> */}
                {/* <span className='electorListTable__rowItem--6'>
                  {' '}
                  <span className={`importAddrList__statusLabel`}>
                    {user.voterInfo?.dateOfBirth}
                  </span>
                </span> */}
                {/* <div className='electorListTable__rowItem--7'>
                  <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
                    Voted
                  </span>
                </div> */}

                {(KeycloakAdminService.hasRole('tivi-administrator') ||
                  KeycloakAdminService.hasRole('tivi-administration-module-administrator') ||
                  KeycloakAdminService.hasRole('tivi-contactinfo')) && (
                  <div className='column-width-5 column-width-5--adminUserDropdown'>
                    <AdminDropdown
                      view='electorDecision'
                      user={user}
                      onCallback={(value) => {
                        if (value == 'Approve') {
                          handleApprove({voter: user})
                        }

                        if (value == 'Decline') {
                          handleDecline({voter: user})
                        }

                        if (value == 'Edit') {
                          setUserToEdit(user)
                          setEditUserModalView(value)
                          setEditUserModal(true)
                        }

                        if (value === 'Resend access email') {
                          if (
                            !user.keycloakId ||
                            (user.voterInfo && user.voterInfo?.status !== 'Approved')
                          ) {
                            alert(translate('User not approved'))
                          } else {
                            setUserToEdit(user)
                            setEditUserModalView(value)
                            setEditUserModal(true)
                          }
                        }

                        if (value == 'View ID') {
                          console.log('user to view', user)

                          let photoIdURL = user.voterInfo?.identification[0]

                          if (hasPdfExtension(photoIdURL)) {
                            onViewPDF({url: photoIdURL})
                          } else {
                            setUserToView(user)
                            setIsViewIdModalOpen(true)
                          }
                        }

                        if (value == 'View') {
                          if (user.voterInfo) {
                            setUserToView(user)
                            window.removeEventListener('beforeunload', beforeUnload)
                            window.open(
                              `/admin/participants/${user?.voterInfo?._id}`,
                              '_blank',
                              'noreferrer'
                            )
                            // history.push(`/admin/participants/${user?.voterInfo?._id}`)
                            // setIsViewIdModalOpen(true)
                          } else {
                            alert('User not viewable')
                          }
                        }
                      }}
                    />{' '}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* footer */}
      <div className='voterAddrList__footer'>
        <span className='voterAddrList__footer__txt'>
          {translate('Showing')}{' '}
          <span className='voterAddrList__footer__txt__value'>
            {Math.min(currentPageNum * NUM_VOTERS_PER_PAGE + 1, numUsers)}
          </span>{' '}
          {translate('to')}{' '}
          <span className='voterAddrList__footer__txt__value'>
            {Math.min(currentPageNum * NUM_VOTERS_PER_PAGE + NUM_VOTERS_PER_PAGE, numUsers)}
          </span>{' '}
          {translate('of')} <span className='voterAddrList__footer__txt__value'>{numUsers}</span>{' '}
          {translate('electors')}
        </span>

        <ReactPaginate
          breakLabel='...'
          nextLabel={<FeatherIcon icon={'chevron-right'} color='#000' size='18' />}
          onPageChange={(event) => {
            setCurrentPageNum(event.selected)
          }}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={Math.ceil(numUsers / NUM_VOTERS_PER_PAGE)}
          previousLabel={<FeatherIcon icon={'chevron-left'} color='#000' size='18' />}
          renderOnZeroPageCount={null}
          containerClassName='pagination-container pagination-bar'
          pageClassName='pagination-item'
          activeClassName='selected'
          previousClassName='voterAddrList__footer__pagination__chevron--left'
          nextClassName='voterAddrList__footer__pagination__chevron--right'
          breakClassName='pagination-item dots'
          forcePage={currentPageNum}
        />
      </div>
    </div>
  )
}
