import {useContext} from 'react'
import {translate, translateEventName} from '../languages/translate'
import {LanguageContext} from '../contexts/LanguageContext'

export const useTranslation = () => {
  const languageContext = useContext(LanguageContext)

  const translateString = (string) => {
    return translate(string, languageContext)
  }

  const translateObj = (obj) => {
    return translateEventName(obj, languageContext)
  }

  return {translate: translateString, translateObj}
}
