import moment from 'moment-timezone'
import 'moment/locale/fr-ca'

export const formatDateLocale = (datetime, LANGUAGE_CONTEXT) => {
  datetime = moment(datetime)
  if (LANGUAGE_CONTEXT.currentLanguage === 'French') {
    moment.locale('fr-ca')
    return moment(datetime).locale('fr-ca').format('LLL')
  }
  if (LANGUAGE_CONTEXT.currentLanguage === 'English') {
    return moment(datetime).locale('en').format('LLL')
  }
}
