/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext, useRef} from 'react'
import FeatherIcon from 'feather-icons-react'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import '../../../../pages/voter_admin/pages/nova_scotia/address_list/AddressList.scss'
import './AdminDropdown.scss'
import {useSuperAdminUserContext} from '../../../../contexts/SuperAdminUserContext'
import VoterAuthService from '../../../../services/auth/VoterAuthService'
import KeycloakService from '../../../../../Keycloak'
import districtOptions from './districtOptions'
import AdminVoterAuthService from '../../../../services/auth/AdminVoterAuthService'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import KeycloakAdminService from '../../../../../KeycloakAdminService'
import AdminVoterService from '../../../../services/admin/AdminVoterService'
import {districts} from '../../../../portalData/Districts'
import {useTranslation} from '../../../../hooks/useTranslation'

const statusFilterOptions = [
  {value: 'All', label: 'All'},
  {value: 'Approved', label: 'Approved'},
  {value: 'Pending', label: 'Pending'},
  {value: 'Declined', label: 'Declined'},
]

const languageOptions = [
  {value: 'English', label: 'English'},
  // {value: 'French', label: 'French'},
]

const electorActionOptions = [
  // {value: 'Approve', label: 'Approve'},
  {value: 'View', label: 'View'},
  {value: 'Edit', label: 'Edit'},
  // {value: 'Decline', label: 'Decline'},
  // {value: 'View ID', label: 'View ID'},
  {value: 'Resend access email', label: 'Resend access email'},
]

const mexicoDistrictOptions = [{value: 'All', label: 'All'}, ...districts]

const channelOptions = [
  {value: 'All', label: 'All'},
  {value: 'inPerson', label: 'In-Person'},
  {value: 'online', label: 'Online'},
]

const statusOptions = [
  {value: 'All', label: 'All'},
  {value: 'Voted', label: 'Voted'},
  {value: 'N/A', label: 'N/A'},
]

const mexicoAdminUserOptions = [
  // {value: 'User Details', label: 'User Details'},
  {value: 'Reset Password', label: 'Reset Password'},
  {value: 'Regenerate QR Code', label: 'Regenerate QR Code'},
  {value: 'Unlock Account', label: 'Unlock Account'},
  {value: 'Delete User', label: 'Delete User'},
]
const editDeleteOptions = [
  {value: 'Edit', label: 'Edit'},
  {value: 'Delete', label: 'Delete'},
]

const mexicoAdmin__restoreAccess__options = [{value: 'Restore Access', label: 'Restore Access'}]

export function AdminDropdown(props) {
  const {translate} = useTranslation()

  let {view, customStyling, onCallback, data, customOptionStyling} = props

  let viewOnly = true
  const isAdmin = KeycloakAdminService?.isLoggedIn()
  if (
    isAdmin &&
    (KeycloakAdminService.hasRole('tivi-administrator') ||
      KeycloakAdminService.hasRole('tivi-administration-module-administrator'))
  ) {
    viewOnly = false
  }

  const {
    adminUser__currentTab,
    setAdminUser__currentTab,
    adminUser__userStatus,
    setAdminUser__userStatus,
  } = useSuperAdminUserContext()

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const [openTopicsDropdown, setOpenTopicsDropdown] = useState(false)
  const topicOpRef = useRef(null)
  const [value, setValue] = useState(view == 'districts' ? 'All Districts' : 'All')

  const getCurrentLanguageLabel = (languageValue) => {
    var lang = languageOptions.find((item) => item?.value === languageValue)
    return lang?.label || 'English'
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openTopicsDropdown && topicOpRef.current && !topicOpRef.current.contains(e.target)) {
        setOpenTopicsDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openTopicsDropdown])

  const districts = (
    <div>
      <div
        style={customStyling}
        className='addressList__districtDropdown '
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      >
        <span className={'createPostModal_optionTxt'}>value</span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {openTopicsDropdown && (
        <div className='navbar__dropdown addressList__districtDropdown__options' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {districtOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setValue(topic.label)
                      setOpenTopicsDropdown(false)
                    }}
                  >
                    <span className='createPostModal_optionTxt'>{translate(topic.label)}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const statusFilter = (
    <div>
      <span className={'createPostModal_optionTxt'}>{translate('Status')}</span>

      <div
        style={customStyling}
        className='addressList__districtDropdown '
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      >
        {' '}
        <span className={'createPostModal_optionTxt'}>{value}</span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {openTopicsDropdown && (
        <div className='navbar__dropdown addressList__districtDropdown__options' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {statusFilterOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setValue(topic.value)
                      setOpenTopicsDropdown(false)
                      props.setStatusFilter(topic.value)
                    }}
                  >
                    <span className='createPostModal_optionTxt'>{topic.label}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const languages = (
    <div className='adminDropdown__container--languages'>
      <div
        style={customStyling}
        className=' adminDropdown--languages'
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      >
        <span className={'adminDropdown__option__txt'}>
          {translate(getCurrentLanguageLabel(LANGUAGE_CONTEXT.currentLanguage))}
        </span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {openTopicsDropdown && (
        <div className='navbar__dropdown adminDropdown__options--languages ' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column '>
              {languageOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='adminDropdown__optionWrap'
                    onClick={() => {
                      LANGUAGE_CONTEXT.setCurrentLangauge(topic.value)
                      localStorage.setItem('language', topic.value)
                      // setValue(topic.label)
                      setOpenTopicsDropdown(false)
                      if (KeycloakService.isLoggedIn()) {
                        VoterAuthService.updateUserLanguage(topic.value)
                      }
                      if (KeycloakAdminService.isLoggedIn()) {
                        AdminVoterService.updateUserLanguage(topic.value)
                      }
                    }}
                  >
                    <span className=' adminDropdown__option__txt'>{translate(topic.label)}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const electorDecision = (
    <div
      className='adminDropdown__mexicoAdminUser'
      onClick={() => {
        setOpenTopicsDropdown(true)
      }}
    >
      <FeatherIcon icon={'more-horizontal'} color='#5E6278' />

      {openTopicsDropdown && (
        <div className='navbar__dropdown dropdown__txtWrap--mexicoAdminUser ' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap '>
            <div
              className='civik-flex-column adminDropdown__mexicoAdminUser__option'
              style={customOptionStyling}
            >
              {electorActionOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='adminDropdown__optionWrap adminDropdown__optionWrap--mexicoAdminUser'
                    onClick={async () => {
                      switch (topic.value) {
                        case 'Approve':
                          await onCallback('Approve')
                          setOpenTopicsDropdown(false)
                          break
                        case 'View':
                          onCallback('View')
                          setOpenTopicsDropdown(false)
                          break
                        case 'Decline':
                          await onCallback('Decline')
                          setOpenTopicsDropdown(false)

                          break
                        case 'View ID':
                          onCallback('View ID')
                          setOpenTopicsDropdown(false)
                          break
                        case 'Resend access email':
                          onCallback('Resend access email')
                          setOpenTopicsDropdown(false)
                          break
                        case 'Edit':
                          onCallback('Edit')
                          setOpenTopicsDropdown(false)
                          break
                        default:
                          // Handle the default case if needed
                          break
                      }
                    }}
                  >
                    <span className=' adminDropdown__option__txt'>{translate(topic.label)}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const customDropDown = (
    <div
      className='adminDropdown__mexicoAdminUser'
      onClick={() => {
        setOpenTopicsDropdown(true)
      }}
    >
      <FeatherIcon icon={'more-horizontal'} color='#5E6278' />

      {openTopicsDropdown && (
        <div className='navbar__dropdown dropdown__txtWrap--mexicoAdminUser ' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap '>
            <div
              className='civik-flex-column adminDropdown__mexicoAdminUser__option'
              style={customOptionStyling}
            >
              {props.dropdownOptions &&
                props.dropdownOptions?.map((topic, index) => {
                  return (
                    <div
                      key={index}
                      className='adminDropdown__optionWrap adminDropdown__optionWrap--mexicoAdminUser'
                      onClick={async () => {
                        onCallback(topic.value)
                        setOpenTopicsDropdown(false)
                      }}
                    >
                      <span className=' adminDropdown__option__txt'>{translate(topic.label)}</span>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const mexicoDistricts = (
    <div>
      <span className={'createPostModal_optionTxt'}>{translate('District')}</span>
      <div
        style={{width: '258.61px', marginRight: 25}}
        className='addressList__districtDropdown '
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      >
        <span className={'createPostModal_optionTxt'}>
          {translate(
            mexicoDistrictOptions.find((o) => o.value === props.districtFilter)?.label || 'All'
          )}
        </span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {openTopicsDropdown && (
        <div className='navbar__dropdown  adminDropdown__mexicoDistricts__option' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {mexicoDistrictOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setValue(topic.label)
                      setOpenTopicsDropdown(false)
                      props.setDistrictFilter(topic.value)
                    }}
                  >
                    <span className='createPostModal_optionTxt'>{translate(topic.label)}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const channel = (
    <div>
      <span className={'createPostModal_optionTxt'}>{translate('Channel')}</span>
      <div
        style={{width: '258.61px', marginRight: 25}}
        className='addressList__districtDropdown '
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      >
        <span className={'createPostModal_optionTxt'}> {translate(props.channelFilter)}</span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {openTopicsDropdown && (
        <div className='navbar__dropdown adminDropdown__mexicoChannel__option' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {channelOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setValue(topic.label)
                      setOpenTopicsDropdown(false)
                      props.setChannelFilter(topic.value)
                    }}
                  >
                    <span className='createPostModal_optionTxt'>{translate(topic.label)}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const status = (
    <div>
      <span className={'createPostModal_optionTxt'}>Status</span>
      <div
        style={{width: '258.61px', marginRight: 25}}
        className='addressList__districtDropdown '
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      >
        <span className={'createPostModal_optionTxt'}>{value}</span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {openTopicsDropdown && (
        <div className='navbar__dropdown addressList__districtDropdown__options' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {statusOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setValue(topic.label)
                      setOpenTopicsDropdown(false)
                    }}
                  >
                    <span className='createPostModal_optionTxt'>{topic.label}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const mexicoAdminUser = (
    <div
      className='adminDropdown__mexicoAdminUser'
      onClick={() => {
        setOpenTopicsDropdown(true)
      }}
    >
      <FeatherIcon icon={'more-horizontal'} color='#5E6278' />

      {openTopicsDropdown && (
        <div className='navbar__dropdown dropdown__txtWrap--mexicoAdminUser ' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap '>
            <div className='civik-flex-column adminDropdown__mexicoAdminUser__option'>
              {mexicoAdminUserOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='adminDropdown__optionWrap adminDropdown__optionWrap--mexicoAdminUser'
                    onClick={async () => {
                      // if (topic.value == 'User Details') {
                      //   setAdminUser__userStatus('Active')
                      //   setAdminUser__currentTab('User Details')
                      // }
                      let resp
                      switch (topic.value) {
                        case 'Reset Password':
                          if (
                            window.confirm(
                              `${translate('Reset Password for')} ${props.user.username}`
                            )
                          ) {
                            resp = await AdminVoterAuthService.superAdminResetPasswordForAdminUser(
                              props.keycloakId
                            )
                            if (resp.success) {
                              alert(translate('Success'))
                            }
                            props.fetchUsers()
                          }
                          setOpenTopicsDropdown(false)
                          break
                        case 'Unlock Account':
                          if (
                            window.confirm(
                              `${translate('Unlock Account for')} ${props.user.username}`
                            )
                          ) {
                            resp = await AdminVoterAuthService.superAdminUnlockAccountFoAdminUser(
                              props.keycloakId
                            )
                            if (resp.success) {
                              alert(translate('Success'))
                            }
                            props.fetchUsers()
                          }
                          setOpenTopicsDropdown(false)
                          break
                        case 'Regenerate QR Code':
                          if (
                            window.confirm(
                              `${translate('Regenerate QR Code for')} ${props.user.username}`
                            )
                          ) {
                            resp =
                              await AdminVoterAuthService.superAdminRegenerateQRCodeForAdminUser(
                                props.keycloakId
                              )
                            if (resp.success) {
                              alert(translate('Success'))
                            }
                            props.fetchUsers()
                          }
                          setOpenTopicsDropdown(false)
                          break
                        case 'Delete User':
                          if (
                            window.confirm(`${translate('Delete User')} ${props.user.username}`)
                          ) {
                            resp = await AdminVoterAuthService.superAdminDeleteUser(
                              props.keycloakId
                            )
                            if (resp.success) {
                              alert(translate('Success'))
                            }
                            props.fetchUsers()
                          }
                          setOpenTopicsDropdown(false)
                          break
                      }
                    }}
                  >
                    <span className=' adminDropdown__option__txt'>{translate(topic.label)}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const mexicoAdminRestoreAccess = (
    <div>
      <FeatherIcon
        icon={'more-horizontal'}
        color='#5E6278'
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      />

      {openTopicsDropdown && (
        <div className='navbar__dropdown dropdown__txtWrap--mexicoAdminUser ' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap '>
            <div className='civik-flex-column '>
              {mexicoAdmin__restoreAccess__options.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='adminDropdown__optionWrap adminDropdown__optionWrap--mexicoAdminUser'
                    onClick={() => {
                      setAdminUser__userStatus('Active')
                      setAdminUser__currentTab('User Details')
                      setOpenTopicsDropdown(false)
                    }}
                  >
                    <span className=' adminDropdown__option__txt'>{topic.label}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const EditDelete = (
    <>
      <img
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
        className='helpSupport__footer__elipsisEdit'
        src={toAbsoluteUrl('/media/design/icon_pink_elipsis_edit.svg')}
      />

      {openTopicsDropdown && (
        <div className='navbar__dropdown editDelete__dropdownOptions' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div
              className=' '
              onClick={() => {
                onCallback('Download')
                setOpenTopicsDropdown(false)
              }}
            >
              <div className='dropdown__iconTxtWrap '>
                <FeatherIcon
                  style={{marginRight: 5}}
                  icon={'download'}
                  color='grey'
                  size='20'
                  className='adminDropdown__trashIcon'
                />
                <span className='dropdown__iconTxt'>{translate('Download')}</span>
              </div>
            </div>
            {!viewOnly && (
              <div
                className=' '
                onClick={() => {
                  if (window.confirm(`${translate('Delete')}`)) {
                    onCallback('Delete')
                    setOpenTopicsDropdown(false)
                  } else {
                    setOpenTopicsDropdown(false)
                  }
                }}
              >
                <div className='dropdown__iconTxtWrap '>
                  <FeatherIcon
                    style={{marginRight: 5}}
                    icon={'trash-2'}
                    color='grey'
                    size='20'
                    className='adminDropdown__trashIcon'
                  />

                  <span className='dropdown__iconTxt'>{translate('Delete')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )

  const renderView = () => {
    if (view == 'customDropDown') {
      return customDropDown
    }
    if (view == 'districts') {
      return districts
    }

    if (view == 'statusFilter') {
      return statusFilter
    }

    if (view == 'languages') {
      return languages
    }

    if (view == 'electorDecision') {
      return electorDecision
    }

    if (view == 'mexicoDistricts') {
      return mexicoDistricts
    }

    if (view == 'channel') {
      return channel
    }
    if (view == 'status') {
      return status
    }

    if (view == 'mexicoAdminUser') {
      return mexicoAdminUser
    }
    if (view == 'mexicoAdminRestoreAccess') {
      return mexicoAdminRestoreAccess
    }
    if (view == 'EditDelete') {
      return EditDelete
    }
  }

  useEffect(() => {
    renderView()
  }, [view])

  return <>{renderView()}</>
}
