import * as Yup from 'yup'

export const initialValues = {
  // USER INFO
  firstName: '',
  middleName: '',
  lastName: '',
  singleName: null,

  // DATE OF BIRTH
  birthMonth: '',
  birthDay: '',
  birthYear: '',

  // CONTACT INFO
  email: '',
  confirmEmail: '',
  phoneNumber: '',
  receiveSMSNotif: false,

  // RESIDENTIAL ADDRESS
  streetAddress: '',
  city: '',
  province: '',
  postalCode: '',
  unitNumber: '',

  // MAILING ADDRESS
  mailingAddress: '',
  mailingCity: '',
  mailingProvince: '',
  mailingPostalCode: '',
  mailingUnitNumber: '',

  // PROPERTY TERMS
  propertyTerms: null,

  // PHOTO ID
  photoID: null,
  photosList: null,

  // VOTER TERMS
  voterTerms: false,
}

export const step1Schema = Yup.object().shape({
  // USER INFO
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().required('Last name is required!'),
  // DATE OF BIRTH
  birthMonth: Yup.string().required('Date of birth is required!'),
  birthDay: Yup.string().required('Date of birth is required!'),
  birthYear: Yup.string().required('Date of birth is required!'),
  // CONTACT INFO
  email: Yup.string().required('Email is required!'),
  confirmEmail: Yup.string().required('Email confirmation is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),

  receiveSMSNotif: Yup.boolean()
    .required('Notifications are required!')
    .oneOf([true], 'Notifications must be enabled.'),
})

export const step1SchemaWithSingleName = Yup.object().shape({
  // USER INFO
  firstName: Yup.string().required('First name is required!'),
  // lastName: Yup.string().notRequired(),
  // DATE OF BIRTH
  birthMonth: Yup.string().required('Date of birth is required!'),
  birthDay: Yup.string().required('Date of birth is required!'),
  birthYear: Yup.string().required('Date of birth is required!'),
  // CONTACT INFO
  email: Yup.string().required('Email is required!'),
  confirmEmail: Yup.string().required('Email confirmation is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),

  receiveSMSNotif: Yup.boolean()
    .required('Notifications are required!')
    .oneOf([true], 'Notifications must be enabled.'),
})

export const step2SchemaDefault = Yup.object().shape({
  // RESIDENTIAL ADDRESS
  streetAddress: Yup.string().required('Street Address is required!'),
  city: Yup.string().required('City is required!'),
  province: Yup.string().required('Province is required!'),
  postalCode: Yup.string().required('Postal Code is required!'),

  photoID: Yup.mixed().required('Photo id is required'),

  // VOTER TERMS
  voterTerms: Yup.boolean()
    .required('You must accept terms.')
    .oneOf([true], 'You must accept terms.'),

  // PHOTOS
  // photosList: Yup.array()
  //   .of(Yup.string())
  //   .min(1, 'Upload atleast one piece of photo identification!'),
})

export const step2SchemaSecondary = Yup.object().shape({
  // RESIDENTIAL ADDRESS
  streetAddress: Yup.string().required('Street Address is required!'),
  city: Yup.string().required('City is required!'),
  province: Yup.string().required('Province is required!'),
  postalCode: Yup.string().required('Postal Code is required!'),
  // MAILING ADDRESS
  mailingAddress: Yup.string().required('Street Address is required!'),
  mailingCity: Yup.string().required('City is required!'),
  mailingProvince: Yup.string().required('Province is required!'),
  mailingPostalCode: Yup.string().required('Postal Code is required!'),

  photoID: Yup.mixed().required('Photo id is required'),

  propertyTerms: Yup.boolean()
    .required('You must accept terms.')
    .oneOf([true, false], 'You must accept terms.'),

  // VOTER TERMS
  voterTerms: Yup.boolean()
    .required('You must accept terms.')
    .oneOf([true], 'You must accept terms.'),

  // PHOTOS
  // photosList: Yup.array()
  //   .of(Yup.string())
  //   .min(1, 'Upload atleast one piece of photo identification!'),
})
