import axios from 'axios'
import environment from '../../../utils/environment'
import KeycloakService from '../../../Keycloak'
import Cookies from 'universal-cookie'
import {languageValueToKey} from '../../languages/languagekey'
import {v4 as uuidv4} from 'uuid'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyToken'

const axiosInstance = axios.create({
  baseURL: `${environment.resolveApi().voter_reg_rest}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

// attach access token
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = KeycloakService.getToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
axiosInstance.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }
    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
    }
    return res
  },
  async (err) => {
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  }
)

const axiosInstanceVerify = axios.create({
  baseURL: `${environment.resolveApi().voter_reg_rest}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

// attach access token
axiosInstanceVerify.interceptors.request.use(
  async (config) => {
    const accessToken = cookies.get(VERIFY_COOKIE)
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
axiosInstanceVerify.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }
    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
    }
    return res
  },
  async (err) => {
    console.log('error', err, err.response)
    if (err.status === 403 || err.response?.status === 403) {
      cookies.remove(VERIFY_COOKIE)
    }
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  }
)

const VoterAuthService = {
  // login with username and password then go to next step
  login: async (username, password, eventId, recaptchaToken) => {
    try {
      let resp = await axios.post(`${environment.resolveApi().voter_reg_rest}/auth/login`, {
        username: username,
        password: password,
        eventId,
        recaptchaToken,
      })
      let user
      resp = resp.data
      if (resp.link && resp.link.includes('login-actions')) {
        window.location.href = resp.link
        cookies.remove(VERIFY_COOKIE)
        user = VoterAuthService.getUserInfoKeycloakToken()
      }
      if (resp.accessToken) {
        cookies.set(VERIFY_COOKIE, resp.accessToken)
      }
      if (resp.user) {
        user = resp.user
      }

      return {
        success: true,
        user: user,
        link: resp.link,
        passwordResetRequired: resp.passwordResetRequired,
      }
    } catch (error) {
      console.log(error.response?.data?.error)
      error = {
        ...error.response?.data?.error,
      }
      return {error}
    }
  },
  // syncSession: async () => {
  //   try {
  //     let res = await axiosInstance.get('/sync-session', {tabId: window.name})
  //     console.log(res)
  //     return {success: true}
  //   } catch (error) {
  //     // console.log(error)
  //     return {error, success: false}
  //   }
  // },
  // clearSession: async () => {
  //   try {
  //     console.log('clear sessions')
  //     let res = await axiosInstance.get('/clear-session')
  //     console.log(res)
  //     return {success: true}
  //   } catch (error) {
  //     console.log(error)
  //     return {error, success: false}
  //   }
  // },
  sendSMSCode: async (language) => {
    try {
      language = languageValueToKey[language]
      await axiosInstanceVerify.post(`/auth/mfa/initiate/sms?locale=${language}`)
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  sendEmailCode: async () => {
    try {
      await axiosInstanceVerify.post('/auth/mfa/initiate/email')
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  requestPasswordReset: async (username, email, recaptchaToken) => {
    try {
      await axiosInstanceVerify.post('/auth/resetpassword', {username, email, recaptchaToken})
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  sendSMSCodePasswordResetFlow: async (language) => {
    try {
      language = languageValueToKey[language]
      await axiosInstanceVerify.post(`/auth/resetpassword/mfa/initiate/sms?locale=${language}`)
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  sendEmailCodePasswordResetFlow: async () => {
    try {
      await axiosInstanceVerify.post('/auth/resetpassword/mfa/initiate/email')
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifySMSCode: async (verificationCode) => {
    try {
      await axiosInstanceVerify.post('/auth/mfa/verify/sms', {verificationCode})

      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyEmailCode: async (verificationCode) => {
    try {
      await axiosInstanceVerify.post('/auth/mfa/verify/email', {verificationCode})

      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifySMSCodePasswordResetFlow: async (verificationCode) => {
    try {
      await axiosInstanceVerify.post('/auth/resetpassword/mfa/verify/sms', {
        verificationCode,
      })

      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyEmailCodePasswordResetFlow: async (verificationCode) => {
    try {
      await axiosInstanceVerify.post('/auth/resetpassword/mfa/verify/email', {
        verificationCode,
      })

      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  regenerateAuthAppQRCode: async (securityAnswer) => {
    try {
      let resp = await axiosInstance.post('/auth/mfa/regenerate/authapp', {securityAnswer})
      return {success: true, qrCodeDataUrl: resp.qrCodeDataUrl, factorSid: resp.factorSid}
    } catch (error) {
      console.log(error)
      return {error}
    }
  },
  verifyRegenerateAuthAppQRCode: async (code, factorSid) => {
    try {
      await axiosInstance.post('/auth/mfa/regenenerate/verify/authapp', {
        code,
        factorSid,
      })
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyAuthAppCode: async (code) => {
    try {
      await axiosInstanceVerify.post('/auth/mfa/verify/authapp', {code})

      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyAuthAppCodePasswordResetFlow: async (code) => {
    try {
      await axiosInstanceVerify.post('/auth/resetpassword/mfa/verify/authapp', {code})

      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  getUserInfo: async () => {
    try {
      const user = await axiosInstance.get('/me')
      return user
    } catch (error) {}
  },
  getUserEvents: async () => {
    try {
      const user = await axiosInstance.get('/me')
      return user?.elections
    } catch (error) {}
  },
  getUserInfoKeycloakToken: () => {
    try {
      const parsedToken = KeycloakService.getParsedToken()
      let user = {}
      if (parsedToken) {
        user = {
          username: parsedToken.preferred_username,
          email: parsedToken.email,
          phone: parsedToken.phone_number,
          phoneNumber: parsedToken.phone_number,
          votingChannel: parsedToken.votingChannel,
          district: parsedToken.district,
        }
      }
      return user
    } catch (error) {}
  },
  // TODO user not logged in keycloak
  // TODO update on login & if logged in whenever change
  updateUserLanguage: async (language) => {
    try {
      language = languageValueToKey[language]
      await axiosInstance.put('/account/language', {language: language})
    } catch (error) {}
  },

  updateUserPassword: async (currentPassword, newPassword) => {
    try {
      await axiosInstance.put('/auth/password', {currentPassword, newPassword})
      return {success: true}
    } catch (error) {
      // console.log(error)
    }
  },
  updateUserPasswordResetPasswordFlow: async (currentPassword, newPassword) => {
    try {
      await axiosInstanceVerify.put('/auth/resetpassword/password', {
        currentPassword,
        newPassword,
      })
      return {success: true}
    } catch (error) {
      console.log(error)
    }
  },
  logOut: async (history) => {
    if (KeycloakService.isLoggedIn()) {
      KeycloakService.doLogout()
    }
    sessionStorage.clear()
    history && history.push('/voting/voter-login')
    if (!history) {
      window.location.reload()
    }
  },

  getVoterEventDetails: async (eventId) => {
    try {
      let event = await axiosInstance.get(`/events/${eventId}`)
      console.log(event)
      return {success: true, event}
    } catch (error) {}
  },
  getEventById: async (eventId) => {
    try {
      if (eventId) {
        const event = await axiosInstance.get(`/events/${eventId}`)
        return event
      }
    } catch (error) {}
  },

  getHelpDocs: async () => {
    try {
      let resp = await axiosInstance.get(`/helpguide`)

      return resp
    } catch (error) {
      console.log(error)
    }
  },
}

export const voterAPI = axiosInstance

export default VoterAuthService
