import {useState, useContext} from 'react'
import {LanguageContext} from '../../../../../../contexts/LanguageContext'
import '../MEXICO__PhoneVerify.scss'
import {useTranslation} from '../../../../../../hooks/useTranslation'

export function ProgressBar(props) {
  const {translate} = useTranslation()
  let {currentStep} = props

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  return (
    <div className='phoneVerify__form__progBar '>
      {/* STEP 1 */}
      <div
        className={
          LANGUAGE_CONTEXT.currentLanguage !== 'English'
            ? 'phoneVerify__form__progBar__stepContainer--Spanish'
            : 'phoneVerify__form__progBar__stepContainer'
        }
      >
        <div
          className={
            currentStep == 1
              ? 'phoneVerify__form__progBar__stepTxtWrap--active'
              : 'phoneVerify__form__progBar__stepTxtWrap--default'
          }
        >
          <span className='phoneVerify__form__progBar__stepTxt'>1</span>
        </div>
        <span
          className={
            currentStep == 1
              ? 'phoneVerify__form__progBar__stepLabel--active'
              : 'phoneVerify__form__progBar__stepLabel--default'
          }
        >
          {translate('Phone verification')}
        </span>
      </div>

      {/* STEP 2 */}
      <div
        className={
          LANGUAGE_CONTEXT.currentLanguage !== 'English'
            ? 'phoneVerify__form__progBar__stepContainer--Spanish'
            : 'phoneVerify__form__progBar__stepContainer'
        }
      >
        <div
          className={
            currentStep == 2
              ? 'phoneVerify__form__progBar__stepTxtWrap--active'
              : 'phoneVerify__form__progBar__stepTxtWrap--default'
          }
        >
          <span className='phoneVerify__form__progBar__stepTxt'>2</span>
        </div>
        <span
          className={
            currentStep == 2
              ? 'phoneVerify__form__progBar__stepLabel--active'
              : 'phoneVerify__form__progBar__stepLabel--default'
          }
        >
          {translate('Authentication method')}
        </span>
      </div>

      {/* STEP 3 */}
      <div
        className={
          LANGUAGE_CONTEXT.currentLanguage !== 'English'
            ? 'phoneVerify__form__progBar__stepContainer--Spanish'
            : 'phoneVerify__form__progBar__stepContainer'
        }
      >
        <div
          className={
            currentStep == 3
              ? 'phoneVerify__form__progBar__stepTxtWrap--active'
              : 'phoneVerify__form__progBar__stepTxtWrap--default'
          }
        >
          <span className='phoneVerify__form__progBar__stepTxt'>3</span>
        </div>
        <span
          className={
            currentStep == 3
              ? 'phoneVerify__form__progBar__stepLabel--active'
              : 'phoneVerify__form__progBar__stepLabel--default'
          }
        >
          {translate('Verification')}
        </span>
      </div>

      {/* STEP 4 */}
      <div className={'phoneVerify__form__progBar__stepContainer'}>
        <div
          className={
            currentStep == 4
              ? 'phoneVerify__form__progBar__stepTxtWrap--active'
              : 'phoneVerify__form__progBar__stepTxtWrap--default'
          }
        >
          <span className='phoneVerify__form__progBar__stepTxt'>4</span>
        </div>
        <span
          className={
            currentStep == 4
              ? 'phoneVerify__form__progBar__stepLabel--active'
              : 'phoneVerify__form__progBar__stepLabel--default'
          }
        >
          {translate('Create password')}
        </span>
      </div>
    </div>
  )
}
