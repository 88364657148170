/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'

import './SuperAdmin__ChangePassword.scss'

import '@djthoms/pretty-checkbox'
import TextField from '../../../../../../modules/auth/reusable/TextField'
import {useVoterStyles} from '../../../../../../contexts/VoterStylesContext'

import AdminVoterAuthService from '../../../../../../services/auth/AdminVoterAuthService'

import {PasswordValidate__SuperAdmin} from '../../onboarding/PasswordValidate__SuperAdmin'

import {useTranslation} from '../../../../../../hooks/useTranslation'

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
}

export function SuperAdmin__ChangePassword(props) {
  const {translate} = useTranslation()
  const history = useHistory()
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(null)
  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()
  const [authError, setAuthError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [changePassSuccess, setChangePassSuccess] = useState(false)
  const [validationResult, setValidationResult] = useState(false)

  let flow = props.history?.location?.state?.flow

  const passwordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    password: Yup.string().required('New password is required'),
    confirmPassword: Yup.string()
      .required('Password confirmation is required')
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match"
        ),
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setAuthError(false)
      setTimeout(async () => {
        try {
          const userInput = {
            // USER INFO
            currentPassword: values.currentPassword,
            password: values.password,
            confirmPassword: values.confirmPassword,
          }
          let updated
          if (flow === 'Onboarding') {
            updated = await AdminVoterAuthService.updateUserPasswordOnboard(
              userInput.currentPassword,
              userInput.password
            )
            setLoading(false)
          } else if (flow === 'Forgot Password' || flow === 'Admin Forgot Password') {
            updated = await AdminVoterAuthService.updateUserPasswordResetPasswordFlow(
              userInput.currentPassword,
              userInput.password
            )
          }
          if (updated?.success) {
            setChangePassSuccess(true)
            formik.resetForm()
            // history.push('/admin/dashboard')
          } else {
            // TODO display error
            setAuthError(true)
          }
        } catch (error) {
          console.log(error)
          setLoading(false)
          setAuthError(true)
        }
      }, 10)
    },
  })

  return (
    <div className=' SuperAdmin__ChangePassword' id='Voter'>
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        {/* BODY */}
        <div className='common__form userLogin__form' style={{height: 'fit-content'}}>
          <span className='userLogin__form__header__welcomeTxt'>
            {translate('Change Password')}
          </span>
          <form
            className='userLogin__form__body'
            onSubmit={(e) => {
              e.preventDefault()
              formik.submitForm()
            }}
          >
            <div className='changePassword__body__form__inputContainer'>
              <TextField
                formik={formik}
                type='password'
                field='currentPassword'
                placeholder={translate('Current password')}
                label={translate('Current password')}
                isRequired={true}
                view='voter'
              />

              {formik.touched['currentPassword'] && formik.errors['currentPassword'] && (
                <div className='TextField__voterErrWrap'>
                  <span className='TextField__voterErrTxt' role='alert'>
                    {translate(formik.errors['currentPassword'])}
                  </span>
                </div>
              )}
              <div className='createPassword__form__body__password__confirmInput'>
                <TextField
                  formik={formik}
                  type='password'
                  field='password'
                  placeholder={translate('New password')}
                  label={translate('New password')}
                  isRequired={true}
                  view='voter'
                />

                {formik.touched['password'] && formik.errors['password'] && (
                  <div className='TextField__voterErrWrap'>
                    <span className='TextField__voterErrTxt' role='alert'>
                      {translate(formik.errors['password'])}
                    </span>
                  </div>
                )}
              </div>
              <div className='createPassword__form__body__password__confirmInput'>
                <TextField
                  formik={formik}
                  type='password'
                  field='confirmPassword'
                  placeholder={translate('Confirm new password')}
                  label={translate('Confirm new password')}
                  isRequired={true}
                  view='voter'
                />

                {formik.touched['confirmPassword'] && formik.errors['confirmPassword'] && (
                  <div className='TextField__voterErrWrap'>
                    <span className='TextField__voterErrTxt' role='alert'>
                      {translate(formik.errors['confirmPassword'])}
                    </span>
                  </div>
                )}

                {authError && (
                  <div className='TextField__voterErrWrap'>
                    <span className='TextField__voterErrTxt' role='alert'>
                      {translate('Authentication failed, please check current password')}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <PasswordValidate__SuperAdmin
              formik={formik}
              result={(value) => {
                setValidationResult(value)
                // console.log('value', value)
              }}
            />
          </form>

          <div className='changePassword__body__form__footer reset_pw_footer'>
            <button
              className={
                validationResult
                  ? 'authMethodLogin__nextBtn'
                  : 'authMethodLogin__nextBtn common__disabledBtn'
              }
              disabled={!validationResult}
              onClick={() => {
                formik.submitForm()
              }}
            >
              {translate('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
