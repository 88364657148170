import {AdminDropdown} from '../../../../../../voter_admin/reusable/admin_dropdown/AdminDropdown'
import './ParticipantDuplication.scss'
export const ParticipantDuplication = ({profiles}) => {
  return (
    <div className='ParticipantDuplication'>
      <span className='participantDuplication__label'>Similar Profiles</span>

      {/* table */}
      <div className='participantDuplication__table'>
        {/* header */}
        <div className='participantDuplication__table__header'>
          <span className='participantDuplication__table__header__label--name'>Name</span>
          <span className='participantDuplication__table__header__label--address'>
            Date of Birth
          </span>
          <span className='participantDuplication__table__header__label--phone'>Status</span>
          <span className='participantDuplication__table__header__label--action'>Action</span>
        </div>

        {/* body */}
        <div className='participantDuplication__table__body'>
          {profiles.map((profile, index) => {
            return (
              <div className='participantDuplication__table__body__row'>
                <span className='participantDuplication__table__body__row__value--name'>
                  {profile.firstName} {profile.middleName} {profile.lastName}
                </span>
                <span className='participantDuplication__table__body__row__value--address'>
                  {profile.dateOfBirth}
                </span>
                <span
                  className={`participantDuplication__table__body__row__value--phone importAddrList__statusLabel ${
                    profile.status === 'Approved'
                      ? 'importAddrList__statusLabel--success '
                      : profile.status === 'Declined'
                      ? 'importAddrList__statusLabel--failed '
                      : 'importAddrList__statusLabel--na '
                  }`}
                >
                  {profile.status}
                </span>
                <div className='participantDuplication__table__body__row__value--action'>
                  <AdminDropdown
                    customOptionStyling={{position: 'absolute'}}
                    view='customDropDown'
                    dropdownOptions={[{label: 'View', value: 'View'}]}
                    onCallback={(value) => {
                      if (value == 'View') {
                        window.open(`/admin/participants/${profile._id}`, '_blank', 'noreferrer')
                      }
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
