import config1 from './config.json'

let config = {
  ...config1,
  // ignace
  centerMaps: {lat: 49.4159, lng: -91.6587},
}

export default config

//"PORTAL_TYPE": "ADMIN" , "VOTER"
