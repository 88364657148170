/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {useSuperAdminUserContext} from '../../../../../../../contexts/SuperAdminUserContext'

import './UserDetails.scss'

export function UserDetails(props) {
  const history = useHistory()
  const {
    adminUser__userStatus,
    setAdminUser__userStatus,
    adminUser__currentTab,
    setAdminUser__currentTab,
  } = useSuperAdminUserContext()

  return (
    <>
      <div className='userDetails__header'>
        <>
          <span
            className='common__backBtn'
            onClick={() => {
              setAdminUser__currentTab('Users')
            }}
          >
            {'< Back'}
          </span>
          <span className='userDetails__header__name'>John Doe</span>
        </>

        {adminUser__userStatus == 'Active' ? (
          <div className='userDetails__header__status--active'>
            <span className='userDetails__header__status__txt'>Active</span>
          </div>
        ) : (
          <div className='userDetails__header__status--suspended'>
            <span className='userDetails__header__status__txt'>Suspended</span>
          </div>
        )}
      </div>

      <div className='UserDetails'>
        <div className='userDetails__email'>
          <span className='userDetails__label'>Email Address</span>
          <span className='userDetails__value'>Johndoe@someoneemail.com</span>
        </div>
        <div className='userDetails__phone'>
          <span className='userDetails__label'>Phone Number</span>
          <span className='userDetails__value'>123-456-7891</span>
        </div>
        <div className='userDetails__role'>
          <span className='userDetails__label'>User Role</span>
          <span className='userDetails__value'>admin</span>
        </div>

        {adminUser__userStatus == 'Active' ? (
          <div
            className='userDetails__suspend'
            onClick={() => {
              setAdminUser__userStatus('Suspend User')
            }}
          >
            <button className='userDetails__suspend__btn'>Suspend</button>
          </div>
        ) : (
          <div
            className='userDetails__suspend'
            onClick={() => {
              setAdminUser__userStatus('Active')
            }}
          >
            <button className='userDetails__suspend__btn--restoreAccess'>Restore Access</button>
          </div>
        )}
      </div>
    </>
  )
}
