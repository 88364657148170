import React from 'react'
import SecurityIcon from '../icon/SheildCheckIcon'

const VoterRegisterHeader = ({setModalView, setIsOpen}) => {
  return (
    <div>
      <div className='voterRegister__titleWrap'>
        <span className='voterRegister__title'>Voter Registration</span>

        {/* Security SVG IMG */}
        {/* <SecurityIcon setModalView={setModalView} setIsOpen={setIsOpen} /> */}
      </div>
      <span className='voterRegister__subtitle'>
        The following information is required to register
      </span>
    </div>
  )
}

export default VoterRegisterHeader
