import {Redirect, Route, Switch} from 'react-router-dom'

import {NOVASCOTIA__Dashboard} from '../pages/voter_admin/pages/nova_scotia/dashboard/NOVASCOTIA__Dashboard'
import {AdminAddressList} from '../pages/voter_admin/pages/nova_scotia/address_list/AdminAddressList'
import {AdminElectorsList} from '../pages/voter_admin/pages/nova_scotia/electors_list/AdminElectorsList'
import {AdminDuplicationList} from '../pages/voter_admin/pages/nova_scotia/duplication_list/AdminDuplicationList'

import {MEXICO__Dashboard} from '../pages/voter_admin/pages/mexico/user_dashboard/dashboard/MEXICO__Dashboard'
import {MEXICO__MyProfile} from '../pages/voter_admin/pages/mexico/user_dashboard/my_profile/MEXICO__MyProfile'
import {MEXICO__ChangePassword} from '../pages/voter_admin/pages/mexico/user_dashboard/change_password/MEXICO__ChangePassword'
import {useVoterStyles} from '../contexts/VoterStylesContext'
import RegenQRCodeVoter from '../pages/voter_admin/pages/mexico/user_dashboard/my_profile/RegenQRCodeVoter'
import {MEXICO__SuperAdmin__HelpAndSupport} from '../pages/super_admin/mexico/pages/help_and_support/MEXICO__SuperAdmin__HelpAndSupport'
import {beforeUnload} from '../App'
import {useEffect} from 'react'
import useWindowHeight from '../hooks/useWindowHeight'

export function VoterAdminRoutes() {
  const {voterStyleView} = useVoterStyles()
  const windowHeight = useWindowHeight()

  const MEXICO_ROUTES = (
    <Route path='/voting/voter'>
      <Switch>
        <Route path='/voting/voter/dashboard' component={MEXICO__Dashboard} />
        <Route path='/voting/voter/my-profile' component={MEXICO__MyProfile} />
        <Route path='/voting/voter/change-password' component={MEXICO__ChangePassword} />
        <Route path='/voting/voter/regen-qr-code' component={RegenQRCodeVoter} />
        <Route path='/voting/voter/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route path='*' render={() => <Redirect to='/voting/voter/dashboard' />} />
      </Switch>
    </Route>
  )

  const NEUVOTE_ROUTES = (
    <Route path='/voting/voter'>
      <Switch>
        <Route path='/voting/voter/dashboard' component={MEXICO__Dashboard} />
        <Route path='/voting/voter/my-profile' component={MEXICO__MyProfile} />
        <Route path='/voting/voter/change-password' component={MEXICO__ChangePassword} />
        <Route path='/voting/voter/regen-qr-code' component={RegenQRCodeVoter} />
        <Route path='/voting/voter/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route path='*' render={() => <Redirect to='/voting/voter/dashboard' />} />{' '}
      </Switch>
    </Route>
  )

  const NWT_ROUTES = (
    <Route path='/voting/voter'>
      <Switch>
        <Route path='/voting/voter/dashboard' component={MEXICO__Dashboard} />
        <Route path='/voting/voter/my-profile' component={MEXICO__MyProfile} />
        <Route path='/voting/voter/change-password' component={MEXICO__ChangePassword} />
        <Route path='/voting/voter/regen-qr-code' component={RegenQRCodeVoter} />
        <Route path='/voting/voter/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route path='*' render={() => <Redirect to='/voting/voter/dashboard' />} />{' '}
      </Switch>
    </Route>
  )

  const IGNACE_ROUTES = (
    <Route path='/voting/voter'>
      <Switch>
        <div style={{backgroundColor: '#F2F3F7', height: windowHeight}}>
          <Route path='/voting/voter/dashboard' component={MEXICO__Dashboard} />
          <Route path='/voting/voter/my-profile' component={MEXICO__MyProfile} />
          <Route path='/voting/voter/change-password' component={MEXICO__ChangePassword} />
          <Route path='/voting/voter/regen-qr-code' component={RegenQRCodeVoter} />
          <Route path='/voting/voter/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
          <Route path='*' render={() => <Redirect to='/voting/voter/dashboard' />} />{' '}
        </div>
      </Switch>
    </Route>
  )

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
  }, [])

  const renderRoutes = () => {
    console.log('voterStyleView', voterStyleView)
    if (voterStyleView == 'mexico') {
      return MEXICO_ROUTES
    } else if (voterStyleView == 'nwt') {
      return NWT_ROUTES
    } else if (voterStyleView == 'ignace') {
      return IGNACE_ROUTES
    } else {
      //else, render nova scotia routes
      return NEUVOTE_ROUTES
    }
  }

  return <Switch>{renderRoutes()}</Switch>
}
