/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext, useRef} from 'react'
import FeatherIcon from 'feather-icons-react'
import './AdminDropdown.scss'

import {useTranslation} from '../../../../hooks/useTranslation'

export function CustomAdminDropdown({options, title, value, setValue, isForm = false, isRequired}) {
  const {translate} = useTranslation()

  const [displayValue, setDisplayValue] = useState(value?.label)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const topicOpRef = useRef(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isDropdownOpen && topicOpRef.current && !topicOpRef.current.contains(e.target)) {
        setIsDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isDropdownOpen])

  return (
    <div>
      <label
        className={`createPostModal_optionTxt fs-6 fw-bold text-dark ${
          isForm ? ' form-label' : ''
        }     ${isRequired && 'required'}`}
      >
        {translate(title)}
      </label>
      <div
        style={{width: '100%', marginRight: 25}}
        className='addressList__districtDropdown '
        onClick={() => {
          setIsDropdownOpen(true)
        }}
      >
        <span className={'createPostModal_optionTxt'}> {translate(displayValue)}</span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {isDropdownOpen && (
        <div className='navbar__dropdown addressList__districtDropdown__options' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {options.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setDisplayValue(translate(topic.label))
                      // console.log('topic.value', topic.value)

                      setValue(topic.value)
                      setIsDropdownOpen(false)
                    }}
                  >
                    <span className='createPostModal_optionTxt'>{translate(topic.label)}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default CustomAdminDropdown
