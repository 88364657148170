import {useState, useContext} from 'react'
import '../MEXICO__PhoneVerify.scss'
import './ProgressBarMobile.scss'
import {useTranslation} from '../../../../../../hooks/useTranslation'

export function ProgressBarMobile(props) {
  const {translate} = useTranslation()

  let {currentStep} = props

  const [stepTxtWrapClass, setStepTxtWrapClass] = useState(
    'phoneVerify__form__progBar__stepTxtWrap--default'
  )
  const [stepLabelClass, setStepLabelClass] = useState(
    'phoneVerify__form__progBar__stepLabel--default'
  )

  return (
    <div className='phoneVerify__form__progBar progressBarM__form'>
      {currentStep == 1 && (
        <div className='phoneVerify__form__progBar__stepContainer'>
          <div
            className={
              currentStep == 1
                ? 'phoneVerify__form__progBar__stepTxtWrap--active'
                : 'phoneVerify__form__progBar__stepTxtWrap--default'
            }
          >
            <span className='phoneVerify__form__progBar__stepTxt'>1</span>
          </div>
          <span
            className={
              currentStep == 1
                ? 'phoneVerify__form__progBar__stepLabel--active'
                : 'phoneVerify__form__progBar__stepLabel--default'
            }
          >
            {translate('Phone verification')}
          </span>
        </div>
      )}

      {currentStep == 2 && (
        <div className='phoneVerify__form__progBar__stepContainer'>
          <div
            className={
              currentStep == 2
                ? 'phoneVerify__form__progBar__stepTxtWrap--active'
                : 'phoneVerify__form__progBar__stepTxtWrap--default'
            }
          >
            <span className='phoneVerify__form__progBar__stepTxt'>2</span>
          </div>
          <span
            className={
              currentStep == 2
                ? 'phoneVerify__form__progBar__stepLabel--active'
                : 'phoneVerify__form__progBar__stepLabel--default'
            }
          >
            {translate('Authentication method')}
          </span>
        </div>
      )}
      {currentStep == 3 && (
        <div className='phoneVerify__form__progBar__stepContainer'>
          <div
            className={
              currentStep == 3
                ? 'phoneVerify__form__progBar__stepTxtWrap--active'
                : 'phoneVerify__form__progBar__stepTxtWrap--default'
            }
          >
            <span className='phoneVerify__form__progBar__stepTxt'>3</span>
          </div>
          <span
            className={
              currentStep == 3
                ? 'phoneVerify__form__progBar__stepLabel--active'
                : 'phoneVerify__form__progBar__stepLabel--default'
            }
          >
            {translate('Verification')}
          </span>
        </div>
      )}
      {currentStep == 4 && (
        <div className='phoneVerify__form__progBar__stepContainer'>
          <div
            className={
              currentStep == 4
                ? 'phoneVerify__form__progBar__stepTxtWrap--active'
                : 'phoneVerify__form__progBar__stepTxtWrap--default'
            }
          >
            <span className='phoneVerify__form__progBar__stepTxt'>4</span>
          </div>
          <span
            className={
              currentStep == 4
                ? 'phoneVerify__form__progBar__stepLabel--active'
                : 'phoneVerify__form__progBar__stepLabel--default'
            }
          >
            {translate('Create password')}
          </span>
        </div>
      )}
    </div>
  )
}
