import {useRef, useEffect, useState} from 'react'
import {useTranslation} from '../../hooks/useTranslation'
import config from '../../../config'

const AutoComplete = (props) => {
  const {
    formik,
    field,
    placeholder,
    label,
    isRequired,
    noLabel,
    types = [],
    width = '100%',
    view = 'default',
    onSelect,
    hideErrorLabel,
    mailing = false,
  } = props
  const {translate} = useTranslation()
  const autoCompleteRef = useRef()
  const inputRef = useRef()

  const center = config.centerMaps
  // Create a bounding box with sides ~50km away from the center point
  const defaultBounds = {
    north: center.lat + 0.75,
    south: center.lat - 0.75,
    east: center.lng + 0.75,
    west: center.lng - 0.75,
  }
  const options = {
    // componentRestrictions: {country: 'ca'},
    fields: ['address_components', 'geometry', 'icon', 'name'],
    bounds: defaultBounds,
    strictBounds: false,
  }

  // TODO improve this
  const streetAddressField = mailing ? 'mailingAddress' : 'streetAddress'
  const provinceField = mailing ? 'mailingProvince' : 'province'
  const cityField = mailing ? 'mailingCity' : 'city'
  const postalCodeField = mailing ? 'mailingPostalCode' : 'postalCode'
  const countryField = mailing ? 'mailingCountry' : 'country'

  function fillInAddress(place) {
    // Get the place details from the autocomplete object.
    let address1 = ''
    let postcode = ''

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`
          break
        }

        case 'route': {
          address1 += component.short_name
          break
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`

          break
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`
          break
        }
        case 'locality':
          // document.querySelector("#locality").value = ;
          formik.setFieldValue(cityField, component.long_name)
          break
        case 'administrative_area_level_1': {
          // document.querySelector('#state').value = component.short_name
          formik.setFieldValue(provinceField, component.long_name)
          break
        }
        case 'country':
          // document.querySelector('#country').value = component.long_name
          formik.setFieldValue(countryField, component.long_name)
          break
        default:
          break
      }
      if (postcode) {
        formik.setFieldValue(postalCodeField, postcode)
      }
      if (address1) {
        console.log('Setting streetAddress:', address1) // Debugging line
        formik.setFieldValue(streetAddressField, address1)
      }
    }
    console.log('address', address1)

    //   address1Field.value = address1;
    //   postalField.value = postcode;
    //   // After filling the form with address components from the Autocomplete
    //   // prediction, set cursor focus on the second address line to encourage
    //   // entry of subpremise information such as apartment, unit, or floor number.
    //   address2Field.focus();
  }

  // Function to handle input change
  const handleInputChange = (e) => {
    const value = e.target.value
    console.log('value', value)

    if (value === '') {
      formik.setFieldError(field, 'Please select an address from the dropdown.')
      formik.setFieldValue(streetAddressField, '')
    } else {
      formik.setFieldValue(streetAddressField, value)
      formik.setFieldError(field, undefined) // Clear the error if selection has been made
    }
  }

  useEffect(() => {
    //@ts-ignore
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options)
    //@ts-ignore
    autoCompleteRef.current.addListener('place_changed', async function () {
      //@ts-ignore
      const place = await autoCompleteRef.current.getPlace()
      console.log({place})
      try {
        fillInAddress(place)
      } catch (error) {}
    })
  }, [])

  return (
    <div>
      {!noLabel && (
        <label
          className={
            isRequired
              ? 'form-label fs-6 fw-bold text-dark required'
              : 'form-label fs-6 fw-bold text-dark'
          }
        >
          {label}
        </label>
      )}

      <input
        {...formik.getFieldProps(streetAddressField)}
        className='SelectField__voter'
        ref={inputRef}
        onChange={handleInputChange}
        name={streetAddressField}
        value={formik.values[streetAddressField]}
      />

      {formik.touched[field] && formik.errors[field] && (
        <div className='voterRegister__singleInputErr'>
          <div className='TextField__voterErrWrap'>
            <span className='TextField__voterErrTxt' role='alert'>
              {translate(formik.errors[field])}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
export default AutoComplete
