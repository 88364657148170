import React, {useState, useCallback, useEffect} from 'react'

import './PdfViewerPage.scss'
import PDFViewer from './PdfViewer'
import {useTranslation} from '../../../hooks/useTranslation'

const PDFViewerPage = ({file, pdfURL = null, onClose, downloadFile = {}}) => {
  const {translate} = useTranslation()

  useEffect(() => {
    console.log('pdfURL', pdfURL)
  }, [pdfURL])

  return (
    <div className='PdfViewerPage'>
      <span className='helpAndSupportM__title'>
        <span
          className='common__backBtn'
          onClick={() => {
            onClose()
          }}
        >
          {'< ' + translate('Back')}
        </span>
        {translate('')}
      </span>
      <div className='helpSupport__header'>
        <span className='common__pg__header__title'>
          {' '}
          <span
            className='common__backBtn'
            onClick={() => {
              onClose()
            }}
          >
            {'< ' + translate('Back')}
          </span>
          {translate('')}
        </span>
      </div>

      {pdfURL && (
        <div className='myProfile__body'>
          <PDFViewer pdfViewURL={pdfURL} />
        </div>
      )}
    </div>
  )
}

export default PDFViewerPage
