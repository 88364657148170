import React, {Component} from 'react'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import './TextField.scss'

class TextField extends Component {
  constructor(props) {
    super(props)
  }

  checkType(type) {
    if (type == 'email') {
      return 'email'
    } else if (type == 'password') {
      return 'password'
    } else {
      return 'text'
    }
  }

  render() {
    const {
      formik,
      type,
      field,
      placeholder,
      label,
      isRequired,
      noLabel,
      view,
      disabled,
      customStyling,
    } = this.props

    if (view == 'admin_searchbar') {
      return (
        <div className='TextField__voter TextField__adminSearchBar' style={customStyling}>
          <img
            className='TextField__adminSearchBarImg'
            src={toAbsoluteUrl('/media/design/admin_search_icon.svg')}
          />
          <input
            placeholder={placeholder}
            {...formik.getFieldProps(field)}
            className={clsx(
              'TextField__adminSearchBar__inner',
              {'is-invalid': formik.touched[field] && formik.errors[field]},
              {
                'is-valid': formik.touched[field] && !formik.errors[field],
              }
            )}
            type={this.checkType(type)}
            name={field}
            autoComplete='off'
            disabled={disabled}
          />
        </div>
      )
    }

    if (view == 'admin_searchbar-no-formik') {
      return (
        <div className='TextField__voter TextField__adminSearchBar' style={customStyling}>
          <img
            className='TextField__adminSearchBarImg'
            src={toAbsoluteUrl('/media/design/admin_search_icon.svg')}
          />
          <input
            placeholder={placeholder}
            className={clsx('TextField__adminSearchBar__inner')}
            type={this.checkType(type)}
            name={field}
            autoComplete='off'
            disabled={disabled}
            onChange={this.props.onChange}
          />
        </div>
      )
    }

    if (view == 'voter') {
      return (
        <div>
          {!noLabel && (
            <label
              className={
                isRequired
                  ? 'form-label fs-6 fw-bold text-dark required'
                  : 'form-label fs-6 fw-bold text-dark'
              }
            >
              {label}
            </label>
          )}

          <input
            placeholder={placeholder}
            {...formik.getFieldProps(field)}
            className={clsx(
              !disabled ? 'TextField__voter' : 'TextField__voter TextField__voterDisabled',
              {'is-invalid': formik.touched[field] && formik.errors[field]},
              {
                'is-valid': formik.touched[field] && !formik.errors[field],
              }
            )}
            type={this.checkType(type)}
            name={field}
            autoComplete='off'
            disabled={disabled}
          />
        </div>
      )
    }

    if (view == 'voter--withValidation') {
      return (
        <div>
          {!noLabel && (
            <label
              className={
                isRequired
                  ? 'form-label fs-6 fw-bold text-dark required'
                  : 'form-label fs-6 fw-bold text-dark'
              }
            >
              {label}
            </label>
          )}

          <input
            placeholder={placeholder}
            {...formik.getFieldProps(field)}
            className={clsx(
              !disabled ? 'TextField__voter' : 'TextField__voter TextField__voterDisabled',
              {'is-invalid': formik.touched[field] && formik.errors[field]},
              {
                'is-valid': formik.touched[field] && !formik.errors[field],
              }
            )}
            type={this.checkType(type)}
            name={field}
            autoComplete='off'
            disabled={disabled}
          />
          {formik.touched[field] && formik.errors[field] && (
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors[field]}
              </span>
            </div>
          )}
        </div>
      )
    }

    return (
      <div>
        {!noLabel && (
          <label
            className={
              isRequired
                ? 'form-label fs-6 fw-bold text-dark required'
                : 'form-label fs-6 fw-bold text-dark'
            }
          >
            {label}
          </label>
        )}

        <input
          placeholder={placeholder}
          {...formik.getFieldProps(field)}
          className={clsx(
            'TextField',
            {'is-invalid': formik.touched[field] && formik.errors[field]},
            {
              'is-valid': formik.touched[field] && !formik.errors[field],
            }
          )}
          type={this.checkType(type)}
          name={field}
          autoComplete='off'
        />
        {formik.touched[field] && formik.errors[field] && (
          <div className='voterRegister__inputErrorsWrap'>
            <span role='alert'>{formik.errors[field]}</span>
          </div>
        )}
      </div>
    )
  }
}

export default TextField
