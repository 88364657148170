/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'

import '@djthoms/pretty-checkbox'

import './EmailSent.scss'
import {useTranslation} from '../../../../../../hooks/useTranslation'

export function EmailSent(props) {
  const {translate} = useTranslation()
  const location = useLocation()

  const pathname = location.pathname
  const history = useHistory()
  const {setCurrentView} = props

  return (
    <div className='EmailSent'>
      <div className='emailSent__body'>
        <img
          className='emailSent__telegramIcon'
          src={toAbsoluteUrl('/media/design/icon_pink_telegram.svg')}
        />

        <span className='emailSent__title'>
          {translate('Reset password submitted successfully')}
        </span>
        <span className='emailSent__subtitle'>
          {translate(
            'A temporary password has been sent to your email address, please check your inbox'
          )}
        </span>
        <span className='emailSent__footerTxt'>
          {translate('Didn’t get it?')}{' '}
          <span
            className='phoneVerify__formFooter__disclaimerLink'
            onClick={() => setCurrentView('Forgot Password')}
          >
            {translate('Send again')}
          </span>
        </span>
      </div>
      <div className='phoneVerify__form__actionBar'>
        <button
          className={'authMethodLogin__nextBtn'}
          onClick={() => {
            if (pathname.includes('admin')) {
              history.push('/admin/login/')
            } else {
              history.push('/voting/voter-login/')
            }
          }}
        >
          {translate('Next')}
        </button>
      </div>
    </div>
  )
}
