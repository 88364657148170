/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useCallback, useRef} from 'react'

import {useFormik} from 'formik'

import TextField from '../../../../../../modules/auth/reusable/TextField'
import * as Yup from 'yup'

import {ModalHeader} from '../../../../../modals/ModalHeader'

import Modal from 'react-modal'
import {useVoterStyles} from '../../../../../../contexts/VoterStylesContext'

import './AddDocumentModals.scss'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {ModalFooter} from '../../../../../voter/reusable/modals/modal_footer/ModalFooter'
import AdminVoterAuthService from '../../../../../../services/auth/AdminVoterAuthService'
import {useTranslation} from '../../../../../../hooks/useTranslation'

const allowedFiles = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'mp3', 'mp4', 'mov', 'wav']

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '0px',
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export const initialValues = {
  title: '',
}

export const schema = Yup.object().shape({
  title: Yup.string().required('Title is required!'),
})

// Each view should be in a seperate file
export function AddDocumentModals(props) {
  const {translate} = useTranslation()
  let {view, modalOpen} = props

  const {customBtnClass} = useVoterStyles()

  const [currentView, setCurrentView] = useState(view)
  const [localFileData, setLocalFileData] = useState({name: '', file: {} as File})
  const [uploadErr, setUploadErr] = useState('')
  const uploadRef = useRef(null)

  const onClose = () => {
    // reset component
    formik.values.title = ''
    setCurrentView('Add Document')
    setLocalFileData({name: '', file: {} as File})
    props.onRequestClose()
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {},
  })

  const getFileType = (filename) => {
    let extension = filename.split('.').pop()
    return extension
  }

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const onFileUpload = async (e) => {
    try {
      e.persist()

      const file = e.target.files[0]
      setLocalFileData({name: file.name, file: file})
      console.log('file', file)

      const isLt2M = file.size / 1024 / 1024 < 25

      //    If the file size (calculated in byte form) is than 25MB
      if (!isLt2M) {
        setUploadErr(translate('The maximum supported file size is 25 MB'))
        setCurrentView('uploadError')
        return console.log('The maximum supported file size is 25 MB')
      }

      if (!allowedFiles.includes(getFileType(file.name))) {
        setCurrentView('wrongFileTypeErr')
        return console.log('wrongFileTypeErr')
      }

      try {
        setCurrentView('Selected Document')
      } catch (error) {
        setUploadErr(translate('Something went wrong, please contact support'))
        setCurrentView('uploadError')

        return console.log(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onFileSave = async () => {
    try {
      setCurrentView('LoadingScreen')

      const formData = new FormData()
      formData.set('helpfile', localFileData.file)
      formData.set('title', formik.values.title)

      await AdminVoterAuthService.createHelpDoc(formData)
      props.getHelpDocs()

      onClose()
    } catch (error) {
      setUploadErr(translate('Something went wrong, please contact support'))
      setCurrentView('uploadError')
      console.log(error)
    }
  }

  const AddDocument = (
    <>
      <ModalHeader
        customStyles={{padding: '23px 25px'}}
        onRequestClose={onClose}
        title={translate('Add Document')}
      />
      <div className='AddDocument'>
        <TextField
          formik={formik}
          type='text'
          field='title'
          placeholder={translate('Title')}
          label={translate('Title')}
          isRequired={true}
          view='voter'
        />

        <div
          className='addDocument__upload'
          onClick={() => {
            setCurrentView('Upload File')
          }}
        >
          <div className='addDocument__upload__iconWrap'>
            <img src={toAbsoluteUrl('/media/design/icon_add_img_pink.svg')} alt='upload' />
            <span className='addDocument__upload__txt'>{translate('Add Document')}</span>
          </div>
          <span>25MB {translate('Max')}</span>
        </div>
      </div>

      <div className='ModalFooter'>
        <button type='submit' className={'eventEditModal__cancelBtn'} onClick={onClose}>
          {translate('Cancel')}
        </button>

        <button
          type='submit'
          className={
            customBtnClass ? 'modalFooter__primaryBtn ' + customBtnClass : 'modalFooter__primaryBtn'
          }
          onClick={() => formik.handleSubmit()}
        >
          {translate('Add')}
        </button>
      </div>
    </>
  )

  /* VIEW #2: SELECT FILE TO UPLOAD  */
  const UploadFile = (
    <>
      <ModalHeader
        customStyles={{padding: '23px 25px'}}
        onRequestClose={onClose}
        title={translate('Add Document')}
      />

      <div>
        <div className='addImageModal__uploadContainer' onClick={onImageClick}>
          <img src={toAbsoluteUrl('/media/design/icon_pink_upload.svg')} alt='upload' />
          <h4 className='addImageModal__uploadTxt'>{translate('File Uploader')}</h4>
          <span className='addImageModal__uploadSubTxt' style={{padding: 25, textAlign: 'center'}}>
            {/* Drag & Drop files here 25MB Max (jpg, gif, png) */}
            {/* Note temp until drag and drop */}
            {translate(`Click to upload file. 25MB Max`)}({allowedFiles.join(', ')})
          </span>
        </div>

        <input type='file' onChange={onFileUpload} ref={uploadRef} style={{display: 'none'}} />
      </div>

      <div className='ModalFooter'>
        <button type='submit' className={'eventEditModal__cancelBtn'} onClick={onClose}>
          {translate('Cancel')}
        </button>

        <button
          type='submit'
          className={
            customBtnClass ? 'modalFooter__primaryBtn ' + customBtnClass : 'modalFooter__primaryBtn'
          }
          onClick={() => {
            setCurrentView('Selected Document')
          }}
        >
          {translate('Upload')}
        </button>
      </div>
    </>
  )

  const errorView = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={onClose} headerLabel={translate('Uploading Error')} />
      <div className='importAddrListM__wrongFileTypeErr'>
        <img
          className='importAddrListM__wrongFileTypeErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
          alt='error'
        />
        <span className='importAddrListM__wrongFileTypeErr__txt' style={{whiteSpace: 'pre-wrap'}}>
          {uploadErr}
        </span>
      </div>
      <ModalFooter onRequestClose={onClose} />
    </>
  )

  // LOADING SCREEN
  const LoadingScreen = (
    <>
      <div className='ReportDownload__loading'>
        <img
          className='reportDownloaded__icon'
          src={toAbsoluteUrl('/media/design/Spinning_neuvote.gif')}
          alt='loading'
        />
      </div>
    </>
  )

  const SelectedDocument = (
    <>
      <ModalHeader
        customStyles={{padding: '23px 25px'}}
        onRequestClose={onClose}
        title={translate('Add Document')}
      />
      <div className='AddDocument'>
        <TextField
          formik={formik}
          type='text'
          field='title'
          placeholder={translate('Title')}
          label={translate('Title')}
          isRequired={true}
          view='voter'
        />

        <div
          className='addDocument__changeDoc'
          onClick={() => {
            setCurrentView('Upload File')
          }}
        >
          <img src={toAbsoluteUrl('/media/design/icon_removeImage_pink.svg')} alt='remove' />
          <span className='addDocument__upload__txt'>{translate('Change Document')}</span>
        </div>

        <div className='addDocument__upload addDocument__changeDoc__upload'>
          <div className='addDocument__upload__iconWrap'>
            {getFileType(localFileData.name) == 'pdf' && (
              <img className='' src={toAbsoluteUrl('/media/design/icon_pdf.svg')} alt='pdf' />
            )}
            {getFileType(localFileData.name) == 'jpg' ||
              (getFileType(localFileData.name) == 'jpeg' && (
                <img className='' src={toAbsoluteUrl('/media/design/icon_jpg.svg')} alt='jpg' />
              ))}
            {getFileType(localFileData.name) == 'png' && (
              <img className='' src={toAbsoluteUrl('/media/design/icon_png.svg')} alt='png' />
            )}
            {getFileType(localFileData.name) == 'doc' ||
              (getFileType(localFileData.name) == 'docx' && (
                <img
                  className=''
                  src={toAbsoluteUrl('/media/design/icon_doc.svg')}
                  alt='doc, docx'
                />
              ))}
            {['mov', 'mp4'].includes(getFileType(localFileData.name)) && (
              <img
                className=''
                src={toAbsoluteUrl('/media/design/icon_video.svg')}
                alt='mov, mp4'
              />
            )}
            {['mp3', 'wav'].includes(getFileType(localFileData.name)) && (
              <img
                className=''
                src={toAbsoluteUrl('/media/design/icon_audio.svg')}
                alt='mp3, wav'
              />
            )}
            <span className='addDocument__upload__txt'>{localFileData.name}</span>
          </div>
        </div>
      </div>

      <div className='ModalFooter'>
        <button type='submit' className={'eventEditModal__cancelBtn'} onClick={onClose}>
          {translate('Cancel')}
        </button>

        <button
          type='submit'
          className={
            customBtnClass ? 'modalFooter__primaryBtn ' + customBtnClass : 'modalFooter__primaryBtn'
          }
          onClick={() => onFileSave()}
        >
          {translate('Add')}
        </button>
      </div>
    </>
  )

  const wrongFileTypeErr = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={onClose} headerLabel={translate('Uploading Error')} />
      <div className='importAddrListM__wrongFileTypeErr'>
        <img
          className='importAddrListM__wrongFileTypeErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
          alt='error'
        />
        <span className='importAddrListM__wrongFileTypeErr__txt'>
          {translate(
            'The file couldn’t be uploaded because it isn’t in the supported format or the file might be corrupted'
          )}
        </span>

        <span className='importAddrListM__wrongFileTypeErr__subTxt'>
          {translate('Please try again using supported file type. Accepted file format is')}
          <span className='civik-primary-blue'> ({allowedFiles.join(', ')})</span>.{' '}
        </span>
      </div>
      <ModalFooter onRequestClose={onClose} />
    </>
  )

  const renderView = () => {
    if (currentView === 'Add Document') {
      return AddDocument
    }
    if (currentView === 'Upload File') {
      return UploadFile
    }
    if (currentView === 'uploadError') {
      return errorView
    }
    if (currentView === 'Selected Document') {
      return SelectedDocument
    }
    if (currentView === 'wrongFileTypeErr') {
      return wrongFileTypeErr
    }
    if (currentView === 'LoadingScreen') {
      return LoadingScreen
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={onClose}
      style={customStyles}
    >
      <div className='ModalWrapper' style={{flexGrow: 1, overflowY: 'visible'}}>
        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </Modal>
  )
}
