import React, {Component} from 'react'
import clsx from 'clsx'

import './TextAreaField.scss'

class TextAreaField extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      customClass,
      formik,
      type,
      field,
      placeholder,
      label,
      isRequired,
      noLabel,
      cols,
      rows,
      customStyling,
      hideErr,
    } = this.props

    return (
      <div>
        {!noLabel && (
          <label
            className={
              isRequired
                ? 'form-label fs-6 fw-bold text-dark required'
                : 'form-label fs-6 fw-bold text-dark'
            }
          >
            {label}
          </label>
        )}

        <textarea
          style={customStyling ? customStyling : {}}
          rows={rows ? rows : '4'}
          cols={cols ? cols : '50'}
          placeholder={placeholder}
          {...formik.getFieldProps(field)}
          className={clsx(
            'TextAreaField' + ` ${customClass}`,
            {'is-invalid': formik.touched[field] && formik.errors[field]},
            {
              'is-valid': formik.touched[field] && !formik.errors[field],
            }
          )}
          type='text'
          name={field}
          autoComplete='off'
          ref={this.props.inputRef}
        />

        {!hideErr && (
          <>
            {formik.touched[field] && formik.errors[field] && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors[field]}</span>
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

export default TextAreaField
