import {useContext, useEffect, useState} from 'react'
import {WorkerProxy, WorkerProxyConf} from '@tivi/core-ts'
import {EventVoteCard} from '../voter_admin/pages/mexico/user_dashboard/dashboard/components/event_vote_card/EventVoteCard'
import {LanguageContext} from '../../contexts/LanguageContext'
import {useHistory, useLocation} from 'react-router-dom'
import KeycloakService from '../../../Keycloak'
import {useTranslation} from '../../hooks/useTranslation'

type StateProps = {
  event: any
  hasVoted: boolean
  receipt?: String
}

export const VoteReceipt = () => {
  const {translate} = useTranslation()

  const [workerProxy, setWorkerProxy] = useState<WorkerProxy | null>(null)
  const {state} = useLocation<StateProps>()
  const event = state?.event ? state.event : null

  const history = useHistory()

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  useEffect(() => {
    try {
      const workerProxyConf: WorkerProxyConf = {
        workerLocation: './workers/worker.js',
        oneKeyIdURL: '',
        oneKeyAuthURL: '',
        clsURLTemplate: '',
        pbbURLTemplate: '',
        randomURL: '',
        sessionURL: '',
        submitURLTemplate: '',
        elections: {},
      }
      let newWorkerProxy = new WorkerProxy(workerProxyConf)
      setWorkerProxy(newWorkerProxy)
    } catch (error) {}
  }, [])

  async function checkReceipt(receipt, contestIndex = 0) {
    try {
      const verifyURL =
        event?.questions && event?.questions.length
          ? event.questions[contestIndex]?.voteVerifyLink
          : event?.voteVerifyLink

      let data = await workerProxy.isVoteVerified(receipt, verifyURL)
      if (data) {
        // console.log(data)
        // data ? alert('valid') : alert('invalid')
        return true
      }
      return false
    } catch (err) {
      console.log('error validating', err)
      // alert('error')
      return false
    }
  }
  if (!event || !KeycloakService.isLoggedIn) {
    history.replace('/voting/voter-login')
  }

  return (
    <div
      className={
        // TODO: fix, not compatible with multiple languages
        LANGUAGE_CONTEXT.currentLanguage !== 'English'
          ? 'MEXICO__Dashboard--spanish'
          : 'MEXICO__Dashboard'
      }
    >
      <div className='common__pg__header '>
        <span
          className='common__backBtn'
          onClick={() => {
            history.push('/voting/voter/dashboard')
          }}
        >
          {'< ' + translate('Back')}
        </span>

        <span className='common__pg__header__title'>{translate('Elections')}</span>
      </div>

      <div
        className={
          LANGUAGE_CONTEXT.currentLanguage !== 'English'
            ? 'dashboard__eventVoteCard--spanish'
            : 'dashboard__eventVoteCard'
        }
      >
        <span className='dashboard__eventVoteCard__title'> {translate('Event Details')}</span>
        {event && (
          <EventVoteCard
            event={event}
            hasVoted={state.hasVoted}
            checkReceipt={checkReceipt}
            receiptParam={state.receipt}
          />
        )}
      </div>
    </div>
  )
}
export default VoteReceipt
