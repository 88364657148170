import Keycloak from 'keycloak-js'
import environment from './utils/environment'

const keycloakConfig = {
  url: environment.resolveApi().keycloak_url_admin,
  clientId: 'voter-portal',
  realm: environment.resolveApi().realm ? environment.resolveApi().realm : 'tivi',
}

const _kc = new Keycloak(keycloakConfig)

// const _kc = new Keycloak({
//   url: 'http://localhost:8080/auth',
//   realm: 'voter-registration',
//   clientId: 'voter-registration-react',
// })
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!')
      } else {
        console.log('user is authenticated.')
      }
      onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const updateToken = (timeToExpiry) => _kc.updateToken(timeToExpiry)

const getUsername = () => _kc.tokenParsed?.preferred_username

const getParsedToken = () => _kc.tokenParsed

const hasRole = (role) => _kc.hasRealmRole(role)

const onTokenExpired = async (onSessionExpired, timeToExpiry) => {
  try {
    await _kc.updateToken(timeToExpiry)
  } catch (error) {
    onSessionExpired()
  }
}

const getSessionStartTime = () => _kc.tokenParsed?.auth_time

const KeycloakAdminService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getParsedToken,
  onTokenExpired,
  getSessionStartTime,
}

export default KeycloakAdminService
