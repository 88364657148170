import {CustomBtn} from '../../../../../reusable/custom_btn/CustomBtn'
import {LabelAndValue} from '../../../../../reusable/label_and_value/LabelAndValue'
import {LineDivider} from '../../../../../reusable/line_divider/LineDivider'
import {ParticipantContactInfo} from '../components/contact_info/ParticipantContactInfo'
import './SurveyResponses.scss'

export const SurveyResponses = ({voter, setCurrentView}) => {
  return (
    <div className='SuperAdmin__ParticipantDetails' id='Voter'>
      {/* Body */}
      <div className='participantDetails__body'>
        {/* Header */}
        <div className='participantDetails__body__header'>
          <span className='participantDetails__body__header__voterID'>
            Participant ID: {voter.voterId}
          </span>
        </div>

        <LineDivider />

        <ParticipantContactInfo
          dob={voter.voterInfo.dateOfBirth}
          phone={voter.phone}
          email={voter.email}
        />

        <LineDivider />

        {/* Responses */}
        <div className='surveyResponses__response'>
          <LabelAndValue
            label='Informed to Make a decision'
            value={voter.voterInfo.informedToMakeDecision}
          />

          <LabelAndValue
            label='Why Ready to Decide'
            value={voter.voterInfo.reasonInformed == '' ? 'N/A' : voter.voterInfo.reasonInformed}
          />

          <LabelAndValue
            label='Info Needed To Make a Decision'
            value={
              voter.voterInfo.infoNeededToDecide == '' ? 'N/A' : voter.voterInfo.infoNeededToDecide
            }
          />

          <LabelAndValue
            label='Preferred way to receive information'
            value={
              voter.voterInfo.preferredInfoDeliveryMethod == ''
                ? 'N/A'
                : voter.voterInfo.preferredInfoDeliveryMethod
            }
          />

          <LabelAndValue label='Want Interview' value={voter.voterInfo.wantInterview} />

          {/* <LabelAndValue label='Interview Invitation Sent' value='NO' /> */}
        </div>

        <div className='ModalFooter'>
          <CustomBtn
            view='PrimaryBtn'
            text={'Back'}
            height={48}
            onClick={() => {
              setCurrentView('default')
            }}
          />
        </div>
      </div>
    </div>
  )
}
