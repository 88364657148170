/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {useTranslation} from '../../../../../hooks/useTranslation'

export function PasswordValidate__SuperAdmin(props) {
  const {translate} = useTranslation()

  let {formik} = props

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const [errorList, setErrorList] = useState([])

  const removeFromList = (list, indexToRemove) => {
    list.splice(indexToRemove, 1)
    setErrorList(list)
  }

  const passwordValidate = (password) => {
    let list = errorList

    // ERROR #1 - Minimum 12 characters in length -----------------
    if (password.length >= 12) {
      let indexToRemove = list.indexOf(translate('Minimum 12 characters in length'))
      // if the value is in the listOne special character (Recommended)
      if (indexToRemove !== -1) {
        removeFromList(list, indexToRemove)
      }
    }

    if (password.length < 12) {
      //check if it not there already
      if (!list.includes(translate('Minimum 12 characters in length'))) {
        list.push(translate('Minimum 12 characters in length'))
        setErrorList(list)
      }
    }

    // ERROR #2 - At least one uppercase letter -----------------
    let upperCaseRegex = /[A-Z]/g
    if (password.match(upperCaseRegex)) {
      let indexToRemove = list.indexOf(translate('At least one uppercase letter'))
      // if the value is in the list
      if (indexToRemove !== -1) {
        list.splice(indexToRemove, 1)
        setErrorList(list)
      }
    }

    if (!password.match(upperCaseRegex)) {
      //check if it not there already
      if (!list.includes(translate('At least one uppercase letter'))) {
        list.push(translate('At least one uppercase letter'))
        setErrorList(list)
      }
    }

    // ERROR #3 - At least one lowercase letter -----------------
    let lowerCaseRegex = /[a-z]/g
    if (password.match(lowerCaseRegex)) {
      let indexToRemove = list.indexOf(translate('At least one lowercase letter'))
      // if the value is in the list
      if (indexToRemove !== -1) {
        list.splice(indexToRemove, 1)
        setErrorList(list)
      }
    }

    if (!password.match(lowerCaseRegex)) {
      //check if it not there already
      if (!list.includes(translate('At least one lowercase letter'))) {
        list.push(translate('At least one lowercase letter'))
        setErrorList(list)
      }
    }

    // ERROR #4 - At least one number letter -----------------
    let numberRegex = /\d/

    if (numberRegex.test(password)) {
      let indexToRemove = list.indexOf(translate('At least one number'))
      // if the value is in the list
      if (indexToRemove !== -1) {
        list.splice(indexToRemove, 1)
        setErrorList(list)
      }
    }

    if (!numberRegex.test(password)) {
      //check if it not there already
      if (!list.includes(translate('At least one number'))) {
        list.push(translate('At least one number'))
        setErrorList(list)
      }
    }

    let specialCharRegex = /\W/

    if (specialCharRegex.test(password)) {
      let indexToRemove = list.indexOf(translate('One special character'))
      // if the value is in the list
      if (indexToRemove !== -1) {
        list.splice(indexToRemove, 1)
        setErrorList(list)
      }
    }

    if (!specialCharRegex.test(password)) {
      //check if it not there already
      if (!list.includes(translate('One special character'))) {
        list.push(translate('One special character'))
        setErrorList(list)
      }
    }
  }

  useEffect(() => {
    passwordValidate(formik.values.password)

    // if there are no errors
    if (errorList.length == 0) {
      props.result(true)
      console.log('valid no erorrs')
    } else {
      props.result(false)
    }
  }, [formik.values.password])

  useEffect(() => {
    setErrorList([
      translate('Minimum 12 characters in length'),
      translate('At least one uppercase letter'),
      translate('At least one lowercase letter'),
      translate('At least one number'),
      translate('One special character'),
    ])

    formik.values.password = ''
    formik.values.confirmPassword = ''
  }, [LANGUAGE_CONTEXT.currentLanguage])

  return (
    <div className='createPassword__form__body__validationContainer'>
      <span
        className={`createPassword__form__body__validation__title ${
          errorList.length == 0 ? 'createPassword__form__body__validation__title--green' : ''
        }`}
      >
        {translate('Your password must meet the minimum requirements')}
      </span>

      {/* ERROR 1 -  Minimum 12 characters in length */}
      {!errorList.includes(translate('Minimum 12 characters in length')) ? (
        // SUCCESS
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/green_check_circle_icon.svg')}
          />

          <span className='createPassword__form__body__validation__successTxt' role='alert'>
            {translate('Minimum 12 characters in length')}
          </span>
        </div>
      ) : (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/red_x_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__errorTxt' role='alert'>
            {translate('Minimum 12 characters in length')}
          </span>
        </div>
      )}

      {/* ERROR 2 -  At least one uppercase letter */}
      {!errorList.includes(translate('At least one uppercase letter')) ? (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/green_check_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__successTxt' role='alert'>
            {translate('At least one uppercase letter')}
          </span>
        </div>
      ) : (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/red_x_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__errorTxt' role='alert'>
            {translate('At least one uppercase letter')}
          </span>
        </div>
      )}

      {/* ERROR 3 -  At least one lowercase letter */}
      {!errorList.includes(translate('At least one lowercase letter')) ? (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/green_check_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__successTxt' role='alert'>
            {translate('At least one lowercase letter')}
          </span>
        </div>
      ) : (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/red_x_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__errorTxt' role='alert'>
            {translate('At least one lowercase letter')}
          </span>
        </div>
      )}

      {/* ERROR 4 - At least one number letter */}
      {!errorList.includes(translate('At least one number')) ? (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/green_check_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__successTxt' role='alert'>
            {translate('At least one number')}
          </span>
        </div>
      ) : (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/red_x_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__errorTxt' role='alert'>
            {translate('At least one number')}
          </span>
        </div>
      )}

      {/* ERROR 5 - One special character */}
      {!errorList.includes(translate('One special character')) ? (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/green_check_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__successTxt' role='alert'>
            {translate('One special character')}
          </span>
        </div>
      ) : (
        <div className='createPassword__form__body__validationWrap'>
          <img
            className='createPassword__form__body__validation__icon'
            src={toAbsoluteUrl('/media/design/red_x_circle_icon.svg')}
          />
          <span className='createPassword__form__body__validation__errorTxt' role='alert'>
            {translate('One special character')}
          </span>
        </div>
      )}
    </div>
  )
}
