import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useVoterStyles} from '../../../contexts/VoterStylesContext'

const BackgroundBanner = () => {
  const {customBannerImg} = useVoterStyles()

  return (
    <img alt='banner' className='voterRegister__topBanner' src={toAbsoluteUrl(customBannerImg)} />
  )
}

export default BackgroundBanner
