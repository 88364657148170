import {useEffect} from 'react'
import CheckBox from '../../../../../reusable/check_box/CheckBox'
import {CustomBtn} from '../../../../../reusable/custom_btn/CustomBtn'
import FormikError from '../../../../../reusable/formik_error/FormikError'
import {LineDivider} from '../../../../../reusable/line_divider/LineDivider'
import {AddressInfo} from '../../../../../voter/components/registration/AddressInfo'
import {ContactInfo} from '../../../../../voter/components/registration/ContactInfo'
import {MailingInfo} from '../../../../../voter/components/registration/MailingInfo'
import {UserInfo} from '../../../../../voter/components/registration/UserInfo'
import {ParticipantPhotoInfo} from '../components/photo_info/ParticipantPhotoInfo'

export const EditVoter = ({
  formik,
  voter,
  onFormatBirthDate,
  onHasPropertyIgnace,
  setOnHasPropertyIgnace,
  voterTerms,
  setVoterTerms,
  setCurrentView,
  loading,
  getFormSchema,
  setFormSchema,
}) => {
  useEffect(() => {
    let schema = getFormSchema()
    setFormSchema(schema)
  }, [formik.values.singleName, onHasPropertyIgnace])

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='SuperAdmin__ParticipantDetails' id='Voter'>
        {/* Body */}
        <div className='participantDetails__body'>
          {/* Header */}
          <div className='participantDetails__body__header'>
            <span className='participantDetails__body__header__voterID'>
              Voter ID: {voter.voterId}
            </span>
          </div>

          <LineDivider />

          {/* Form */}

          <UserInfo
            formik={formik}
            onSingleName={(value) => {
              // setSingleName(value)
              formik.values.singleName = value

              let schema = getFormSchema()
              setFormSchema(schema)
            }}
            changeSchema={() => {}}
            customStyling={{padding: 30, marginTop: 0}}
            onFormatBirthDate={onFormatBirthDate}
          />

          <ContactInfo
            formik={formik}
            view={'VoterRegister'}
            customStyling={{padding: 30, marginTop: 0}}
          />

          <AddressInfo
            formik={formik}
            view='VoterRegister'
            customStyling={{padding: '0px 30px 0px 30px', marginTop: 0}}
          />

          {/* Current Residential Address  --------- */}
          <CheckBox
            containerStyling={{marginLeft: 30, marginBottom: 30, marginTop: 0}}
            checked={onHasPropertyIgnace}
            onChange={(value) => {
              setOnHasPropertyIgnace(value)
              let schema = getFormSchema()
              setFormSchema(schema)
            }}
            error={null}
            label='I own property in Ignace but currently reside elsewhere.'
          />

          {/* Ignace Property Address ---------  */}
          {onHasPropertyIgnace && (
            <MailingInfo
              formik={formik}
              customStyling={{padding: '0px 30px 30px 30px', marginTop: 0}}
            />
          )}

          {formik.touched['mailingAddress'] && formik.errors['mailingAddress'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['mailingAddress']}
                </span>
              </div>
            </div>
          )}

          <LineDivider />

          <ParticipantPhotoInfo photos={voter.voterInfo.identification} />

          <FormikError
            formik={formik}
            fieldName='photosList'
            errorLabel='Upload at least one piece of photo identification!'
            customStyling={{marginLeft: 30, marginBottom: 30}}
          />

          <LineDivider />

          <CheckBox
            checked={voterTerms}
            onChange={(value) => {
              formik.setFieldError('voterTerms', '')
              formik.values.voterTerms = value
              setVoterTerms(value)
            }}
            error={null}
            label='I declare that I am legally eligible to vote'
            containerStyling={{marginLeft: 30, marginBottom: 30, marginRight: 30}}
          />

          {/* Footer */}
          <div className='ModalFooter'>
            <CustomBtn
              view='PrimaryBtn'
              text={'Cancel'}
              height={48}
              customStyling={{marginLeft: 25, marginRight: 25}}
              onClick={() => {
                setCurrentView('default')
              }}
            />

            <CustomBtn
              view='ActionBtn'
              text={'Update'}
              height={48}
              onClick={() => {
                let schema = getFormSchema()
                setFormSchema(schema)

                formik.submitForm()
              }}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </form>
  )
}
