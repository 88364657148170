/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
import {useFormik} from 'formik'
import './EventInfo.scss'
import './EventEditModal.scss'
import * as Yup from 'yup'
import {ModalHeader} from '../../../../../modals/ModalHeader'
import Modal from 'react-modal'
import {useVoterStyles} from '../../../../../../contexts/VoterStylesContext'
import dateFormat from 'dateformat'
import SelectField from '../../../../../../../app/modules/auth/reusable/SelectField'
import DatePicker from 'react-datepicker'
import AdminVoterService from '../../../../../../services/admin/AdminVoterService'
import {districts} from '../../../../../../portalData/Districts'
import {useTranslation} from '../../../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const district = [{value: 0, label: 'District'}, ...districts]
const channel = [
  {value: 0, label: 'Channel'},
  {value: 'online', label: 'Online'},
  {value: 'inPerson', label: 'In-Person'},
]

export const initialValues = {
  district: '',
  channel: '',
}

export const schema = Yup.object().shape({
  district: Yup.string().required('District is required!'),
})

export function EventEditModal(props) {
  const {translate} = useTranslation()
  let {headerLabel, modalOpen, onRequestClose, event, updateEvent} = props

  const [startDate, setStartDate] = useState(null)
  const [loading, setLoading] = useState(false)

  const {customBtnClass} = useVoterStyles()

  const [modalIsOpen, setIsOpen] = useState(false)

  function afterOpenModal() {}
  function onAfterCompleteModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          if (values.district) {
            let update = await AdminVoterService.updateEvent(event._id, values.district, null)
            console.log('update', update)
            if (update.success) {
              console.log(update.event)
              updateEvent(update.event)
              props.onRequestClose()
            }
          }
          if (values.channel) {
            let update = await AdminVoterService.updateEvent(event._id, null, values.channel)
            if (update.success) {
              updateEvent(update.event)
              props.onRequestClose()
            }
          }
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  const VotingOpenDate = (
    <div className='VotingOpenDate'>
      <DatePicker
        showTimeSelect
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        className='eventEditModal__input'
      />

      <span className='eventEditModal__dateTimeTxt'>
        {' '}
        {startDate ? dateFormat(startDate, 'mmmm dS, h:MM TT') : ''}
      </span>
    </div>
  )
  const VotingClosingDate = (
    <div className='VotingOpenDate'>
      {' '}
      <DatePicker
        showTimeSelect
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        className='eventEditModal__input'
      />
      <span className='eventEditModal__dateTimeTxt'>
        {' '}
        {startDate ? dateFormat(startDate, 'mmmm dS, h:MM TT') : ''}
      </span>
    </div>
  )
  const District = (
    <div className='VotingOpenDate'>
      <SelectField
        formik={formik}
        type='text'
        field='district'
        placeholder='District'
        label=''
        isRequired={false}
        options={district}
        view='voter'
      />
    </div>
  )
  const Channel = (
    <div className='VotingOpenDate'>
      <SelectField
        formik={formik}
        type='text'
        field='channel'
        placeholder='Channel'
        label=''
        isRequired={false}
        options={channel}
        view='voter'
      />
    </div>
  )

  const renderView = () => {
    if (headerLabel == 'Voting opening date') {
      return VotingOpenDate
    }

    if (headerLabel == 'Voting closing date') {
      return VotingClosingDate
    }

    if (headerLabel == 'District') {
      return District
    }

    if (headerLabel == 'Channel') {
      return Channel
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className='ModalWrapper' style={{flexGrow: 1, overflowY: 'visible'}}>
        <ModalHeader onRequestClose={props.onRequestClose} title={translate(headerLabel)} />

        {/* BODY CONTENT */}
        {renderView()}

        <div className='ModalFooter'>
          <button
            type='submit'
            className={'eventEditModal__cancelBtn'}
            onClick={props.onRequestClose}
          >
            {translate('Cancel')}
          </button>

          <button
            type='submit'
            className={
              customBtnClass
                ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
                : 'modalFooter__primaryBtn'
            }
            onClick={() => formik.handleSubmit()}
          >
            {translate('Save')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
