import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import './AdminSidebarMobile.scss'
import '../Common.scss'
import {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {AdminSVG} from '../admin_svg/AdminSVG'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import Modal from 'react-modal'
import {AdminDropdown} from '../admin_dropdown/AdminDropdown'
import {VoterAuthContext} from '../../../../contexts/VoterAuthContext'
import {VoterLoginFlowContext} from '../../../../contexts/VoterLoginFlowContext'
import KeycloakService from '../../../../../Keycloak'
import {DateTime} from 'luxon'
import {VoterUserContext} from '../../../../contexts/VoterUserContext'
import KeycloakAdminService from '../../../../../KeycloakAdminService'
import VoterAuthService from '../../../../services/auth/VoterAuthService'
import AdminVoterAuthService from '../../../../services/auth/AdminVoterAuthService'
import {useTranslation} from '../../../../hooks/useTranslation'

const customStyles = {
  content: {
    borderRadius: '4px',
    border: 'none',
    width: 299,
    height: '100%',
    top: 0,
    left: 0,
    padding: 0,
    maxWidth: 400,
  },

  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99999999,
  },
}

export function AdminSidebarMobile(props) {
  const {translate} = useTranslation()

  let {sideNavbarOpen, setSideNavbarOpen} = props

  const history = useHistory()
  const [currentTab, setCurrentTab] = useState('Dashboard')

  const {customMobileLogoImg, voterStyleView, customTxtClass} = useVoterStyles()
  const VOTER_AUTH_CONTEXT = useContext(VoterAuthContext)
  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)
  const VoterUser = useContext(VoterUserContext)
  const [currentTime, setCurrentTime] = useState(null)

  const activeTab = {
    nova_scotia: 'adminSideBar__tabActiveWrap',
    mexico: 'adminSideBar__tabActiveWrap--Mexico',
    nwt: 'adminSideBar__tabActiveWrap',
    ignace: 'adminSideBar__tabActiveWrap',
    smartmatic: 'adminSideBar__tabActiveWrap',
  }

  const activeTabTxt = {
    nova_scotia: 'adminSideBar__tabActiveTxt',
    mexico: 'adminSideBar__tabActiveTxt--Mexico',
    nwt: 'adminSideBar__tabActiveTxt',
    ignace: 'adminSideBar__tabActiveTxt',
    smartmatic: 'adminSideBar__tabActiveTxt',
  }

  const getCurrentTime = () => {
    let currentIgnaceTime = DateTime.now().setZone('America/Toronto')

    let hours = currentIgnaceTime.hour
    let minutes = currentIgnaceTime.minute
    let full_time = hours + ':' + (minutes < 10 ? '0' + minutes : minutes)

    if (full_time !== currentTime) {
      setCurrentTime(full_time)
    }
  }

  useEffect(() => {
    const interval = setInterval(getCurrentTime, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={sideNavbarOpen}
      onRequestClose={() => {
        setSideNavbarOpen(false)
      }}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <div className='AdminSidebarMobile'>
        {/* HEADER */}
        <div className='adminSidebarM__header'>
          <img className='adminSidebarM__header__logo' src={customMobileLogoImg} />

          <img
            onClick={() => {
              setSideNavbarOpen(false)
            }}
            className='adminSidebarM__header__xIcon'
            src={toAbsoluteUrl('/media/design/icon_x.svg')}
          />
        </div>

        <div className='adminSidebarM__bodyFooterContainer'>
          {/* BODY */}
          <div className='adminSidebarM__body'>
            <span className='adminSidebarM__body__title'>
              {translate('Hello')},{' '}
              <span className='common__fw700'>{VoterUser?.user?.username}</span>
            </span>
            <div className='adminSideBar__tabContainer adminSidebarM__body__tabContainer'>
              {currentTab == 'Dashboard' ? (
                <div className={activeTab[voterStyleView]}>
                  <AdminSVG icon='Dashboard' isMobile={true} />
                  <span className={activeTabTxt[voterStyleView]}>{translate('Dashboard')}</span>
                </div>
              ) : (
                <div
                  className='adminSideBar__tabInactiveWrap'
                  onClick={() => {
                    history.push('/voting/voter/dashboard')
                    setCurrentTab('Dashboard')
                    setSideNavbarOpen(false)
                  }}
                >
                  <AdminSVG icon='Dashboard' isMobile={true} inactive={true} />
                  <span className='adminSideBar__tabInactiveTxt'>{translate('Dashboard')}</span>
                </div>
              )}

              {voterStyleView !== 'smartmatic' ? (
                <>
                  {currentTab == 'Address List' ? (
                    <div className='adminSideBar__tabActiveWrap'>
                      <img
                        src={toAbsoluteUrl('/media/design/admin_map_active_icon.svg')}
                        alt='dashboard icon'
                      />
                      <span className='adminSideBar__tabActiveTxt'>
                        {translate('Address List')}
                      </span>
                    </div>
                  ) : (
                    <div
                      className='adminSideBar__tabInactiveWrap'
                      onClick={() => {
                        history.push('/voter/address-list')
                        setCurrentTab('Address List')
                        setSideNavbarOpen(false)
                      }}
                    >
                      <img
                        src={toAbsoluteUrl('/media/design/admin_map_icon.svg')}
                        alt='dashboard icon'
                      />
                      <span className='adminSideBar__tabInactiveTxt'>Address List</span>
                    </div>
                  )}

                  {currentTab == 'Electors List' ? (
                    <div className='adminSideBar__tabActiveWrap'>
                      <img
                        src={toAbsoluteUrl('/media/design/admin_electors_active_icon.svg')}
                        alt='dashboard icon'
                      />
                      <span className='adminSideBar__tabActiveTxt'>Electors List</span>
                    </div>
                  ) : (
                    <div
                      className='adminSideBar__tabInactiveWrap'
                      onClick={() => {
                        history.push('/voter/elector-list')
                        setCurrentTab('Electors List')
                        setSideNavbarOpen(false)
                      }}
                    >
                      <img
                        src={toAbsoluteUrl('/media/design/admin_electors_icon.svg')}
                        alt='dashboard icon'
                      />
                      <span className='adminSideBar__tabInactiveTxt'>Electors List</span>
                    </div>
                  )}

                  {currentTab == 'Duplication List' ? (
                    <div className='adminSideBar__tabActiveWrap'>
                      <img
                        src={toAbsoluteUrl('/media/design/admin_duplicate_active_icon.svg')}
                        alt='dashboard icon'
                      />
                      <span className='adminSideBar__tabActiveTxt'>Duplication List</span>
                    </div>
                  ) : (
                    <div
                      className='adminSideBar__tabInactiveWrap'
                      onClick={() => {
                        history.push('/voter/duplicate-list')
                        setCurrentTab('Duplication List')
                        setSideNavbarOpen(false)
                      }}
                    >
                      <img
                        src={toAbsoluteUrl('/media/design/admin_duplicate_icon.svg')}
                        alt='dashboard icon'
                      />
                      <span className='adminSideBar__tabInactiveTxt'>Duplication List</span>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {currentTab == 'Help & Support' ? (
                    <div className={activeTab[voterStyleView]}>
                      <AdminSVG icon='Help & Support' />
                      <span className={activeTabTxt[voterStyleView]}>
                        {translate('Help & Support')}
                      </span>
                    </div>
                  ) : (
                    <div
                      className='adminSideBar__tabInactiveWrap'
                      onClick={() => {
                        history.push('/voting/voter/help-support')
                        setCurrentTab('Help & Support')
                        setSideNavbarOpen(false)
                      }}
                    >
                      <AdminSVG icon='Help & Support' inactive />
                      <span className='adminSideBar__tabInactiveTxt'>
                        {translate('Help & Support')}
                      </span>
                    </div>
                  )}

                  {currentTab == 'My Profile' ? (
                    <div className={activeTab[voterStyleView]}>
                      <AdminSVG icon='My Profile' />
                      <span className={activeTabTxt[voterStyleView]}>
                        {translate('My Profile')}
                      </span>
                    </div>
                  ) : (
                    <div
                      className='adminSideBar__tabInactiveWrap'
                      onClick={() => {
                        history.push('/voting/voter/my-profile')
                        setCurrentTab('My Profile')
                        setSideNavbarOpen(false)
                      }}
                    >
                      <img
                        width='27'
                        height='27'
                        src={toAbsoluteUrl('/media/design/admin_myprofile_icon.svg')}
                        alt='dashboard icon'
                      />
                      <span className='adminSideBar__tabInactiveTxt'>
                        {translate('My Profile')}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className='adminSidebarM__adminDropdownWrapper--languages'>
              <AdminDropdown view='languages' customStyling={{width: 120}} />
            </div>
          </div>

          <div>
            <span className='adminSidebarM__currentTimeTxt'>
              <span> {translate('Current time')}:</span>
              <span className='common__fw700'>{currentTime}</span>
            </span>

            {/* FOOTER */}
            <div className='adminSidebarM__footer'>
              <button
                className='adminSidebarM__footer__logoutBtn'
                onClick={async () => {
                  try {
                    if (KeycloakService.isLoggedIn()) {
                      VOTER_LOGIN_CONTEXT.setLoginFlow('Login')
                      await VoterAuthService.logOut(history)
                    }
                    if (KeycloakAdminService.isLoggedIn()) {
                      VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Login')
                      await AdminVoterAuthService.logOut(history)
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                {translate('Logout')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
