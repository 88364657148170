/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'

import Modal from 'react-modal'

import './NewAdminUserModal.scss'

import {useVoterStyles} from '../../../../../../../contexts/VoterStylesContext'
import {ModalHeader} from '../../../../../../voter/reusable/modals/modal_header/ModalHeader'

import TextField from '../../../../../../../modules/auth/reusable/TextField'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import AdminVoterAuthService from '../../../../../../../services/auth/AdminVoterAuthService'
import CustomAdminDropdown from '../../../../../../voter_admin/reusable/admin_dropdown/AdminFormDropDown'
import {useTranslation} from '../../../../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'scroll',
  },
}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  phone: '',
  roles: [],
}

export function NewAdminUserModal(props) {
  const {translate} = useTranslation()

  const {modalView, headerLabel, rolesOptions, setRefreshList, onRequestClose} = props
  const [roleValue, setRoleValue] = useState({})
  const [roleErr, setRoleErr] = useState(false)
  const {customBtnClass, customTxtClass} = useVoterStyles()

  const schema = Yup.object().shape({
    firstName: Yup.string().required(translate('First name is required')),
    lastName: Yup.string().required(translate('Last name is required')),
    email: Yup.string()
      .email(translate('Wrong email format'))
      .required(translate('Email is required')),
    username: Yup.string().required(translate('Username is required')),
    phone: Yup.string().required(translate('Phone is required')),
  })

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (isEmpty(roleValue)) {
        setRoleErr(true)
        return
      }

      const userInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        username: values.username,
        phoneNumber: values.phone,
        roles: roleValue ? [roleValue] : [],
      }
      let result = await AdminVoterAuthService.superAdminCreateUser(userInput)
      if (result.success) {
        onClose()
        alert(translate('Success'))

        setRefreshList(true)
      } else {
        // TODO display error
      }
    },
  })

  const onClose = () => {
    // Clear fields
    formik.values.firstName = ''
    formik.values.lastName = ''
    formik.values.email = ''
    formik.values.username = ''
    formik.values.phone = ''
    setRoleValue({})

    // Clear validations
    formik.errors.firstName = ''
    formik.errors.lastName = ''
    formik.errors.email = ''
    formik.errors.username = ''
    formik.errors.phone = ''
    setRoleErr(false)

    props.onRequestClose()
  }

  useEffect(() => {
    if (!isEmpty(roleValue)) {
      setRoleErr(false)
    }
  }, [roleValue])

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={onClose}
      style={customStyles}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        <ModalHeader onRequestClose={onClose} headerLabel={translate('New admin user')} />

        <div className='NewAdminUserModal'>
          <div className='mb-5'>
            <TextField
              formik={formik}
              type='text'
              field='firstName'
              placeholder={translate('First Name')}
              label={translate('First Name')}
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='mb-5'>
            <TextField
              formik={formik}
              type='text'
              field='lastName'
              placeholder={translate('Last Name')}
              label={translate('Last Name')}
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='mb-5'>
            <TextField
              formik={formik}
              type='text'
              field='username'
              placeholder={translate('Username')}
              label={translate('Username')}
              isRequired={true}
              view='voter'
            />
          </div>
          <div className='mb-5'>
            <TextField
              formik={formik}
              type='email'
              field='email'
              placeholder={translate('Enter email')}
              label={translate('Email')}
              isRequired={true}
              view='voter'
            />{' '}
          </div>
          <div className='mb-5'>
            <TextField
              formik={formik}
              type='phone'
              field='phone'
              placeholder={translate('Phone Number')}
              label={translate('Phone Number')}
              isRequired={true}
              view='voter'
            />
          </div>
          <div className='mb-5'>
            <CustomAdminDropdown
              options={rolesOptions}
              title='Role'
              value={roleValue}
              setValue={setRoleValue}
              isRequired={true}
              isForm
            />
          </div>
          {formik.touched['firstName'] && formik.errors['firstName'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['firstName']}
                </span>
              </div>
            </div>
          )}

          {formik.touched['lastName'] && formik.errors['lastName'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['lastName']}
                </span>
              </div>
            </div>
          )}

          {formik.touched['username'] && formik.errors['username'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['username']}
                </span>
              </div>
            </div>
          )}

          {((formik.touched['email'] && formik.errors['email']) ||
            (formik.touched['confirmEmail'] && formik.errors['confirmEmail'])) && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate('Email address is required')}!
                </span>
              </div>
            </div>
          )}
          {formik.touched['phone'] && formik.errors['phone'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['phone']}
                </span>
              </div>
            </div>
          )}
          {roleErr && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate('Role is required')}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='ModalFooter'>
          <button type='submit' className={'modalFooter__cancelBtn'} onClick={onClose}>
            {translate('Cancel')}
          </button>
          <button
            type='submit'
            className={
              customBtnClass
                ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
                : 'modalFooter__primaryBtn'
            }
            onClick={() => formik.handleSubmit()}
          >
            {translate('Create User & Send Password')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
