// @ts-nocheck
import axios from 'axios'
import environment from '../../../utils/environment'
import KeycloakService from '../../../KeycloakAdminService'
import Cookies from 'universal-cookie'
import {languageValueToKey} from '../../languages/languagekey'
import AdminVoterAuthService from '../auth/AdminVoterAuthService'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyTokenAdmin'

const axiosInstance = axios.create({
  baseURL: `${environment.resolveApi().voter_reg_rest}/admin`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

// attach access token
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = KeycloakService.getToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
axiosInstance.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }
    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
      cookies.remove(VERIFY_COOKIE)
    }
    return res
  },
  async (err) => {
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  }
)

const AdminVoterService = {
  getUserInfoKeycloakToken: () => {
    try {
      const parsedToken = KeycloakService.getParsedToken()
      let user = {}
      if (parsedToken) {
        user = {
          username: parsedToken.preferred_username,
          email: parsedToken.email,
          phone: parsedToken.phone_number,
          phoneNumber: parsedToken.phone_number,
        }
      }
      return user
    } catch (error) {}
  },
  // TODO user not logged in keycloak
  // TODO update on login & if logged in whenever change
  //   updateUserLanguage: async (language) => {
  //     try {
  //       language = languageValueToKey[language]
  //       await axiosInstance.put('/account/language', {language: language})
  //     } catch (error) {}
  //   },

  syncEvents: async () => {
    try {
      let events = await axiosInstance.get('/events/sync')
      console.log(events)
      return {events}
    } catch (error) {}
  },

  updateEvent: async (eventId, votingDistrict, votingChannel) => {
    try {
      let event = await axiosInstance.put(`/events/${eventId}`, {
        votingDistrict: votingDistrict,
        votingChannel: votingChannel,
      })
      return {event, success: true}
    } catch (error) {
      console.log(error)
    }
  },

  getVoterCount: async () => {
    try {
      let count = await axiosInstance.get('/count-users-db')
      return count
    } catch (error) {
      console.error(count)
    }
  },

  uploadVoterList: async (voterlist) => {
    try {
      let resp = await axiosInstance.post('/voters', voterlist)
      console.log(resp)
      return {success: true, ...resp}
    } catch (error) {
      if (error?.response?.data) {
        console.error(error.response.data)
        let errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },

  fetchVoters: async (
    districtFilter = 'all',
    channelFilter = 'all',
    limit = 1000,
    skip = 0,
    search = '',
    status = 'All',
    sort = ''
  ) => {
    try {
      let res = await axiosInstance.get(
        `/users?votingDistrict=${districtFilter}&votingChannel=${channelFilter}&limit=${limit}&skip=${skip}&search=${search}&status=${status}&sort=${sort}`
      )
      // console.log(res)
      return {success: true, users: res.users, numUsers: res.numUsers}
    } catch (error) {
      console.log(error)
    }
  },
  getVoterCsv: async (districtFilter = 'all', channelFilter = 'all') => {
    try {
      let result = await axiosInstance.get(
        `/users/csv?votingDistrict=${districtFilter}&votingChannel=${channelFilter}`,
        {
          responseType: 'blob',
        }
      )
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
  getOnlineEventsList: async (district) => {
    try {
      let events = await axiosInstance.get(`/events/online?votingDistrict=${district}`)
      return events
    } catch (error) {
      console.error(error)
      // return {error}
    }
  },
  sendEmailsOnlineVoters: async (tiviId) => {
    try {
      await axiosInstance.post(`/events/${tiviId}/email/voters`)
      return {success: true}
    } catch (error) {
      console.error(error)
      // return {error}
    }
  },
  getVoteReceiptReport: async (tiviId, language) => {
    try {
      language = languageValueToKey[language]
      let data = await axiosInstance.get(`/votereceiptreport/${tiviId}?language=${language}`, {
        responseType: 'blob',
      })
      return {success: true, data}
    } catch (error) {
      console.error(error)
      // return {error}
    }
  },
  getAuthLogReport: async (formData, language) => {
    try {
      language = languageValueToKey[language]
      let url = `/authlogs/${formData.userType}?language=${language}`

      let date = ''
      if (formData.date) {
        date = formData.date.toISOString()
        url = `${url}&date=${date}`
      }
      let data = await axiosInstance.get(url, {
        responseType: 'blob',
      })
      return {success: true, data}
    } catch (error) {
      console.error(error)
      // return {error}
    }
  },
  updateUserLanguage: async (language) => {
    try {
      language = languageValueToKey[language]
      await axiosInstance.put('/account/language', {language: language})
    } catch (error) {}
  },
  // updateType is enum : ['edit, 'update']
  // voterId is user.voterId
  // tiviId is event.tiviId
  updateVoter: async ({voterId, email = '', phone = '', updateType}) => {
    try {
      await axiosInstance.post(`/users/${voterId}/update/${updateType}`, {
        email,
        phone,
      })
      return {success: true}
    } catch (error) {
      return {success: false}
    }
  },
  approveVoter: async (id) => {
    try {
      await axiosInstance.put(`/voters/${id}/approve`)
      return {success: true}
    } catch (error) {
      return {success: false}
    }
  },
  approveTestVoter: async (id) => {
    try {
      await axiosInstance.put(`/voters/${id}/test/approve`)
      return {success: true}
    } catch (error) {
      return {success: false}
    }
  },
  declineVoter: async (id) => {
    try {
      await axiosInstance.put(`/voters/${id}/decline`)
      return {success: true}
    } catch (error) {
      return {success: false}
    }
  },
  getAuthQR: async (username) => {
    try {
      let resp = await axiosInstance.get(`/voters/${username}/login`)
      return {success: true, qrCodeDataUrl: resp.qrCodeDataUrl}
    } catch (error) {
      return {success: false}
    }
  },
  registerVoter: async (data) => {
    try {
      const result = await axiosInstance.post(`/voters/registration/`, data)
      return {success: true, data: result.data}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
}

export default AdminVoterService
