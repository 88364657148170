import Modal from 'react-modal'
import {ModalStyling} from '../../../../../voter/components/registration_follow_up/ModalStyling'
import {ModalHeaderWithLabel} from '../../../../../voter/components/layout/ModalHeaderWithLabel'
import {useEffect, useState} from 'react'
import './VoterDecisionModal.scss'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {CustomBtn} from '../../../../../reusable/custom_btn/CustomBtn'
import AdminVoterService from '../../../../../../services/admin/AdminVoterService'
import TextField from '../../../../../../modules/auth/reusable/TextField'
import SelectField from '../../../../../../modules/auth/reusable/SelectField'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import FormikError from '../../../../../reusable/formik_error/FormikError'
import TextAreaField from '../../../../../../modules/auth/reusable/TextAreaField'

const initialValues = {reason: '', desc: ''}

const reasonSchema = Yup.object().shape({
  reason: Yup.string().required('Reason is required'),
  desc: Yup.string().required('Description is required'),
})

const reasonOptions = [
  {value: 0, label: 'Select'},
  {value: 'ID document is not clear', label: 'ID document is not clear'},
  {value: 'Missing information', label: 'Missing information'},
  {value: 'Name in not matching the photo ID', label: 'Name in not matching the photo ID'},
  {value: 'Duplication Found', label: 'Duplication Found'},
  {value: 'Extra Document required', label: 'Extra Document required'},
  {value: 'Proof of residency required', label: 'Proof of residency required'},
  {value: 'Submission is incomplete', label: 'Submission is incomplete'},
]

export const VoterDecisionModal = ({
  onModalOpen,
  onModalClose,
  modalView = 'approve',
  setModalView,
  voterId,
  onGetUsers,
}) => {
  //   const [modalView, setModalView] = useState('approve')

  const formik = useFormik({
    initialValues,
    validationSchema: reasonSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setTimeout(async () => {
        const userInput = {
          reason: values.reason,
          desc: values.desc,
        }

        console.log(userInput)

        // TODO: send request

        onHandleDecline()

        onModalClose()
      }, 1000)
    },
  })

  const onHandleDecline = async () => {
    await AdminVoterService.declineVoter(voterId)
    onGetUsers()
  }

  const Approve = (
    <div className='voterDecisionModal--approve'>
      <ModalHeaderWithLabel label={'Approve Participant'} />

      <div className='voterDecisionModal--approve__body'>
        <img
          className='voterDecisionModal--approve__body__icon'
          alt='check icon circle'
          src={toAbsoluteUrl('/media/design/icon_check_circle_green.svg')}
        />

        <span className='voterDecisionModal--approve__body__txt'>
          Participant has been approved & Onboarding email has been sent
        </span>
      </div>

      <div className='ModalFooter'>
        <CustomBtn view='ActionBtn' text='OK' height={47} onClick={onModalClose} />
      </div>
    </div>
  )

  const Decline = (
    <div className='voterDecisionModal--decline'>
      <ModalHeaderWithLabel label='Decline Participant' />

      <div className='voterDecisionModal--decline__body'>
        <img
          className='voterDecisionModal--decline__body__icon'
          alt='hand icon grey'
          src={toAbsoluteUrl('/media/design/icon_hand_palm_grey.svg')}
        />

        <span className='voterDecisionModal--decline__body__txt'>
          Would you like to send more details about this decision to the user?"
        </span>
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='PrimaryBtn'
          text='NO'
          height={47}
          onClick={() => {
            onHandleDecline()

            setModalView('decline-success')
          }}
          customStyling={{marginRight: 25}}
        />
        <CustomBtn
          view='ActionBtn'
          text='YES'
          height={47}
          onClick={() => {
            setModalView('decline-reason')
          }}
        />
      </div>
    </div>
  )

  const DeclineSuccess = (
    <div className='voterDecisionModal--declineSuccess'>
      <ModalHeaderWithLabel label='Decline Participant' />

      <div className='voterDecisionModal--declineSuccess__body'>
        <img
          className='voterDecisionModal--declineSuccess__body__icon'
          alt='hand icon grey'
          src={toAbsoluteUrl('/media/design/icon_x_circle_rec.svg')}
        />

        <span className='voterDecisionModal--declineSuccess__body__txt'>
          submission has been successfully declined & an email sent to the user about the decision
        </span>
      </div>

      <div className='ModalFooter'>
        <CustomBtn view='ActionBtn' text='OK' height={47} onClick={onModalClose} />
      </div>
    </div>
  )

  const DeclineReason = (
    <div className='voterDecisionModal--declineReason'>
      <ModalHeaderWithLabel label='Decline Participant' />

      <div className='voterDecisionModal--declineReason__body'>
        <div className='voterDecisionModal--declineReason__body__input--reason'>
          <SelectField
            formik={formik}
            type=''
            field='reason'
            placeholder='Select'
            label='Reason to decline?'
            isRequired
            view='voter'
            options={reasonOptions}
          />
          <FormikError formik={formik} fieldName='reason' errorLabel='Reason is required' />
        </div>

        <div className='voterDecisionModal--declineReason__body__input--desc'>
          <TextAreaField
            customClass='TextField__voter'
            hideErr
            formik={formik}
            type=''
            field='desc'
            placeholder='Type Here'
            label='Description'
            isRequired
            cols={100}
            rows={3}
            customStyling={{borderRadius: 6, height: '100%'}}
          />

          <FormikError formik={formik} fieldName='desc' errorLabel='Description is required' />
        </div>
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='PrimaryBtn'
          text='Do not send email'
          height={47}
          onClick={() => {
            onHandleDecline()
            onModalClose()
          }}
          customStyling={{marginRight: 30}}
        />

        <CustomBtn view='ActionBtn' text='Send' height={47} onClick={formik.submitForm} />
      </div>
    </div>
  )

  const renderView = () => {
    if (modalView == 'approve') {
      return Approve
    }
    if (modalView == 'decline') {
      return Decline
    }
    if (modalView == 'decline-success') {
      return DeclineSuccess
    }

    if (modalView == 'decline-reason') {
      return DeclineReason
    }
  }

  useEffect(() => {}, [onModalOpen])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={onModalOpen}
      onAfterOpen={() => {}}
      onRequestClose={onModalClose}
      style={ModalStyling}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </Modal>
  )
}
