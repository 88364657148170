import {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'
import './AdminTopbar.scss'
import '../Common.scss'

import {VoterAuthContext} from '../../../../contexts/VoterAuthContext'
import {AdminDropdown} from '../admin_dropdown/AdminDropdown'

import {VoterUserContext} from '../../../../contexts/VoterUserContext'
import KeycloakService from '../../../../../Keycloak'
import KeycloakAdminService from '../../../../../KeycloakAdminService'

import {VoterLoginFlowContext} from '../../../../contexts/VoterLoginFlowContext'
import {LanguageContext} from '../../../../contexts/LanguageContext'

import dateFormat from 'dateformat'

import {DateTime} from 'luxon'
import {useSuperAdminUserContext} from '../../../../contexts/SuperAdminUserContext'
import VoterAuthService from '../../../../services/auth/VoterAuthService'
import {useKeycloakSessionTime} from '../../../../hooks/useKeycloakSessionTime'
import AdminVoterAuthService from '../../../../services/auth/AdminVoterAuthService'
import {useKeycloakSession} from '../../../../hooks/useKeycloakSession'
import {ModalWrapper} from '../../../voter/reusable/modals/modal_wrapper/ModalWrapper'
import {SessionTimerProvider, useSessionTimer} from '../../../../contexts/SessionTimerContext'
import {useTranslation} from '../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function AdminTopbar(props) {
  let {type} = props

  const {translate} = useTranslation()
  const history = useHistory()
  const VoterUser = useContext(VoterUserContext)
  const {adminUser} = useSuperAdminUserContext()
  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const adminPage = window.location.pathname?.startsWith('/admin') ? true : false
  const {sessionEnding, setSessionEnding} = useSessionTimer()

  const [currentTime, setCurrentTime] = useState(null)

  useKeycloakSession()
  const sessionTimeRemaining = useKeycloakSessionTime()

  const getCurrentTime = () => {
    let currentIgnaceTime = DateTime.now().setZone('America/Toronto')

    let hours = currentIgnaceTime.hour
    let minutes = currentIgnaceTime.minute
    let full_time = hours + ':' + (minutes < 10 ? '0' + minutes : minutes)

    if (full_time !== currentTime) {
      setCurrentTime(full_time)
    }
  }

  useEffect(() => {
    const interval = setInterval(getCurrentTime, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English' ? 'AdminTopbar--spanish' : 'AdminTopbar'
      }
      id='Voter'
    >
      {adminPage && (
        <ModalWrapper
          isOpen={sessionEnding}
          onRequestClose={() => {
            setSessionEnding(false)
          }}
          style={customStyles}
          modalView={'ExtendSession'}
          headerLabel={translate('Session Time')}
          sessionTimeRemaining={sessionTimeRemaining}
        />
      )}
      <span className='adminTopbar__currentTimeTxt'>
        {translate('Current time')}
        {': '}
        <span className='common__fw700'>{currentTime}</span>
      </span>

      <div className='adminTopbar__btnWrap'>
        {sessionTimeRemaining && !sessionTimeRemaining.includes('NaN') ? (
          <span className='adminTopbar__sessionTime'>
            <span className='adminTopbar__sessionTime__label'>
              {' '}
              {translate('Time left in session')}
              {': '}
            </span>

            {sessionTimeRemaining?.length === 11 ? (
              <div className='adminTopbar__sessionTimeWrap'>
                <span className='adminTopbar__sessionTime__value'>
                  {sessionTimeRemaining.substring(0, 1) !== '0'
                    ? sessionTimeRemaining.substring(0, 2)
                    : sessionTimeRemaining.substring(1, 2)}
                </span>
                <span className='adminTopbar__sessionTime__minTxt'>
                  {sessionTimeRemaining.substring(2, 6)}
                </span>
                <span className='adminTopbar__sessionTime__value'>
                  {sessionTimeRemaining.substring(6, 9)}
                </span>
                <span className='adminTopbar__sessionTime__secTxt'>
                  {sessionTimeRemaining.substring(9, 13)}
                </span>
              </div>
            ) : (
              <div className='adminTopbar__sessionTimeWrap adminTopbar__sessionTime__value'>
                {sessionTimeRemaining}
              </div>
            )}
          </span>
        ) : null}
        <AdminDropdown view='languages' customStyling={{width: 120}} />
        <span className='adminTopbar__welcomeMsg--MEXICO'>
          <span className='fw-400'>{translate('Hello')},</span>{' '}
          {VoterUser?.user?.username || adminUser?.username}
        </span>

        <img
          className='adminTopbar__divider--MEXICO'
          src={toAbsoluteUrl('/media/design/grey_divider.svg')}
        />

        <span
          className='adminTopbar__logoutTxt--MEXICO'
          onClick={async () => {
            try {
              if (KeycloakService.isLoggedIn()) {
                VOTER_LOGIN_CONTEXT.setLoginFlow('Login')
                await VoterAuthService.logOut(history)
              }
              if (KeycloakAdminService.isLoggedIn()) {
                VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Login')
                await AdminVoterAuthService.logOut(history)
              }
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {translate('Logout')}
        </span>
      </div>
    </div>
  )
}
