/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import AdminVoterAuthService from '../../../../../services/auth/AdminVoterAuthService'
import {ModalWrapper} from '../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'
import {useTranslation} from '../../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function MEXICO__SuperAdmin__MyProfile(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const {adminUser} = useSuperAdminUserContext()
  const [requestSent, setRequestSent] = useState(false)

  const [openQrModal, setOpenQrModal] = useState(false)

  // TODO: confirmation pop up
  const requestRegenerateQRCode = async () => {
    let result = await AdminVoterAuthService.requestRegenerateQRCode()
    if (result.success) {
      setRequestSent(true)
    }
  }
  return (
    <div className='MEXICO__MyProfile SuperAdmin__MyProfile' id='Voter'>
      <ModalWrapper
        isOpen={openQrModal}
        onRequestClose={() => {
          setOpenQrModal(false)
        }}
        style={customStyles}
        modalView='ConfirmQrRequestAdmin'
        headerLabel={translate('Confirm Request')}
        onClick={() => {
          requestRegenerateQRCode()
          setOpenQrModal(false)
        }}
      />

      <div className='common__pg__header'>
        <span className='common__pg__header__title'>{translate('My Profile')}</span>
      </div>
      {requestSent && (
        <div className='changePassword__banner--success' style={{marginTop: 15}}>
          <span className='changePassword__banner__txt'>
            {translate('A request has been made to regenerate your QR Code')}
          </span>
        </div>
      )}

      <div className='myProfile__body'>
        <div className='myProfile__body__details'>
          {/* USERNAME SECTION */}
          <div className='myProfile__body__details__usernameSection'>
            <span className='myProfile__body__details__txt__label'>{translate('Username')}:</span>
            <span className='myProfile__body__details__txt__value'>{adminUser?.username}</span>
          </div>
          {/* PASSWORD SECTION */}
          <div className='myProfile__body__details__passwordSection'>
            <span className='myProfile__body__details__txt__label'>{translate('Password')}</span>
            <div className='myProfile__body__details__actionLinkWrap'>
              <span className='myProfile__body__details__txt__value'>******</span>
              <span
                className='myProfile__body__details__actionLink'
                onClick={() => {
                  history.push('/admin/change-password')
                }}
              >
                {translate('Change')}
              </span>{' '}
            </div>
          </div>
          {/* REGISTERED PHONE NUMBER */}
          <div className='myProfile__body__details__passwordSection'>
            <span className='myProfile__body__details__txt__label'>
              {translate('Registered Phone number')}:
            </span>
            <span className='myProfile__body__details__txt__value'>{adminUser?.phoneNumber}</span>
          </div>
          {/* AUTHENTICATION METHOD */}
          {/* <div className='myProfile__body__details__authMethodSection'>
            <div className='myProfile__body__details__txtIconWrap'>
              <span className='myProfile__body__details__txt__label'>Authentication method</span>
              <img
                className='myProfile__body__details__infoIcon'
                src={toAbsoluteUrl('/media/design/ine_pink_info_icon.svg')}
                alt='info icon'
              />
            </div>
            <div className='myProfile__body__details__actionLinkWrap'>
              <span className='myProfile__body__details__txt__value'>
                SMS code sent to my phone{' '}
              </span>
              <span className='myProfile__body__details__actionLink'>Change</span>{' '}
            </div>
          </div> */}
          {/* QR CODE */}
          <div className='myProfile__body__details__qrCodeSection'>
            <div className='myProfile__body__details__qrCode__txtWrap'>
              <span
                className='myProfile__body__details__txt__value myProfile__body__details__qrCode__label myProfile__body__details__actionLink'
                onClick={() => {
                  // open popup
                  setOpenQrModal(true)
                  // requestRegenerateQRCode()
                }}
              >
                {translate('Request a new QR code')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
