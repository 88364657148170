/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
import './EventInfo.scss'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {EventEditModal} from './EventEditModal'
import {LanguageContext} from '../../../../../../contexts/LanguageContext'
import KeycloakAdminService from '../../../../../../../KeycloakAdminService'
import AdminVoterService from '../../../../../../services/admin/AdminVoterService'
// import {AdminTopbar} from '../../../reusable/admin_topbar/AdminTopbar'

// import VoterAdminService from '../../../../../services/voter/VoterAdminService'
import {districts} from '../../../../../../portalData/Districts'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {formatDateLocale} from '../../../../../../languages/datetimeformats'

export function EventInfo(props) {
  const {translate, translateObj} = useTranslation()

  const {event, updateEvent} = props
  const [loading, setLoading] = useState(false)
  const [detailedView, setDetailedView] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  // note: used within multiple functions and calls to understand which langugage is currently selected
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const [downloadURL, setDownloadURL] = useState(null)
  const [downloading, setDownloading] = useState(false)

  const viewOnly =
    !KeycloakAdminService.hasRole('tivi-administrator') &&
    !KeycloakAdminService.hasRole('tivi-administration-module-administrator')

  const [headerLabel, setHeaderLabel] = useState('Voting opening date')
  if (!event) {
    return null
  }

  const getVoteReceiptReport = async () => {
    setDownloading(true)
    const res = await AdminVoterService.getVoteReceiptReport(
      event.tiviId,
      LANGUAGE_CONTEXT.currentLanguage
    )
    if (res?.success) {
      // @ts-ignore
      const href = URL.createObjectURL(res.data)
      setDownloadURL(href)
      const link = document.createElement('a')
      link.download = `Receipt-Report-${event.tiviId}`
      link.href = href
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setDownloading(false)
  }

  return (
    <div className='EventInfo'>
      <EventEditModal
        headerLabel={headerLabel}
        modalOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false)
        }}
        event={event}
        updateEvent={updateEvent}
      />

      {/* TIVI Event Id */}
      <div className='eventInfo__desc'>
        <span className='eventInfo__desc__label'>{translate('Identifier')}:</span>
        <span className='eventInfo__desc__value'>{event.tiviId}</span>
      </div>

      {/* DESCRIPTION */}
      <div className='eventInfo__desc'>
        <span className='eventInfo__desc__label'>{translate('Description')}:</span>
        <span className='eventInfo__desc__value'>
          {event.description && translateObj(event.description)}
        </span>
      </div>

      {/* TIME */}
      <div className='eventInfo__time'>
        <img
          className='eventInfo__time__icon'
          src={toAbsoluteUrl('/media/design/icon_clock_pink.svg')}
        />

        <div className='eventInfo__timeWrap'>
          <div className='eventInfo__time__opening'>
            <div className='eventInfo__time__txtWrap'>
              <span className='eventInfo__time__label'>{translate('Opening Time')}:</span>
              <span className='eventInfo__time__value'>
                {' '}
                {formatDateLocale(event.openDate, LANGUAGE_CONTEXT)}
              </span>{' '}
            </div>
          </div>

          <div className='eventInfo__time__closing'>
            <div className='eventInfo__time__txtWrap'>
              <span className='eventInfo__time__label'>{translate('Closing Time')}:</span>
              <span className='eventInfo__time__value'>
                {' '}
                {formatDateLocale(event.closeDate, LANGUAGE_CONTEXT)}
              </span>{' '}
            </div>
          </div>
        </div>
      </div>

      {/* DISTRICT */}
      {true ? (
        <div />
      ) : (
        <div className='eventInfo__time eventInfo__district'>
          <img
            className='eventInfo__time__icon'
            src={toAbsoluteUrl('/media/design/icon_building_pink.svg')}
          />

          <div className='eventInfo__timeWrap'>
            <div className='eventInfo__time__opening'>
              <div className='eventInfo__time__txtWrap'>
                <span className='eventInfo__time__label'>{translate('District')}:</span>
                <span className='eventInfo__time__value'>
                  {/* {' '}
                {translate(
                  districts.find((o) => o.value === event.votingDistrict)?.label ||
                    event.votingDistrict
                )} */}
                  jurisdiction1, jurisdiction2
                </span>{' '}
              </div>
              {/* only use when districts between civik & tivi deployments are out of sync */}
              {/* {!viewOnly && (
              <div
                className='eventInfo__time__editWrap eventInfo__district__editWrap'
                onClick={() => {
                  setHeaderLabel('District')
                  setModalOpen(true)
                }}
              >
                <span className='eventInfo__time__edit__label'>{translate('Edit')}</span>
                <img className='' src={toAbsoluteUrl('/media/design/icon_edit_grey.svg')} />
              </div>
            )} */}
            </div>
          </div>
        </div>
      )}

      {/* CHANNEL */}
      <div className='eventInfo__time eventInfo__district eventInfo__downloadBtnWrap'>
        <div className='common__flex__row'>
          <img
            className='eventInfo__time__icon'
            src={toAbsoluteUrl('/media/design/icon_channel_pink.svg')}
          />

          <div className='eventInfo__timeWrap'>
            <div className='eventInfo__time__opening'>
              <div className='eventInfo__time__txtWrap'>
                <span className='eventInfo__time__label'>{translate('Channel')}:</span>
                <span className='eventInfo__time__value'>{translate(event.votingChannel)}</span>
              </div>
            </div>
          </div>
        </div>
        {!event.multipleVotesEnabled && (
          <div>
            {downloading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              <button className='btn__downLoadList' onClick={getVoteReceiptReport}>
                {' '}
                {translate('Download Vote Receipt Report')}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
