import {useContext, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import './AdminSidebar.scss'
import {useHistory} from 'react-router-dom'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import {AdminSVG} from '../admin_svg/AdminSVG'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import {useTranslation} from '../../../../hooks/useTranslation'

export function AdminSidebar(props) {
  const {translate} = useTranslation()

  let {type} = props

  const history = useHistory()
  const {customMobileLogoImg, voterStyleView, customTxtClass} = useVoterStyles()
  const [currentTab, setCurrentTab] = useState('Dashboard')

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  // const activeTab = {
  //   nova_scotia: 'adminSideBar__tabActiveWrap',
  //   mexico: 'adminSideBar__tabActiveWrap--Mexico',
  // }

  // const activeTabTxt = {
  //   nova_scotia: 'adminSideBar__tabActiveTxt',
  //   mexico: 'adminSideBar__tabActiveTxt--Mexico',
  // }

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English' ? 'AdminSidebar--spanish' : 'AdminSidebar'
      }
    >
      <img
        className='adminSidebar__logo'
        src={toAbsoluteUrl(customMobileLogoImg)}
        alt='Elections Logo'
      />

      <hr className='AdminSidebar__divider'></hr>

      <div className='adminSideBar__tabContainer'>
        {currentTab == 'Dashboard' ? (
          <div className={'adminSideBar__tabActiveWrap--Mexico'}>
            <AdminSVG icon='Dashboard' />
            <span className={'adminSideBar__tabActiveTxt--Mexico'}>{translate('Dashboard')}</span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/voting/voter/dashboard')
              setCurrentTab('Dashboard')
            }}
          >
            <AdminSVG icon='Dashboard' inactive={true} />
            <span className='adminSideBar__tabInactiveTxt'>{translate('Dashboard')}</span>
          </div>
        )}

        <>
          {/* {currentTab == 'Address List' ? (
            <div className='adminSideBar__tabActiveWrap'>
              <img
                src={toAbsoluteUrl('/media/design/admin_map_active_icon.svg')}
                alt='dashboard icon'
              />
              <span className='adminSideBar__tabActiveTxt'>{translate('Address List')}</span>
            </div>
          ) : (
            <div
              className='adminSideBar__tabInactiveWrap'
              onClick={() => {
                history.push('/voting/voter/address-list')
                setCurrentTab('Address List')
              }}
            >
              <img src={toAbsoluteUrl('/media/design/admin_map_icon.svg')} alt='dashboard icon' />
              <span className='adminSideBar__tabInactiveTxt'>{translate('Address List')}</span>
            </div>
          )} */}

          {/* {currentTab == 'Electors List' ? (
            <div className='adminSideBar__tabActiveWrap'>
              <img
                src={toAbsoluteUrl('/media/design/admin_electors_active_icon.svg')}
                alt='dashboard icon'
              />
              <span className='adminSideBar__tabActiveTxt'>{translate('Electors List')}</span>
            </div>
          ) : (
            <div
              className='adminSideBar__tabInactiveWrap'
              onClick={() => {
                history.push('/votings/voter/elector-list')
                setCurrentTab('Electors List')
              }}
            >
              <img
                src={toAbsoluteUrl('/media/design/admin_electors_icon.svg')}
                alt='dashboard icon'
              />
              <span className='adminSideBar__tabInactiveTxt'>{translate('Electors List')}</span>
            </div>
          )} */}

          {/* {currentTab == 'Duplication List' ? (
            <div className='adminSideBar__tabActiveWrap'>
              <img
                src={toAbsoluteUrl('/media/design/admin_duplicate_active_icon.svg')}
                alt='dashboard icon'
              />
              <span className='adminSideBar__tabActiveTxt'>{translate('Duplication List')}</span>
            </div>
          ) : (
            <div
              className='adminSideBar__tabInactiveWrap'
              onClick={() => {
                history.push('/voting/voter/duplicate-list')
                setCurrentTab('Duplication List')
              }}
            >
              <img
                src={toAbsoluteUrl('/media/design/admin_duplicate_icon.svg')}
                alt='dashboard icon'
              />
              <span className='adminSideBar__tabInactiveTxt'>{translate('Duplication List')}</span>
            </div>
          )} */}
        </>

        {/* {voterStyleView !== 'neuvote' ? (
          <>
            {currentTab == 'Address List' ? (
              <div className='adminSideBar__tabActiveWrap'>
                <img
                  src={toAbsoluteUrl('/media/design/admin_map_active_icon.svg')}
                  alt='dashboard icon'
                />
                <span className='adminSideBar__tabActiveTxt'>{translate('Address List')}</span>
              </div>
            ) : (
              <div
                className='adminSideBar__tabInactiveWrap'
                onClick={() => {
                  history.push('/voting/voter/address-list')
                  setCurrentTab('Address List')
                }}
              >
                <img src={toAbsoluteUrl('/media/design/admin_map_icon.svg')} alt='dashboard icon' />
                <span className='adminSideBar__tabInactiveTxt'>{translate('Address List')}</span>
              </div>
            )}

            {currentTab == 'Electors List' ? (
              <div className='adminSideBar__tabActiveWrap'>
                <img
                  src={toAbsoluteUrl('/media/design/admin_electors_active_icon.svg')}
                  alt='dashboard icon'
                />
                <span className='adminSideBar__tabActiveTxt'>{translate('Electors List')}</span>
              </div>
            ) : (
              <div
                className='adminSideBar__tabInactiveWrap'
                onClick={() => {
                  history.push('/votings/voter/elector-list')
                  setCurrentTab('Electors List')
                }}
              >
                <img
                  src={toAbsoluteUrl('/media/design/admin_electors_icon.svg')}
                  alt='dashboard icon'
                />
                <span className='adminSideBar__tabInactiveTxt'>{translate('Electors List')}</span>
              </div>
            )}

            {currentTab == 'Duplication List' ? (
              <div className='adminSideBar__tabActiveWrap'>
                <img
                  src={toAbsoluteUrl('/media/design/admin_duplicate_active_icon.svg')}
                  alt='dashboard icon'
                />
                <span className='adminSideBar__tabActiveTxt'>{translate('Duplication List')}</span>
              </div>
            ) : (
              <div
                className='adminSideBar__tabInactiveWrap'
                onClick={() => {
                  history.push('/voting/voter/duplicate-list')
                  setCurrentTab('Duplication List')
                }}
              >
                <img
                  src={toAbsoluteUrl('/media/design/admin_duplicate_icon.svg')}
                  alt='dashboard icon'
                />
                <span className='adminSideBar__tabInactiveTxt'>
                  {translate('Duplication List')}
                </span>
              </div>
            )}
          </>
        ) : (
          <>
            {currentTab == 'Help & Support' ? (
              <div className={activeTab[voterStyleView]}>
                <AdminSVG icon='Help & Support' />
                <span className={activeTabTxt[voterStyleView]}>{translate('Help & Support')}</span>
              </div>
            ) : (
              <div
                className='adminSideBar__tabInactiveWrap'
                onClick={() => {
                  history.push('/voting/voter/help-support')
                  setCurrentTab('Help & Support')
                }}
              >
                <AdminSVG icon='Help & Support' inactive={true} />
                <span className='adminSideBar__tabInactiveTxt'>{translate('Help & Support')}</span>
              </div>
            )}

           
          </>
        )} */}

        {currentTab == 'My Profile' ? (
          <div className={'adminSideBar__tabActiveWrap--Mexico'}>
            <AdminSVG icon='My Profile' />
            <span className={'adminSideBar__tabActiveTxt--Mexico'}>{translate('My Profile')}</span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/voting/voter/my-profile')
              setCurrentTab('My Profile')
            }}
          >
            <img
              width='27'
              height='27'
              src={toAbsoluteUrl('/media/design/admin_myprofile_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabInactiveTxt'>{translate('My Profile')}</span>
          </div>
        )}

        <>
          {currentTab == 'Help & Support' ? (
            <div className={'adminSideBar__tabActiveWrap--Mexico'}>
              <AdminSVG icon='Help & Support' />
              <span className={'adminSideBar__tabActiveTxt--Mexico'}>
                {translate('Help & Support')}
              </span>
            </div>
          ) : (
            <div
              className='adminSideBar__tabInactiveWrap'
              onClick={() => {
                history.push('/voting/voter/help-support')
                setCurrentTab('Help & Support')
              }}
            >
              <AdminSVG icon='Help & Support' inactive={true} />
              <span className='adminSideBar__tabInactiveTxt'>{translate('Help & Support')}</span>
            </div>
          )}
        </>
      </div>
    </div>
  )
}
