/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import './MEXICO__SuperAdmin__ElectorsList.scss'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ElectorsListTable} from '../../../../voter_admin/pages/nova_scotia/electors_list/ElectorsListTable'
import {ImportAddressList} from '../../../../voter_admin/pages/nova_scotia/address_list/ImportAddressList'
import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import KeycloakAdminService from '../../../../../../KeycloakAdminService'
import {beforeUnload} from '../../../../../App'
import {useTranslation} from '../../../../../hooks/useTranslation'

export const initialValues = {}

export const schema = Yup.object().shape({})

export function MEXICO__SuperAdmin__ElectorsList(props) {
  const {translate} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('Electors List')
  const {tiviLink} = useSuperAdminUserContext()

  const viewOnly =
    !KeycloakAdminService.hasRole('tivi-administrator') &&
    !KeycloakAdminService.hasRole('tivi-administration-module-administrator')

  const contactInfoRole = KeycloakAdminService.hasRole('tivi-contactinfo')

  const renderView = () => {
    if (currentTab == 'Import List') {
      return <ImportAddressList />
    }
    if (currentTab == 'Electors List') {
      return <ElectorsListTable />
    }
  }

  useEffect(() => {
    renderView()
  }, [currentTab])

  return (
    <>
      <div className='SuperAdmin__ElectorsList' id='Voter'>
        {/* CONTENT */}

        {/* TABS */}
        <div className='voterAddrList__tabWrap' style={{display: 'flex'}}>
          <div className='adminUsers__header'>
            <span
              onClick={() => {
                setCurrentTab('Electors List')
              }}
              className={
                currentTab == 'Electors List'
                  ? 'voterAddrList__activeTab'
                  : 'voterAddrList__inactiveTab'
              }
            >
              {translate('Electors List')}
            </span>

            {!viewOnly && (
              <>
                <span
                  onClick={() => {
                    setCurrentTab('Import List')
                  }}
                  className={
                    currentTab == 'Import List'
                      ? 'voterAddrList__activeTab'
                      : 'voterAddrList__inactiveTab'
                  }
                >
                  {translate('Import List')}
                </span>
              </>
            )}
          </div>

          {!contactInfoRole && (
            <div className='adminUsers__eventComponent__btnOuter'>
              <div
                className='adminUsers__eventComponent__btn'
                onClick={() => {
                  window.removeEventListener('beforeunload', beforeUnload)
                  window.location.href = tiviLink
                }}
              >
                <img
                  style={{paddingRight: 2}}
                  src={toAbsoluteUrl('/media/design/icon_black_gear.svg')}
                  alt='settings'
                />
                <span className='adminUsers__eventComponent__btn__txt'>
                  {translate('Event Configuration Component')}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </>
  )
}
