import './LabelAndValue.scss'

export const LabelAndValue = ({label, value}) => {
  return (
    <div className='LabelAndValue'>
      <span className='labelAndValue__label'>{label}</span>
      <span className='labelAndValue__value'>{value}</span>
    </div>
  )
}
