import {Redirect, Route, Switch} from 'react-router-dom'

import {MEXICO__AdminChangePassword} from '../pages/voter_admin/pages/mexico/user_dashboard/change_password/MEXICO__AdminChangePassword'
import {useVoterStyles} from '../contexts/VoterStylesContext'
import {MEXICO__SuperAdmin__Events} from '../pages/super_admin/mexico/pages/events/MEXICO__SuperAdmin__Events'
import {MEXICO__SuperAdmin__ElectorsList} from '../pages/super_admin/mexico/pages/electors_list/MEXICO__SuperAdmin__ElectorsList'
import {MEXICO__SuperAdmin__Users} from '../pages/super_admin/mexico/pages/admin_users/MEXICO__SuperAdmin__Users'
import {MEXICO__SuperAdmin__MyProfile} from '../pages/super_admin/mexico/pages/my_profile/MEXICO__SuperAdmin__MyProfile'
import {MEXICO__SuperAdmin__HelpAndSupport} from '../pages/super_admin/mexico/pages/help_and_support/MEXICO__SuperAdmin__HelpAndSupport'
import {MEXICO__SuperAdmin__Reports} from '../pages/super_admin/mexico/pages/reports/MEXICO__SuperAdmin__Reports'
import {beforeUnload} from '../App'
import {useEffect, useState} from 'react'
import useWindowHeight from '../hooks/useWindowHeight'
import {SuperAdmin__ParticipantDetails} from '../pages/super_admin/mexico/pages/participant_details/SuperAdmin__ParticipantDetails'
import SuperAdmin__ViewPDF from '../pages/super_admin/mexico/pages/view_pdf/SuperAdmin__ViewPDF'
import {VoterRegister} from '../pages/voter/pages/VoterRegister'

export function SuperAdmin__DashboardRoutes() {
  const {voterStyleView} = useVoterStyles()
  const windowHeight = useWindowHeight()

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
  }, [])

  const MEXICO_ROUTES = (
    <Switch>
      <Route path='/admin/dashboard' component={MEXICO__SuperAdmin__Events} />
      <Route path='/admin/events' component={MEXICO__SuperAdmin__Events} />
      <Route path='/admin/electors-list' component={MEXICO__SuperAdmin__ElectorsList} />
      <Route path='/admin/admin-users' component={MEXICO__SuperAdmin__Users} />
      <Route path='/admin/my-profile' component={MEXICO__SuperAdmin__MyProfile} />
      <Route path='/admin/reports' component={MEXICO__SuperAdmin__Reports} />
      <Route path='/admin/change-password' component={MEXICO__AdminChangePassword} />
      <Route path='/admin/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
      <Route render={() => <Redirect to='/admin/dashboard' />} />
    </Switch>
  )

  const NEUVOTE_ROUTES = (
    <>
      <Switch>
        <Route path='/admin/dashboard' component={MEXICO__SuperAdmin__Events} />
        <Route path='/admin/events' component={MEXICO__SuperAdmin__Events} />
        <Route path='/admin/electors-list' component={MEXICO__SuperAdmin__ElectorsList} />
        <Route path='/admin/admin-users' component={MEXICO__SuperAdmin__Users} />
        <Route path='/admin/my-profile' component={MEXICO__SuperAdmin__MyProfile} />
        <Route path='/admin/reports' component={MEXICO__SuperAdmin__Reports} />
        <Route path='/admin/change-password' component={MEXICO__AdminChangePassword} />
        <Route path='/admin/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route render={() => <Redirect to='/admin/dashboard' />} />
      </Switch>
    </>
  )

  const NWT_ROUTES = (
    <>
      <Switch>
        <Route path='/admin/dashboard' component={MEXICO__SuperAdmin__Events} />
        <Route path='/admin/events' component={MEXICO__SuperAdmin__Events} />
        <Route path='/admin/electors-list' component={MEXICO__SuperAdmin__ElectorsList} />
        <Route path='/admin/admin-users' component={MEXICO__SuperAdmin__Users} />
        <Route path='/admin/my-profile' component={MEXICO__SuperAdmin__MyProfile} />
        <Route path='/admin/reports' component={MEXICO__SuperAdmin__Reports} />
        <Route path='/admin/change-password' component={MEXICO__AdminChangePassword} />

        <Route path='/admin/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route render={() => <Redirect to='/admin/dashboard' />} />
      </Switch>
    </>
  )

  const IGNACE_ROUTES = (
    <>
      <Switch>
        <div style={{backgroundColor: '#F2F3F7', minHeight: windowHeight, height: 'fit-content'}}>
          <Route path='/admin/dashboard' component={MEXICO__SuperAdmin__Events} />
          <Route path='/admin/events' component={MEXICO__SuperAdmin__Events} />
          <Route path='/admin/electors-list' component={MEXICO__SuperAdmin__ElectorsList} />
          <Route path='/admin/view-pdf/:pdfURL' component={SuperAdmin__ViewPDF} />
          <Route path='/admin/participants/:voterId' component={SuperAdmin__ParticipantDetails} />

          <Route path='/admin/admin-users' component={MEXICO__SuperAdmin__Users} />
          <Route path='/admin/my-profile' component={MEXICO__SuperAdmin__MyProfile} />
          <Route path='/admin/reports' component={MEXICO__SuperAdmin__Reports} />
          <Route path='/admin/change-password' component={MEXICO__AdminChangePassword} />
          <Route path='/admin/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
          {/* <Route render={() => <Redirect to='/admin/dashboard' />} /> */}

          <Route path='/admin/register' component={VoterRegister} />
        </div>
      </Switch>
    </>
  )

  const renderRoutes = () => {
    if (voterStyleView == 'mexico') {
      return MEXICO_ROUTES
    } else if (voterStyleView == 'nwt') {
      return NWT_ROUTES
    } else if (voterStyleView == 'ignace') {
      return IGNACE_ROUTES
    } else {
      return IGNACE_ROUTES
    }
  }
  return <>{renderRoutes()}</>
}
