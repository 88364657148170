import React, {Suspense, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
// import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {UserContext} from './contexts/usercontext'
import {ProfileContext} from './contexts/profilecontext'
import {ProfilePageContext} from './contexts/profilePageContext'
import {FeedPostContext} from './contexts/feedPostContext'
import {FeedLocationContext} from './contexts/feedLocationContext'
import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'

import app_config from '../config.json'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import Cookies from 'universal-cookie'
import {v4 as uuidv4} from 'uuid'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyToken'
const VERIFY_COOKIE_ADMIN = 'verifyTokenAdmin'
export const ONBOARDING_COOKIE = 'onboardingAccessToken'

/// Initialize Firebase
var config = {
  apiKey: app_config.FIREBASE_API_KEY,
  authDomain: app_config.FIREBASE_AUTH_DOMAIN,
  databaseURL: app_config.FIREBASE_DATABASE_URL,
  projectId: app_config.FIREBASE_PROJECT_ID,
  storageBucket: app_config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: app_config.FIREBASE_MESSAGING_SENDER_ID,
  appId: app_config.FIREBASE_APP_ID,
  measurementId: app_config.FIREBASE_MEASUREMENT_ID,
}

// firebase.initializeApp(config)
// Initialize Analytics and get a reference to the service
// firebase.analytics()

type Props = {
  basename: string
}

export const beforeUnload = (event) => {
  event.returnValue = 'Are you sure you want to leave?'
}
const App: React.FC<Props> = ({basename}) => {
  const [user, setUser] = React.useState({loggedIn: false, verified: false, secondarySignup: false})
  const [profile, setProfile] = React.useState({})
  const [currentTab, setCurrentTab] = React.useState('About')
  const [feedPosts, setFeedPosts] = React.useState([])
  const [feedLocation, setFeedLocation] = React.useState({country: 'Canada', region: '', city: ''})

  useEffect(() => {
    cookies.remove(VERIFY_COOKIE)
    cookies.remove(VERIFY_COOKIE_ADMIN)
    cookies.remove(ONBOARDING_COOKIE)
  }, [])

  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <GoogleReCaptchaProvider reCaptchaKey={app_config.RECAPTCHA_SITE_KEY}>
              {/* <AuthInit> */}
              <UserContext.Provider value={{user, setUser}}>
                <ProfileContext.Provider value={{profile, setProfile}}>
                  <ProfilePageContext.Provider value={{currentTab, setCurrentTab}}>
                    <FeedLocationContext.Provider value={{feedLocation, setFeedLocation}}>
                      <FeedPostContext.Provider value={{feedPosts, setFeedPosts}}>
                        <Routes />
                      </FeedPostContext.Provider>
                    </FeedLocationContext.Provider>
                  </ProfilePageContext.Provider>
                </ProfileContext.Provider>
              </UserContext.Provider>
              {/* </AuthInit> */}
            </GoogleReCaptchaProvider>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
