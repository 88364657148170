/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import useQuery from '../../../../../routing/UseQuery'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'

import {useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import './MEXICO__PhoneVerify.scss'
import '../../../../voter/pages/ConfirmCode.scss'
import '../../../../voter/pages/VerifyMethod.scss'

import '@djthoms/pretty-checkbox'
import VerificationInput from 'react-verification-input'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'

import {ProgressBar} from './reusable/ProgressBar'
import './Common.scss'
import {LanguageContext} from '../../../../../contexts/LanguageContext'

import {VoterLoginFlowContext} from '../../../../../contexts/VoterLoginFlowContext'
import {ProgressBarMobile} from './reusable/ProgressBarMobile'

import OnlineVoterAuthService from '../../../../../services/auth/OnlineVoterAuthService'
import {VoterUserContext} from '../../../../../contexts/VoterUserContext'
import {useTranslation} from '../../../../../hooks/useTranslation'
import RecapatchaDisclaimer from '../../../../../resuable/recapatcha/RecapatchaDisclaimer'

export function MEXICO__PhoneVerify(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const query = useQuery()
  const accessCode = query.get('accessCode')
  const VoterUser = useContext(VoterUserContext)

  const {executeRecaptcha} = useGoogleReCaptcha()

  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(null)
  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()
  const [notValid, setNotValid] = useState(true)

  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  useEffect(() => {
    // disabled button handler
    setCodeError('')
    if (code.length == 4) {
      setNotValid(false)
    }
  }, [code])

  useEffect(() => {
    const validateAccessCode = async () => {
      let res = await OnlineVoterAuthService.validateAccessLink(accessCode)
      if (res.error) {
        // TODO: change redirect or show error
        setCodeError('Invalid access link')
        history.replace('/voting/voter-login')
        setCodeError('')
      }
    }
    validateAccessCode()
  }, [accessCode])

  const onSubmit = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }
    if (!accessCode) {
      setCodeError('Invalid code')
    }

    const token = await executeRecaptcha('voter_onboarding')
    let res = await OnlineVoterAuthService.validateSecurityQuestion(accessCode, code, token)
    if (res.success) {
      VoterUser.setUser(res.user)
      VOTER_LOGIN_CONTEXT.setLoginFlow('Onboarding')
      history.push('/voting/voter-onboarding/auth-method')
    } else {
      setCodeError('Invalid code')
    }
  }

  return (
    <div className='VerifyMethod' id='Voter'>
      {/* NAVBAR */}

      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div
          className={
            LANGUAGE_CONTEXT.currentLanguage !== 'English'
              ? 'authMethodLogin__form authMethod__form--spanish'
              : 'phoneVerify__form'
          }
        >
          <ProgressBar currentStep={1} />
          <ProgressBarMobile currentStep={1} />

          {/*  */}

          <div className='phoneVerify__form__body'>
            <div className='verifyMethod__formHeader'>
              <span className='verifyMethod__formHeaderTitle'>
                {translate('Profile verification')}
              </span>

              <div className='verifyMethod__formHeaderTxtWrap'>
                <span className='confirmCode__formHeaderTxt'>
                  {translate('Enter the last 4 digits of the')}{' '}
                  <span className={'phoneVerify__primaryPhoneTxt'} style={{fontWeight: 600}}>
                    {translate('phone number')}
                  </span>
                </span>
                <span className='confirmCode__formHeaderTxt'>
                  {translate('used when you registered')}
                </span>{' '}
              </div>
            </div>

            <div className='phoneVerify__inputsWrap'>
              <VerificationInput
                autoFocus={true}
                inputProps={{autoComplete: 'one-time-code'}}
                value={code}
                onChange={setCode}
                validChars='0-9'
                length={4}
                classNames={{
                  container: 'TextField__confirmCode__container',
                  character: 'TextField__confirmCode--phoneVerify',
                  characterInactive: 'TextField__confirmCode__inactive',
                  characterSelected: 'TextField__confirmCode__selected__MEXICO',
                }}
              />

              {codeError && (
                <div className='voterRegister__singleInputErr confirmCode__errorWrap'>
                  <span className='TextField__voterErrTxt' role='alert'>
                    {translate('You need to enter the last four digits of your registered phone')}
                  </span>
                </div>
              )}
            </div>

            {/* <div className='confirmCode__formFooter'>
              <span className='phoneVerify__formFooter__disclaimerTxt'>
                {translate("Don't have access to this phone  number anymore?")}{' '}
                <span className='phoneVerify__formFooter__disclaimerLink'>
                  {translate('Please click here')}
                </span>
              </span>{' '}
            </div> */}
          </div>
          {/*  */}
          <div className='phoneVerify__form__actionBar'>
            <button
              className={
                notValid ? 'authMethodLogin__nextBtn disabled' : 'authMethodLogin__nextBtn'
              }
              onClick={onSubmit}
              disabled={notValid}
            >
              {translate('Next')}
            </button>
          </div>
        </div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt400' view='mobile' />
    </div>
  )
}
