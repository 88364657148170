// FormikError.js

import React from 'react'

const FormikError = ({formik, fieldName, errorLabel, customStyling = {}}) => {
  return (
    <>
      {formik.errors[fieldName] && (
        <div className='voterRegister__singleInputErr' style={customStyling}>
          <div className='TextField__voterErrWrap'>
            <span className='TextField__voterErrTxt' role='alert'>
              {errorLabel}
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default FormikError
