import {useEffect, useState} from 'react'
import './PhotoIdModal.scss'
import {current} from '@reduxjs/toolkit'
import {AddImageModal} from '../../../../../../modals/AddImageModal'
import {ModalHeaderWithLabel} from '../../../../../../voter/components/layout/ModalHeaderWithLabel'
import {ModalStyling} from '../../../../../../voter/components/registration_follow_up/ModalStyling'
import Modal from 'react-modal'
import {CustomBtn} from '../../../../../../reusable/custom_btn/CustomBtn'

export const PhotoIdModal = ({modalOpen, setModalOpen, modalView, setModalView, photos, i}) => {
  //   const UploadNew = (
  //     <AddImageModal
  //       isOpen={modalIsOpen}
  //       onAfterOpen={afterOpenModal}
  //       onRequestClose={closeModal}
  //       style={customStyles}
  //       contentLabel='Example Modal'
  //       setPostURL={(imgURL, path) => {
  //         console.log('imgURL', imgURL)
  //         setPhotoURL(imgURL)
  //         // setPostURL({type: 'image', url: imgURL, path: path})
  //       }}
  //       setFileData={(image) => {
  //         formik.values[formikValueName] = image
  //         if (formik.errors[formikValueName]) {
  //           formik.errors[formikValueName] = false
  //         }
  //       }}
  //       modalView='photoID'
  //     />
  //   )

  const ViewPhotoId = (
    <>
      <div className='photoIdModal--view'>
        <img className='photoIdModal--view__photoImg' alt='identification' src={photos[i]} />
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='PrimaryBtn'
          text={'Close'}
          height={48}
          onClick={() => {
            setModalView('view-photo-id')
            setModalOpen(false)
          }}
        />
      </div>
    </>
  )

  const renderView = () => {
    if (modalView == 'view-photo-id') {
      return ViewPhotoId
    }
  }

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => {
        setModalOpen(false)
      }}
      style={ModalStyling}
    >
      <div className='ModalWrapper' style={{flexGrow: 1, maxWidth: 925, width: '100%'}}>
        <ModalHeaderWithLabel label={'Photo ID'} />

        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </Modal>
  )
}
