/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import './MEXICO__SuperAdmin__Users.scss'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {UsersList} from './users_list/UsersList'
import {NewAdminUserModal} from './users_list/new_admin_user_modal/NewAdminUserModal'
import {UserDetails} from './users_list/user_details/UserDetails'
import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import AdminVoterAuthService from '../../../../../services/auth/AdminVoterAuthService'
import {useTranslation} from '../../../../../hooks/useTranslation'

export const initialValues = {}

export const schema = Yup.object().shape({})

export function MEXICO__SuperAdmin__Users(props) {
  const {translate, translateObj} = useTranslation()

  const [openNewUserModal, setOpenNewUserModal] = useState(false)
  const {adminUser__currentTab, setAdminUser__currentTab} = useSuperAdminUserContext()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const [adminRoles, setAdminRoles] = useState([])
  const [refreshList, setRefreshList] = useState(false)

  const renderView = () => {
    if (adminUser__currentTab == 'Users') {
      return <UsersList refreshList={refreshList} setRefreshList={setRefreshList} />
    }
    // I don't think we need this
    // if (adminUser__currentTab == 'Restore Requests') {
    //   return <RestoreRequestsTable />
    // }
  }

  useEffect(() => {
    const fetchRoles = async () => {
      let resp = await AdminVoterAuthService.superAdminFetchRealmRoles()
      if (resp.success) {
        let roles = resp.data ? resp.data : []
        //@ts-ignore
        roles = roles.map((r) => {
          let role = r
          role.value = {name: r.name, id: r.id}
          role.label = translateObj(r.displayName)
          return role
        })
        //@ts-ignore
        roles = roles.filter((r) => (r.label ? true : false))
        //@ts-ignore
        setAdminRoles(roles)
      }
    }
    fetchRoles()
    // note: need to rerender, whenever current language changes
  }, [LANGUAGE_CONTEXT.currentLanguage])

  useEffect(() => {
    renderView()
  }, [adminUser__currentTab])

  return (
    <>
      <div className='SuperAdmin__ElectorsList' id='Voter'>
        {/* CONTENT */}
        {adminUser__currentTab == 'User Details' ? (
          <UserDetails />
        ) : (
          <>
            <NewAdminUserModal
              isOpen={openNewUserModal}
              onRequestClose={() => {
                setOpenNewUserModal(false)
              }}
              headerLabel='New admin user'
              // NOTE: roles should not be translated
              rolesOptions={adminRoles}
              setRefreshList={setRefreshList}
            />

            {/* TABS */}
            <div className='voterAddrList__tabWrap'>
              <div className='adminUsers__header'>
                <span
                  onClick={() => {
                    setAdminUser__currentTab('Users')
                  }}
                  className={
                    adminUser__currentTab == 'Users'
                      ? 'voterAddrList__activeTab'
                      : 'voterAddrList__inactiveTab'
                  }
                >
                  {translate('Users')}
                </span>
                {/* 
                <span
                  onClick={() => {
                    setAdminUser__currentTab('Restore Requests')
                  }}
                  className={
                    adminUser__currentTab == 'Restore Requests'
                      ? 'voterAddrList__activeTab'
                      : 'voterAddrList__inactiveTab'
                  }
                >
                  Restore Requests
                </span> */}
              </div>

              <div className='adminUsers__newUser__btnOuter'>
                <div
                  className='adminUsers__newUser__btn'
                  onClick={() => {
                    setOpenNewUserModal(true)
                  }}
                >
                  <img className='' src={toAbsoluteUrl('/media/design/icon_add_user_pink.svg')} />
                  <span className='adminUsers__newUser__btn__txt'>
                    {translate('New admin user')}
                  </span>
                </div>{' '}
              </div>
            </div>

            {/* BODY CONTENT */}
            {renderView()}
          </>
        )}
      </div>
    </>
  )
}
