import {useContext, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
// import './AdminSidebar.scss'
import {useHistory} from 'react-router-dom'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import {AdminSVG} from '../admin_svg/AdminSVG'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import KeycloakAdminService from '../../../../../KeycloakAdminService'
import {useTranslation} from '../../../../hooks/useTranslation'
import {beforeUnload} from '../../../../App'

export function SuperAdmin__Sidebar(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const {customAdminLogoImg, voterStyleView, customTxtClass} = useVoterStyles()
  const [currentTab, setCurrentTab] = useState('Events')

  const isSuperAdmin = KeycloakAdminService.hasRole('tivi-administrator')
  const tiviTest = KeycloakAdminService.hasRole('tivi-test')
  const contactInfoRole = KeycloakAdminService.hasRole('tivi-contactinfo')

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  // const activeTab = {
  //   nova_scotia: 'adminSideBar__tabActiveWrap',
  //   mexico: 'adminSideBar__tabActiveWrap--Mexico',
  // }

  // const activeTabTxt = {
  //   nova_scotia: 'adminSideBar__tabActiveTxt',
  //   mexico: 'adminSideBar__tabActiveTxt--Mexico',
  // }
  if (window.location.pathname.includes('register')) {
    return <div />
  }

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English' ? 'AdminSidebar--spanish' : 'AdminSidebar'
      }
    >
      <img
        className='adminSidebar__logo'
        src={toAbsoluteUrl(customAdminLogoImg)}
        alt='Elections Logo'
      />

      <hr className='AdminSidebar__divider'></hr>

      <div className='adminSideBar__tabContainer'>
        {/* TAB 1 ---------------------------------------------------
        {currentTab == 'Dashboard' ? (
          <div className={adminSideBar__tabActiveWrap--Mexico}>
            <AdminSVG icon='Dashboard' />
            <span className={'adminSideBar__tabActiveTxt--Mexico'}>
              {translate('Dashboard')}
            </span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/admin/dashboard')
              setCurrentTab('Dashboard')
            }}
          >
            <AdminSVG icon='Dashboard' inactive={true} />
            <span className='adminSideBar__tabInactiveTxt'>
              {translate('Dashboard')}
            </span>
          </div>
        )} */}
        {/* TAB 2 --------------------------------------------------- */}
        {!contactInfoRole && currentTab == 'Events' ? (
          <div
            className={'adminSideBar__tabActiveWrap--Mexico'}
            onClick={() => {
              history.push('/admin/events')
              setCurrentTab('Events')
            }}
          >
            <AdminSVG icon='Events' />
            <span className={'adminSideBar__tabActiveTxt--Mexico'}>{translate('Events')}</span>
          </div>
        ) : !contactInfoRole ? (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/admin/events')
              setCurrentTab('Events')
            }}
          >
            <AdminSVG icon='Events' inactive={true} />
            <span className='adminSideBar__tabInactiveTxt'>{translate('Events')}</span>
          </div>
        ) : null}
        {/* TAB 3 --------------------------------------------------- */}
        {currentTab == 'Electors List' ? (
          <div
            className={'adminSideBar__tabActiveWrap--Mexico'}
            onClick={() => {
              history.push('/admin/electors-list')
              setCurrentTab('Electors List')
            }}
          >
            <AdminSVG icon='Electors List' />
            <span className={'adminSideBar__tabActiveTxt--Mexico'}>
              {translate('Electors List')}
            </span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/admin/electors-list')
              setCurrentTab('Electors List')
            }}
          >
            <AdminSVG icon='Electors List' inactive={true} />
            <span className='adminSideBar__tabInactiveTxt'>{translate('Electors List')}</span>
          </div>
        )}
        {/* {isSuperAdmin && !tiviTest && (
          <div
            className={
              currentTab === 'register'
                ? 'adminSideBar__tabActiveWrap--Mexico'
                : 'adminSideBar__tabInactiveWrap'
            }
            onClick={() => {
              window.removeEventListener('beforeunload', beforeUnload)
              history.push('/admin/register')
              setCurrentTab('register')

              window.location.reload()
            }}
          >
          
            <span
              className={
                currentTab === 'register'
                  ? 'adminSideBar__tabActiveTxt--Mexico'
                  : 'adminSideBar__tabInactiveTxt'
              }
            >
              {translate('Registration')}
            </span>
          </div>
        )} */}

        {/* TAB 4 --------------------------------------------------- */}
        {isSuperAdmin ? (
          <div
            className={
              currentTab === 'Admin Users'
                ? 'adminSideBar__tabActiveWrap--Mexico'
                : 'adminSideBar__tabInactiveWrap'
            }
            onClick={() => {
              history.push('/admin/admin-users')
              setCurrentTab('Admin Users')
            }}
          >
            <AdminSVG icon='Admin Users' inactive={currentTab !== 'Admin Users'} />
            <span
              className={
                currentTab === 'Admin Users'
                  ? 'adminSideBar__tabActiveTxt--Mexico'
                  : 'adminSideBar__tabInactiveTxt'
              }
            >
              {translate('Admin Users')}
            </span>
          </div>
        ) : null}
        {/* TAB 5 --------------------------------------------------- */}
        {currentTab == 'Help & Support' ? (
          <div className={'adminSideBar__tabActiveWrap--Mexico'}>
            <AdminSVG icon='Help & Support' />
            <span className={'adminSideBar__tabActiveTxt--Mexico'}>
              {translate('Help & Support')}
            </span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/admin/help-support')
              setCurrentTab('Help & Support')
            }}
          >
            <AdminSVG icon='Help & Support' inactive={true} />
            <span className='adminSideBar__tabInactiveTxt'>{translate('Help & Support')}</span>
          </div>
        )}
        {/* TAB 6 --------------------------------------------------- */}
        {currentTab == 'My Profile' ? (
          <div className={'adminSideBar__tabActiveWrap--Mexico'}>
            <AdminSVG icon='My Profile' />
            <span className={'adminSideBar__tabActiveTxt--Mexico'}>{translate('My Profile')}</span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/admin/my-profile')
              setCurrentTab('My Profile')
            }}
          >
            <img
              width='27'
              height='27'
              src={toAbsoluteUrl('/media/design/admin_myprofile_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabInactiveTxt'>{translate('My Profile')}</span>
          </div>
        )}

        {/* TAB 6 --------------------------------------------------- */}

        {!contactInfoRole && currentTab == 'Reports' ? (
          <div className={'adminSideBar__tabActiveWrap--Mexico'}>
            <AdminSVG icon='Reports' />
            <span className={'adminSideBar__tabActiveTxt--Mexico'}>{translate('Reports')}</span>
          </div>
        ) : !contactInfoRole ? (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/admin/reports')
              setCurrentTab('Reports')
            }}
          >
            <img
              width='27'
              height='27'
              src={toAbsoluteUrl('/media/design/icon_reports_grey.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabInactiveTxt'>{translate('Reports')}</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}
