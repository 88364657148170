import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import './VoterModalHeader.scss'

export function VoterModalHeader(props) {
  let {customStyles, headerLabel, onRequestClose} = props
  return (
    <div className='VoterModalHeader' style={customStyles}>
      <span className='voterModalHeader__label'>{headerLabel}</span>

      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
        className='civik-modal-x-btn'
        onClick={onRequestClose}
      />
    </div>
  )
}
