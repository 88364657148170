import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {CustomBtn} from '../../../../../../reusable/custom_btn/CustomBtn'
import './ParticipantPhotoInfo.scss'
import {PhotoIdModal} from '../view_photo_id_modal/PhotoIdModal'
import {UploadPhotoID} from '../../../../../../voter/components/registration/UploadPhotoID'
import {useHistory} from 'react-router-dom'

export const ParticipantPhotoInfo = ({photos}) => {
  const history = useHistory()
  const [modalView, setModalView] = useState('view-photo-id')
  const [modalPhotoIdOpen, setModalPhotoIdOpen] = useState(false)
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)

  const onViewPDF = async ({url}) => {
    const encodedPDFURL = encodeURIComponent(url)
    history.push(`/admin/view-pdf/${encodedPDFURL}`)
  }

  function hasPdfExtension(filename) {
    return filename.toLowerCase().includes('.pdf')
  }
  return (
    <div className='ParticipantPhotoInfo'>
      <PhotoIdModal
        modalOpen={modalPhotoIdOpen}
        setModalOpen={setModalPhotoIdOpen}
        modalView={modalView}
        setModalView={setModalView}
        photos={photos}
        i={currentPhotoIndex}
      />

      <div className='participantPhotoInfo__photo'>
        <span className='participantPhotoInfo__photo__label'>Photo ID</span>
        <div className='participantPhotoInfo__photos'>
          {photos.map((photo, index) => {
            return (
              <div>
                {hasPdfExtension(photo) ? (
                  <span
                    style={{cursor: 'pointer', fontSize: 16, color: 'blue', marginTop: 20}}
                    onClick={() => onViewPDF({url: photo})}
                  >
                    View Pdf
                  </span>
                ) : (
                  <img
                    key={index}
                    className='participantPhotoInfo__photo__img'
                    style={{cursor: 'pointer'}}
                    alt='identification'
                    src={photo}
                    onClick={() => {
                      let photoIdURL = photo

                      if (hasPdfExtension(photoIdURL)) {
                        onViewPDF({url: photoIdURL})
                      } else {
                        setModalView('view-photo-id')
                        setCurrentPhotoIndex(index)
                        setModalPhotoIdOpen(true)
                      }
                    }}
                  />
                )}
              </div>
            )
          })}
        </div>
        <div className='participantPhotoInfo__btns'>
          <div className='participantPhotoInfo__btn--uploadNew'>
            {/* <CustomBtn
              view='ActionBtn'
              text={'Upload New'}
              height={48}
              width={174}
              onClick={() => {
                setModalUploadOpen(true)
              }}
            /> */}

            {/* <CustomBtn
              view='PrimaryBtn'
              text={'View'}
              height={48}
              width={174}
              // customStyling={{marginLeft: 25}}
              onClick={() => {
                setModalView('view-photo-id')
                setModalPhotoIdOpen(true)
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
