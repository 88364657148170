/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

import Modal from 'react-modal'

import {UserContext} from '../../../../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'

import TextField from '../../reusable/TextField'
import {EditStaffModal} from './modals/EditStaffModal'
import {CreateStaffModal} from './modals/CreateStaffModal'

import {ProfileContext} from '../../../../contexts/profilecontext'
import {AccountStepContext} from '../../../../contexts/accountStepContext'
import POLITICIAN_SERIVCE from '../../../../services/politician/PoliticianService'
import AuthService from '../../../../services/auth/AuthService'
import ROUTE_PATHS from '../../../../routing/RoutePaths'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    maxWidth: '100%',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const initialValues = {}

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required('First name is required'),
  lName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phonenumber: Yup.string().required('Phone number is required'),
})

export function CouncilorStaff() {
  const STEP_CONTEXT = useContext(AccountStepContext)
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const [showAddModal, setShowAddModal] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00'
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openAddModal() {
    setShowAddModal(true)
  }

  function afterOpenAddModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00'
  }

  function closeAddModal() {
    setShowAddModal(false)
  }

  const history = useHistory()

  const USER_CONTEXT = useContext(UserContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)

  const [loading, setLoading] = useState(false)

  const [staffId, setStaffId] = useState(null)
  const [showCompleteModal, setShowCompleteModal] = useState(false)

  // const onEditStaff = useCallback(() => MicroModal.show('modal-1'), [])

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        setLoading(false)
      }, 1000)
    },
  })

  const onDeleteStaff = async (staffId) => {
    try {
      // find the staff in list
      let index = PROFILE_CONTEXT.profile.staff.findIndex((staff) => staff._id === staffId)
      // if the staff is found using the _id then remove it from list
      if (index !== -1) PROFILE_CONTEXT.profile.staff.splice(index, 1)

      // save the updated staff list in users profile
      await POLITICIAN_SERIVCE.updatePendingProfile({staff: PROFILE_CONTEXT.profile.staff})
    } catch (error) {
      console.log(error)
    }
  }

  const completeOnboarding = async () => {
    try {
      // save the updated staff list in users profile
      await POLITICIAN_SERIVCE.updatePendingProfile({onboardingComplete: true})
      setShowCompleteModal(false)
      USER_CONTEXT.setUser({loggedIn: true, verified: true, secondarySignup: false})
      history.replace(ROUTE_PATHS.HOME)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchProfile = async () => {
    try {
      let userProfile = await AuthService.getCurrentUser()
      PROFILE_CONTEXT.setProfile(userProfile)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#EditStaff')
  }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      // id='kt_login_signup_form'
      style={{width: '100%'}}
      id='EditStaff'
    >
      <Modal
        ariaHideApp={false}
        isOpen={showCompleteModal}
        onRequestClose={() => {
          setShowCompleteModal(false)
        }}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='civik-modal' style={{padding: '30px 25px'}}>
          <div className='civik-flex-row-wrap'>
            <div className='civik-flex-column '>
              <h1>Awesome!</h1>
              <p style={{marginTop: '40px', marginRight: 80}}>
                your profile has been successfully completed and sent to a city admin for approval.
                you will recieve am email when your profile is approved, normally profile approval
                takes between 24-72 hours.
                <br></br>
                <br></br>
                You can still navigate and see the community feeds.
              </p>

              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100  mt-7'
                style={{maxWidth: 120}}
                onClick={completeOnboarding}
              >
                <span className='indicator-label'>Continue</span>
              </button>
            </div>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/completeIllustration.svg')}
              className='h-250px '
            />
          </div>
        </div>
      </Modal>

      <EditStaffModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
        staffId={staffId}
      />

      <CreateStaffModal
        ariaHideApp={false}
        isOpen={showAddModal}
        onAfterOpen={afterOpenAddModal}
        onRequestClose={closeAddModal}
        style={customStyles}
        contentLabel='Example Modal'
      />

      <div className=' mb-5 w-100'>
        <h1 className='text-dark mb-3' style={{fontSize: '24px'}}>
          Staff
        </h1>
      </div>
      {/* begin::Heading */}
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/*START*/}
      <div className='civik-flex-column'>
        {PROFILE_CONTEXT.profile.staff &&
          PROFILE_CONTEXT.profile.staff.map((staff, index) => {
            return (
              <div
                className='civik-flex-row mb-5'
                key={index}
                style={{justifyContent: 'space-between', width: '100%'}}
              >
                <div className='civik-flex-row mb-5 mr-10'>
                  {staff.profile_picture ? (
                    <img
                      alt='Logo'
                      src={
                        staff.profile_picture
                          ? staff.profile_picture
                          : toAbsoluteUrl('/media/design/facebook-grey.svg')
                      }
                      className='civik-council-staff-pic  '
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#F0EEF5',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        color: '#7E8299',
                        fontSize: 16,
                        fontWeight: 'bold',
                        width: 100,
                        height: 100,
                        borderRadius: '50%',
                        marginRight: 20,
                      }}
                    >
                      {staff.first_name[0]}
                    </div>
                  )}

                  <div className='civik-flex-column'>
                    <h4 className='civik-dark-blue fw-bold'>
                      {staff.first_name + ' ' + staff.last_name}
                    </h4>
                    {staff.position && <span className='civik-dark-grey'> {staff.position}</span>}
                    <span className='civik-primary-blue'>{staff.email}</span>
                    <span className='civik-dark-grey'>{staff.phone}</span>
                  </div>
                </div>

                <div className='civik-flex-row mobile-show ml-5'>
                  <FeatherIcon
                    icon={'edit'}
                    className='mb-5'
                    color='#181c32'
                    onClick={() => {
                      setStaffId(staff._id)
                      openModal()
                    }}
                  />
                  <FeatherIcon
                    className=''
                    icon={'x'}
                    color='red'
                    onClick={() => {
                      onDeleteStaff(staff._id)
                    }}
                  />
                </div>
                <div className='civik-flex-row mobile-hide'>
                  <button
                    type='submit'
                    className='civik-dark-grey civik-txt-delete-btn fw-bold mr-10'
                    onClick={() => {
                      onDeleteStaff(staff._id)
                    }}
                  >
                    Delete
                  </button>
                  <button
                    id='kt_sign_up_submit'
                    className='btn btn-light btn-active-light-primary '
                    style={{maxWidth: 110}}
                    onClick={() => {
                      setStaffId(staff._id)
                      openModal()
                    }}
                  >
                    <span className='indicator-label'>Edit</span>
                  </button>
                </div>
              </div>
            )
          })}
      </div>
      {/* END  */}

      {/* ADD STAFF BUTTON */}
      <div
        className='civik-flex-row'
        style={{justifyContent: 'flex-end', marginBottom: 10, marginTop: 10}}
      >
        <div className='civik-flex-row civik-btn-wrap' onClick={openAddModal}>
          <button className='civik-primary-blue civik-txt-primary-btn fw-bold'>Add Staff</button>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/design/blue-plus-circle.svg')}
            className='h-25px'
          />
        </div>
      </div>
      {/*  */}
      <div className='civik-flex-row' style={{justifyContent: 'flex-end'}}>
        <Link to='/auth/councilor-about' className='link-primary fs-6 fw-bolder'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-light btn-active-light-primary mt-7'
            style={{maxWidth: 110, marginRight: 25}}
            onClick={async () => {
              await POLITICIAN_SERIVCE.updatePendingProfile({onboardingStep: 2})
            }}
          >
            <span className='indicator-label'>Previous</span>
          </button>
        </Link>

        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100  mt-7'
          style={{maxWidth: 180}}
          onClick={async () => {
            // STEP_CONTEXT.setStep(3)
            await POLITICIAN_SERIVCE.updatePendingProfile({onboardingStep: 3})
            setShowCompleteModal(true)
          }}
        >
          {!loading && <span className='indicator-label'>Save & Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
