import {useEffect, useState} from 'react'
import PDFViewerPage from '../../../../../modules/reusable/pdf_viewer/PdfViewerPage'
import './SuperAdmin__ViewPDF.scss'
import {useHistory, useParams} from 'react-router-dom'

const SuperAdmin__ViewPDF = () => {
  let {pdfURL} = useParams()
  const decodedPDFURL = decodeURIComponent(pdfURL)
  const history = useHistory()
  // Assuming you have the pdfURL defined somewhere
  // const pdfURL = `your-base-url/${url}`

  useEffect(() => {
    console.log('root', pdfURL)
  })

  return (
    <div>
      <PDFViewerPage
        file={null}
        pdfURL={decodedPDFURL}
        onClose={() => {
          history.goBack()
        }}
      />
    </div>
  )
}

export default SuperAdmin__ViewPDF
