import {createContext, useContext, useState} from 'react'
import AdminVoterAuthService from '../services/auth/AdminVoterAuthService'
import {useHistory} from 'react-router-dom'
import {VoterLoginFlowContext} from '../contexts/VoterLoginFlowContext'

export const SessionTimerContext = createContext()

export const SessionTimerProvider = ({children}) => {
  const [extendSession, setExtendSession] = useState(false)
  const [sessionEnding, setSessionEnding] = useState(false)

  const history = useHistory()
  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)

  const doExtendSession = async () => {
    await AdminVoterAuthService.extendSession()
  }
  const endSession = async () => {
    try {
      await AdminVoterAuthService.logOut(history)
      VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Login')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <SessionTimerContext.Provider
      value={{
        extendSession,
        setExtendSession,
        sessionEnding,
        setSessionEnding,
        doExtendSession,
        endSession,
      }}
    >
      {children}
    </SessionTimerContext.Provider>
  )
}

const PROVIDER_ERROR = 'useSessionTimer must be used within a SessionTimerProvider'

export const useSessionTimer = () => {
  const context = useContext(SessionTimerContext)
  if (!context) {
    throw new Error(PROVIDER_ERROR)
  }
  return context
}

export default SessionTimerContext
