/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import {LanguageContext} from '../../../../../../../../contexts/LanguageContext'
import {toAbsoluteUrl} from '../../../../../../../../../_metronic/helpers'
import '../../../common.scss'
import './EventVoteCard.scss'

import '../../MEXICO__Dashboard.scss'
import {ModalWrapper} from '../../../../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'

import moment from 'moment'
import {VotingReceiptModals} from './voting_receipt_modal/VotingReceiptModals'
import VoterAuthService from '../../../../../../../../services/auth/VoterAuthService'
import {useLocation} from 'react-router-dom'
import {beforeUnload} from '../../../../../../../../App'
import {useTranslation} from '../../../../../../../../hooks/useTranslation'
import {formatDateLocale} from '../../../../../../../../languages/datetimeformats'
import {languageValueToKey} from '../../../../../../../../languages/languagekey'
import ConfirmVoteModal from './ConfirmVoteModal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999999999',
  },
}

export function EventVoteCard(props) {
  const {translate, translateObj} = useTranslation()

  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const location = useLocation()
  const {hasVoted, checkReceipt, receiptParam} = props
  const propsEvent = props.event

  const [currentDays, setCurrentDays] = useState(null)
  const [currentHours, setCurrentHours] = useState(null)
  const [currentMinutes, setCurrentMinutes] = useState(null)
  const [currentSeconds, setCurrentSeconds] = useState(null)
  const [event, setEvent] = useState(propsEvent || {})
  const [votingOpen, setvotingOpen] = useState(false)

  const [confirmIsOpen, setIsOpenConfirm] = useState(false)

  let {closeDate} = event
  closeDate = moment(closeDate)

  const formatCloseDate = () => {
    let day = formatDateLocale(closeDate, LANGUAGE_CONTEXT)

    if (day !== currentDays) {
      setCurrentDays(day)
    }
    return day
  }

  const getDays = () => {
    let now = moment()
    let days = closeDate.diff(now, 'days')
    return days
  }
  const getHours = () => {
    let now = moment()
    now = now.add(getDays(), 'days')
    let hours = closeDate.diff(now, 'hours')
    if (hours !== currentHours) {
      setCurrentHours(hours)
    }
    return hours
  }
  const getMinutes = () => {
    let now = moment()
    now = now.add(getDays(), 'days').add(getHours(), 'hours')
    let minutes = closeDate.diff(now, 'minutes')
    if (minutes !== currentMinutes) {
      setCurrentMinutes(minutes)
    }
    return minutes
  }
  const getSeconds = () => {
    let now = moment()
    now = now.add(getDays(), 'days').add(getHours(), 'hours').add(getMinutes(), 'minutes')
    let seconds = closeDate.diff(now, 'seconds')

    if (seconds !== currentSeconds) {
      setCurrentSeconds(seconds)
    }
  }

  useEffect(() => {
    const interval = setInterval(getSeconds, 1000)
    return () => clearInterval(interval)
  }, [])

  const fetchEvent = async () => {
    let updatedEvent = await VoterAuthService.getEventById(propsEvent.tiviId)
    if (updatedEvent) {
      setEvent(updatedEvent)
      return updatedEvent
    }
  }
  // check if event state has changed to started at scheduled event open time
  const checkIsVoteTime = async () => {
    if (event.state === 'finalized') {
      let now = moment()
      let open = moment(event.openDate)
      let dif = open.diff(now, 'seconds')
      // 9 seconds seems to be how long it takes for the api to update the event state
      if (!votingOpen && dif <= -9 && dif > -12) {
        await fetchEvent()
      }
    }
  }
  useEffect(() => {
    fetchEvent()
  }, [location])

  useEffect(() => {
    let totalSecsLeft = closeDate.diff(moment(), 'seconds')
    // if (totalSecsLeft < 0 && votingOpen) {
    //   setvotingOpen(false)
    // }
    // else
    if (event.state === 'started' && !votingOpen && totalSecsLeft > 0) {
      setvotingOpen(true)
    } else if (event.state !== 'started' && votingOpen) {
      setvotingOpen(false)
    }
    checkIsVoteTime()
  }, [currentSeconds, event.state])

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalView, setModalView] = useState('INE__ExampleBallot')

  const [votingModalOpen, setVotingModalOpen] = useState(false)
  const [votingModalView, setVotingModalView] = useState('VotingReceipt')

  const [headerLabel, setHeaderLabel] = useState(translate('Verify Vote Receipt'))

  function afterOpenModal() {}
  function closeModal() {
    setModalIsOpen(false)
  }

  useEffect(() => {
    setInterval(getSeconds, 1000)
  })

  useEffect(() => {
    if (receiptParam) {
      setVotingModalOpen(true)
    }
  }, [])

  const onVoteNow = () => {
    window.removeEventListener('beforeunload', beforeUnload)
    window.location.href = `${event.eventLink}?locale=${
      languageValueToKey[LANGUAGE_CONTEXT.currentLanguage] || 'en'
    }`
  }

  return (
    <div className='EventVoteCard' id='Voter'>
      <ModalWrapper
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
        // ballotUrl={event.ballotUrl}
        headerLabel={translate('Ballot preview')}
      />

      <VotingReceiptModals
        isOpen={votingModalOpen}
        onRequestClose={() => {
          setVotingModalOpen(false)
        }}
        style={customStyles}
        modalView={votingModalView}
        setModalView={setVotingModalView}
        headerLabel={headerLabel}
        setHeaderLabel={setHeaderLabel}
        questions={event.questions || []}
        checkReceipt={checkReceipt}
        receiptParam={receiptParam}
      />

      {/* <ConfirmVoteModal
        isOpen={confirmIsOpen}
        onRequestClose={() => {
          setVotingModalOpen(false)
        }}
        style={customStyles}
        setIsOpen={setIsOpenConfirm}
        onConfirm={onVoteNow}
      /> */}

      <div className='eventVoteCard__body'>
        <div className='eventVoteCard__body__row1'>
          <img
            className='eventVoteCard__body__row1--calendar'
            src={toAbsoluteUrl('/media/design/icon_pink_calendar_check.svg')}
          />
          <div className='eventVoteCard__body__row1__content'>
            <div className='eventVoteCard__body__row1__content__titleWrap'>
              <div>
                <div className='eventVoteCard__body__row1__content__votIsOpenWrap'>
                  <span className='dashboard__body__topBanner__voteStatus__label'>
                    {translateObj(event.name)}
                  </span>
                  <span className='dashboard__body__topBanner__voteStatus__divider'>|</span>
                  <span
                    className={
                      votingOpen
                        ? 'dashboard__body__topBanner__voteStatus__value'
                        : 'dashboard__body__topBanner__voteStatus__value--notOpened'
                    }
                  >
                    {votingOpen ? translate('Voting is Open') : translate('Voting is not opened')}
                  </span>
                </div>

                <span className='dashboard__body__topBanner__label'>
                  {translateObj(event.description)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='eventVoteCard__body__row2'>
          <img
            className='eventVoteCard__body__row2__icon--clock'
            src={toAbsoluteUrl('/media/design/icon_pink_alarm.svg')}
          />

          <div className='eventVoteCard__body__row2__content'>
            <div className='eventVoteCard__body__row2__content__cards'>
              <div className='common__flex__column'>
                <div className='dashboard__body__topBanner__closingAtCard'>
                  <span className='dashboard__body__topBanner__closingAtCard__label'>
                    {translate('Voting will close on')}:
                  </span>

                  <span className='dashboard__body__topBanner__closingAtCard__value'>
                    {formatCloseDate()}
                  </span>
                </div>
                {/* <div className='eventVoteCard__body__row2__content__linkWrap eventVoteCard__body__row2__content__linkWrap--1'>
                  <img
                    className='eventVoteCard__body__row2__content__linkWrap__icon--ballot'
                    src={toAbsoluteUrl('/media/design/icon_grey_ballot.svg')}
                  />
                  <span
                    className='eventVoteCard__body__row2__content__linkWrap__txt'
                    onClick={() => {
                      setModalIsOpen(true)
                    }}
                  >
                    {translate('Know your ballot')}
                  </span>
                </div> */}
              </div>
              <div className='common__flex__column dashboard__body__topBanner__votingStatusCardWrap'>
                <div className='dashboard__body__topBanner__votingStatusCard'>
                  <span className='dashboard__body__topBanner__votingStatusCard__label'>
                    {translate('Have you voted yet?')}
                  </span>

                  <span className='dashboard__body__topBanner__votingStatusCard__value'>
                    {!hasVoted ? translate('Not voted yet') : translate('Voted')}
                  </span>
                </div>

                {/* <div className='eventVoteCard__body__row2__content__linkWrap eventVoteCard__body__row2__content__linkWrap--mobile'>
                  <img
                    className='eventVoteCard__body__row2__content__linkWrap__icon--ballot'
                    src={toAbsoluteUrl('/media/design/icon_grey_ballot.svg')}
                  />
                  <span
                    className='eventVoteCard__body__row2__content__linkWrap__txt'
                    onClick={() => {
                      setModalIsOpen(true)
                    }}
                  >
                    {translate('Know your ballot')}
                  </span>
                </div> */}

                <div
                  className='eventVoteCard__body__row2__content__linkWrap'
                  onClick={() => {
                    if (event.state && ['ended', 'decrypting', 'decrypted'].includes(event.state)) {
                      alert(translate('You can verify your vote after all votes are tallied'))
                      // } else if (event.multipleVotesEnabled) {
                      //   alert(
                      //     translate(
                      //       'Voting receipts cannot be verified in elections that allow multiple votes'
                      //     )
                      //   )
                    } else {
                      setVotingModalView('VotingReceipt')
                      setVotingModalOpen(true)
                    }
                  }}
                >
                  <img
                    className='eventVoteCard__body__row2__content__linkWrap__icon--reciept'
                    src={toAbsoluteUrl('/media/design/icon_grey_reciept.svg')}
                  />
                  <span className='eventVoteCard__body__row2__content__linkWrap__txt'>
                    {translate('Verify Vote Receipt')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {votingOpen && (
        <div className='eventVoteCard__footer'>
          <div className=' eventVoteCard__footer__contentWrap'>
            <span className='eventVoteCard__footer__title'>{translate('Voting closes in:')}</span>

            <div className='common__flex__row'>
              <div className='eventVoteCard__footer__timeCardWrap'>
                <span className='eventVoteCard__footer__timeCard__smallLabel'>
                  {translate('Days')}
                </span>
                <div className='eventVoteCard__footer__timeCard'>
                  <span className='eventVoteCard__footer__timeCard__value'>{getDays()}</span>
                </div>
              </div>
              <span className='eventVoteCard__footer__timeDivider'>:</span>
              <div className='eventVoteCard__footer__timeCardWrap'>
                <span className='eventVoteCard__footer__timeCard__smallLabel'>
                  {translate('Hours')}
                </span>
                <div className='eventVoteCard__footer__timeCard'>
                  <span className='eventVoteCard__footer__timeCard__value'>{currentHours}</span>
                </div>
              </div>

              <span className='eventVoteCard__footer__timeDivider'>:</span>

              <div className='eventVoteCard__footer__timeCardWrap'>
                <span className='eventVoteCard__footer__timeCard__smallLabel'>
                  {translate('Minutes')}
                </span>
                <div className='eventVoteCard__footer__timeCard'>
                  <span className='eventVoteCard__footer__timeCard__value'>{currentMinutes}</span>
                </div>
              </div>
              <span className='eventVoteCard__footer__timeDivider'>:</span>
              <div className='eventVoteCard__footer__timeCardWrap'>
                <span className='eventVoteCard__footer__timeCard__smallLabel'>
                  {translate('Seconds')}
                </span>
                <div className='eventVoteCard__footer__timeCard'>
                  <span className='eventVoteCard__footer__timeCard__value'>{currentSeconds}</span>
                </div>
              </div>
            </div>
          </div>

          {!hasVoted || event.multipleVotesEnabled ? (
            <div className='eventVoteCard__footer__btnWrap'>
              <button
                className={`dashboard__body__topBanner__voteNowBtn eventVoteCard__footer__btn--voteNow`}
                onClick={() => {
                  onVoteNow()
                }}
              >
                {translate('Vote Now')}
              </button>{' '}
            </div>
          ) : (
            <div style={{height: 50}} />
          )}
        </div>
      )}
    </div>
  )
}
