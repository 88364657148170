import {useContext} from 'react'

import * as Yup from 'yup'

import {AdminDropdown} from '../../../../../../voter_admin/reusable/admin_dropdown/AdminDropdown'

import '../UsersList.scss'
import {useTranslation} from '../../../../../../../hooks/useTranslation'

export const initialValues = {}

export const loginSchema = Yup.object().shape({})
const REQUEST_TYPES = {
  RESET_PASSWORD: 'Reset Password',
  UNLOCK_ACCOUNT: 'Unlock Account',
  REGENERATE_QR_CODE: 'Regenerate QR Code',
}

export function UsersListTable(props) {
  const {translate, translateObj} = useTranslation()

  let {users} = props

  const formatSupportRequests = (user) => {
    let requests = user.attributes?.authSupportRequests?.map((r) =>
      REQUEST_TYPES[r] ? translate(REQUEST_TYPES[r]) : r
    )
    return requests?.join(', ')
  }
  return (
    <div id='Voter'>
      {/* CONSOLE */}
      <div className='voterAddrList__tableContanier voterAddrList__tableContanier--adminUsers'>
        {/* TABLE */}
        <div className='voterAddrList__table'>
          <div className='voterAddrList__tableHeader'>
            <span className='voterAddrList__tableHeader__columnLabel column-width-25'>
              {translate('Username')}
            </span>
            {/* <span className='voterAddrList__tableHeader__columnLabel column-width-25'>
              Email Address
            </span> */}
            <span className='voterAddrList__tableHeader__columnLabel column-width-25'>
              {translate('Role')}
            </span>

            <span className='voterAddrList__tableHeader__columnLabel column-width-15'>
              {translate('Requests')}
            </span>

            <span className='voterAddrList__tableHeader__columnLabel column-width-10'>
              {translate('Status')}
            </span>

            <span className='voterAddrList__tableHeader__columnLabel column-width-5'>
              {translate('Action')}
            </span>
          </div>

          <div className='voterAddrList__tableBody'>
            {users?.map((user) => (
              <div className='voterAddrList__tableBody__row'>
                <span className='column-width-25'>{user.username}</span>
                {/* <span className='column-width-25'>{user.email}</span> */}
                <span className='column-width-25' style={{whiteSpace: 'pre-wrap'}}>
                  {user.roles?.length ? translateObj(user.roles[0].displayName) : ''}
                </span>

                <span className='column-width-15'>{formatSupportRequests(user)}</span>
                <div className='column-width-10'>
                  <span
                    className={`importAddrList__statusLabel ${
                      user.enabled
                        ? `importAddrList__statusLabel--success`
                        : `importAddrList__statusLabel--failed`
                    }`}
                  >
                    {user.enabled ? translate('Enabled') : translate('Locked')}
                  </span>
                </div>

                <div className='column-width-5 column-width-5--adminUserDropdown'>
                  <AdminDropdown
                    view='mexicoAdminUser'
                    user={user}
                    keycloakId={user.id}
                    fetchUsers={props.fetchUsers}
                  />{' '}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
