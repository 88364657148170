import CheckBox from '../../../../reusable/check_box/CheckBox'
import {CustomBtn} from '../../../../reusable/custom_btn/CustomBtn'
import {VoterRegistrationUserContext} from '../../../../../contexts/RegistrationUser'
import {useContext} from 'react'
import VoterRegistrationService from '../../../services/VoterRegistrationService'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'

export const FollowUpStep1 = ({selectedOption, setSelectedOption, setCurrentStep}) => {
  let RegisterUser = useContext(VoterRegistrationUserContext)
  const {executeRecaptcha} = useGoogleReCaptcha()
  const handleQuestion = (option) => {
    setSelectedOption(option)
    // Handle logic for the selected option
    if (option === 'Email') {
      console.log('User selected Email')
    } else if (option === 'SMS') {
      console.log('User selected SMS')
    } else if (option === 'Both') {
      console.log('User selected Both')
    }
  }

  return (
    <div className='voterRegisterFollowUp__form'>
      <span className='verifyMethod__formHeaderTitle'>One more step!</span>

      <span className='confirmCode__formHeaderTxt mt-10'>
        we will send a verification code to you, select where you would like the code to be sent.
      </span>

      <div className='voterRegisterFollowUp__form__options'>
        <CheckBox
          view='CircleCheck'
          checked={selectedOption === 'Email'}
          onChange={() => handleQuestion('Email')}
          error={null}
          label='Send to email my address (Email)'
        />
        <CheckBox
          view='CircleCheck'
          checked={selectedOption === 'SMS'}
          onChange={() => handleQuestion('SMS')}
          error={null}
          label='Send to my phone (SMS)'
        />
        {/* 
        <CheckBox
          view='CircleCheck'
          checked={selectedOption === 'Both'}
          onChange={() => handleQuestion('Both')}
          error={null}
          label='Send to both'
        /> */}
      </div>

      <CustomBtn
        view='ActionBtn'
        text={'Send'}
        height={50}
        customStyling={{width: '100%', marginTop: 32}}
        onClick={async () => {
          const token = await executeRecaptcha('register_participant1')
          let res
          if (selectedOption === 'SMS') {
            res = await VoterRegistrationService.sendSMSCode(
              RegisterUser.registrationUser.user.phone,
              token
            )
          } else {
            res = await VoterRegistrationService.sendEmailCode(
              RegisterUser.registrationUser.user.email,
              token
            )
          }
          if (res.success) {
            setCurrentStep(2)
          }
        }}
      />
    </div>
  )
}
