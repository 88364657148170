import './ParticipantLocationInfo.scss'
export const ParticipantLocationInfo = ({resAddress, mailAddress}) => {
  return (
    <div className='ParticipantLocationInfo'>
      <div className='participantLocationInfo__resAddress'>
        <span className='participantLocationInfo__resAddress__label'>Residential Address</span>
        <span className='participantLocationInfo__resAddress__value'>{resAddress}</span>
      </div>

      <div className='participantLocationInfo__mailAddress'>
        <span className='participantLocationInfo__mailAddress__label'>Mailing Address</span>
        <span
          className={`participantLocationInfo__mailAddress__value ${
            !mailAddress && 'participantLocationInfo__mailAddress__value--light'
          }`}
        >
          {!mailAddress ? 'My mailing address is the same as my residential address' : mailAddress}
        </span>
      </div>
    </div>
  )
}
