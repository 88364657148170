import Keycloak from 'keycloak-js'
import environment from './utils/environment'

const keycloakConfig = {
  url: environment.resolveApi().keycloak_url,
  clientId: 'voter-registration-react',
  realm: environment.resolveApi().realm ? environment.resolveApi().realm : 'voter-registration',
}

const _kc = new Keycloak(keycloakConfig)

// const _kc = new Keycloak({
//   url: 'http://localhost:8080/auth',
//   realm: 'voter-registration',
//   clientId: 'voter-registration-react',
// })
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      // console.log(authenticated, _kc)
      if (!authenticated) {
        console.log('user is not authenticated..!')
      } else {
        console.log('user is authenticated.')
      }
      onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const updateToken = (timeToExpiry) => _kc.updateToken(timeToExpiry)

const getUsername = () => _kc.tokenParsed?.preferred_username

const getParsedToken = () => _kc.tokenParsed

const hasRole = (role) => _kc.hasRealmRole(role)

const onTokenExpired = async (onSessionExpired, timeToExpiry) => {
  try {
    await _kc.updateToken(timeToExpiry)
  } catch (error) {
    onSessionExpired()
  }
}

const getSessionStartTime = () => _kc.tokenParsed?.auth_time

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getParsedToken,
  onTokenExpired,
  getSessionStartTime,
}

export default KeycloakService

// http://localhost:3011/voting/voter/dashboard?session_state=2c4e547c-7d35-4bde-8486-a4071ce44adb&code=c689ce82-7561-4549-9450-5333c42b0d75.2c4e547c-7d35-4bde-8486-a4071ce44adb.04bcab26-4b46-4da5-96e9-f44858a8d839
