import './StatusBox.scss'

export const StatusBox = ({label, color, customStyling = {}}) => {
  return (
    <div className='StatusBox' style={{...customStyling, border: `1px solid ${color}`}}>
      <span className='statusBox__label' style={{color: color}}>
        {label}
      </span>
    </div>
  )
}
