/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import './MEXICO__SuperAdmin__HelpAndSupport.scss'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {AddDocumentModals} from './add_doc_modals/AddDocumentModals'
import {AdminDropdown} from '../../../../voter_admin/reusable/admin_dropdown/AdminDropdown'
import AdminVoterAuthService from '../../../../../services/auth/AdminVoterAuthService'
import KeycloakAdminService from '../../../../../../KeycloakAdminService'
import VoterAuthService from '../../../../../services/auth/VoterAuthService'
import {beforeUnload} from '../../../../../App'
import {useTranslation} from '../../../../../hooks/useTranslation'

export function MEXICO__SuperAdmin__HelpAndSupport(props) {
  const {translate} = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const [currentView, setCurrentView] = useState('Add Document')

  const [listOfDocs, setListOfDocs] = useState([] as any)

  let viewOnly = true
  const isAdmin = KeycloakAdminService?.isLoggedIn()
  if (
    isAdmin &&
    (KeycloakAdminService.hasRole('tivi-administrator') ||
      KeycloakAdminService.hasRole('tivi-administration-module-administrator'))
  ) {
    viewOnly = false
  }

  const getFileType = (filename) => {
    let extension = filename.split('.').pop()

    return extension
  }

  const getHelpDocs = async () => {
    try {
      let resp
      if (isAdmin) {
        resp = await AdminVoterAuthService.getHelpDocs()
      } else {
        resp = await VoterAuthService.getHelpDocs()
      }
      if (resp) {
        setListOfDocs(resp)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteHelpDoc = async (documentId) => {
    try {
      await AdminVoterAuthService.deleteHelpDoc(documentId)
      getHelpDocs()
    } catch (error) {
      console.log(error)
    }
  }

  // Function will execute on click of button
  const downloadFile = (file) => {
    // Setting various property values
    window.removeEventListener('beforeunload', beforeUnload)

    let alink = document.createElement('a')
    alink.href = file.fileUrl
    alink.download = `${file.filename}`
    document.body.appendChild(alink)
    alink.click()
    document.body.removeChild(alink)
  }

  useEffect(() => {
    getHelpDocs()
  }, [])
  return (
    <div className='MEXICO__MyProfile SuperAdmin__HelpAndSupport'>
      <AddDocumentModals
        headerLabel={'Add Document'}
        modalOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false)
        }}
        view={currentView}
        getHelpDocs={getHelpDocs}
      />

      <span className='helpAndSupportM__title'> {translate('Help & Support')}</span>
      <div className='common__pg__header helpSupport__header'>
        <span className='common__pg__header__title'>{translate('Help & Support')}</span>
        {!viewOnly && (
          <div
            className='adminUsers__newUser__btn  helpSupport__header__btn'
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <img className='' src={toAbsoluteUrl('/media/design/icon_add_user_grey.svg')} />
            <span className='adminUsers__newUser__btn__txt helpSupport__header__btn__txt'>
              {translate('Add Document')}
            </span>
          </div>
        )}
      </div>

      <div className='myProfile__body'>
        <div className='helpSupport__cardOuter'>
          {listOfDocs.map((document, index) => {
            return (
              <div className='helpSupport__card' key={index}>
                <div className='helpSupport__body'>
                  <span className='helpSupport__body__txt'>{document.title}</span>
                </div>
                <div className='helpSupport__footer'>
                  {getFileType(document.filename) == 'pdf' && (
                    <img className='' src={toAbsoluteUrl('/media/design/icon_pdf.svg')} />
                  )}
                  {getFileType(document.filename) == 'jpg' ||
                    (getFileType(document.filename) == 'jpeg' && (
                      <img className='' src={toAbsoluteUrl('/media/design/icon_jpg.svg')} />
                    ))}
                  {getFileType(document.filename) == 'png' && (
                    <img className='' src={toAbsoluteUrl('/media/design/icon_png.svg')} />
                  )}
                  {getFileType(document.filename) == 'doc' ||
                    (getFileType(document.filename) == 'docx' && (
                      <img className='' src={toAbsoluteUrl('/media/design/icon_doc.svg')} />
                    ))}
                  {['mov', 'mp4'].includes(getFileType(document.filename)) && (
                    <img className='' src={toAbsoluteUrl('/media/design/icon_video.svg')} />
                  )}
                  {['mp3', 'wav'].includes(getFileType(document.filename)) && (
                    <img className='' src={toAbsoluteUrl('/media/design/icon_audio.svg')} />
                  )}
                  {!viewOnly && (
                    <AdminDropdown
                      view='EditDelete'
                      onCallback={(value) => {
                        if (value == 'Delete') {
                          deleteHelpDoc(document.id)
                        }
                        if (value == 'Download') {
                          downloadFile(document)
                        }
                      }}
                    />
                  )}

                  {viewOnly && (
                    <button
                      className='helpSupport__download'
                      onClick={(e) => {
                        e.preventDefault()
                        downloadFile(document)
                      }}
                    >
                      {translate('Download')}
                    </button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
