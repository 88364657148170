import {useContext, useEffect, useState} from 'react'
import KeycloakServiceVoter from '../../Keycloak'
import KeycloakAdminService from '../../KeycloakAdminService'
import moment from 'moment'
import VoterAuthService from '../services/auth/VoterAuthService'
import AdminVoterAuthService from '../services/auth/AdminVoterAuthService'
import {useHistory} from 'react-router-dom'
import {VoterLoginFlowContext} from '../contexts/VoterLoginFlowContext'
import {useSessionTimer} from '../contexts/SessionTimerContext'
import {beforeUnload} from '../App'

const formatTwodigits = (numStr) => {
  // let numStr = num.toString()
  numStr = numStr.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  return numStr
}

export function useKeycloakSessionTime() {
  const history = useHistory()
  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)

  const admin = window.location.pathname?.startsWith('/admin') ? true : false
  const SESSION_DURATION_MINS = admin ? 4 * 2 * 30 : 30
  const REMAINING_TIME_WARN_MINS = 5

  const KeycloakService = admin ? KeycloakAdminService : KeycloakServiceVoter
  //@ts-ignore
  let flow = history?.location?.state?.flow

  const onSessionExpired = async () => {
    try {
      window.removeEventListener('beforeunload', beforeUnload)
      if (!admin) {
        await VoterAuthService.logOut(history)
        VOTER_LOGIN_CONTEXT.setLoginFlow('Login')
      } else {
        await AdminVoterAuthService.logOut(history)
        VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Login')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [sessionTimeRemaining, setSessionTimeRemaining] = useState('')
  const {setSessionEnding} = useSessionTimer()

  const getSessionTimeRemaining = async () => {
    try {
      let now = moment()
      const sessionEndTime = moment
        .unix(KeycloakService.getSessionStartTime())
        .add(SESSION_DURATION_MINS, 'minutes')

      let sessionEndTimeUnformated = sessionEndTime.diff(now, 'seconds')
      // console.log(sessionEndTimeUnformated)
      let hoursLeft = Math.max(Math.floor(sessionEndTimeUnformated / (60 * 60)), 0)
      let minutesLeft = Math.max(
        Math.floor((sessionEndTimeUnformated - hoursLeft * 60 * 60) / 60),
        0
      )
      let secondsLeft = sessionEndTimeUnformated - minutesLeft * 60 - hoursLeft * 60 * 60
      let formattedMins = admin ? minutesLeft : formatTwodigits(minutesLeft)
      let formattedSecs = formatTwodigits(secondsLeft)

      if (hoursLeft) {
        setSessionTimeRemaining(`${hoursLeft} h ${formattedMins} min ${formattedSecs} s`)
      } else {
        setSessionTimeRemaining(`${formattedMins} min ${formattedSecs} s`)
      }

      if (admin && !hoursLeft && minutesLeft === REMAINING_TIME_WARN_MINS && secondsLeft === 0) {
        // display pop up
        window.removeEventListener('beforeunload', beforeUnload)
        setSessionEnding(true)
      }

      // admins are now getting 1 minute at the end of their session to decide to extend the session
      if (sessionEndTimeUnformated <= 0) {
        onSessionExpired()
      }
    } catch (error) {}
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // for unauthenticated change password page
      getSessionTimeRemaining()
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return sessionTimeRemaining
}
