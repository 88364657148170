import {useState, useContext} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AdminTopbar} from '../pages/voter_admin/reusable/admin_topbar/AdminTopbar'
import {MEXICO__AuthAppSetup} from '../pages/voter_admin/pages/mexico/onboarding/MEXICO__AuthAppSetup'
import {MEXICO__AuthVerify} from '../pages/voter_admin/pages/mexico/onboarding/MEXICO__AuthVerify'
import {VoterOnboardingContext} from '../contexts/VoterOnboardingContext'
import {VoterAuthContext} from '../contexts/VoterAuthContext'
import {MEXICO__ResetPassword} from '../pages/voter_admin/pages/mexico/reset_password/MEXICO__ResetPassword'
import {VoterLoginFlowContext} from '../contexts/VoterLoginFlowContext'
import {VoterNavbar} from '../pages/voter/reusable/VoterNavbar'
import {SuperAdmin__ChangePassword} from '../pages/voter_admin/pages/mexico/reset_password/change_password/SuperAdmin__ChangePassword'
import {useAdminKeycloakAuth} from '../hooks/useAdminKeycloakAuth'
import {MEXICO__AdminLogin} from '../pages/super_admin/mexico/pages/auth/MEXICO__AdminLogin'
import {SuperAdmin__Sidebar} from '../pages/voter_admin/reusable/super_admin_sidebar/SuperAdmin__Sidebar'
import {SuperAdmin__DashboardRoutes} from './SuperAdmin__DashboardRoutes'
import {useTranslation} from '../hooks/useTranslation'
import RecapatchaDisclaimer from '../resuable/recapatcha/RecapatchaDisclaimer'

const SuperAdminRoutes = () => {
  const [adminView, setAdminView] = useState(false)
  const [authMethod, setAuthMethod] = useState(null)
  const [loginFlow, setLoginFlow] = useState('Admin Login')

  useAdminKeycloakAuth({setAdminView})

  // private theme when user in anonymous mode
  return (
    <div style={{maxWidth: '100%'}}>
      <VoterLoginFlowContext.Provider value={{loginFlow, setLoginFlow}}>
        <VoterAuthContext.Provider value={{adminView, setAdminView}}>
          {!adminView ? (
            <VoterOnboardingContext.Provider value={{authMethod, setAuthMethod}}>
              <div
                style={{
                  maxWidth: '100%',
                  height: '100%',
                  display: 'flex',
                  width: '100%',
                  flexFlow: 'column',
                }}
              >
                <VoterNavbar />

                <Switch>
                  <Route path='/admin/login' component={MEXICO__AdminLogin} exact />
                  <Route path='/admin/auth'>
                    <Switch>
                      <Route path='/admin/auth/verify' component={MEXICO__AuthVerify} />
                      <Route path='/admin/auth/auth-app-setup' component={MEXICO__AuthAppSetup} />
                      <Route path='/admin/auth/reset-password' component={MEXICO__ResetPassword} />
                      <Route
                        path='/admin/auth/change-password'
                        component={SuperAdmin__ChangePassword}
                      />
                      <Route path='*' render={() => <Redirect to='/admin/login' />} />
                    </Switch>
                  </Route>
                </Switch>

                <RecapatchaDisclaimer />
              </div>
            </VoterOnboardingContext.Provider>
          ) : (
            <>
              <SuperAdmin__Sidebar />
              <AdminTopbar />
              <SuperAdmin__DashboardRoutes />
            </>
          )}
        </VoterAuthContext.Provider>
      </VoterLoginFlowContext.Provider>
    </div>
  )
}

export {SuperAdminRoutes}
