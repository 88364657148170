import {useState, useEffect} from 'react'

import TextField from '../../../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../../../app/modules/auth/reusable/SelectField'

import '../../pages/VoterRegister.scss'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import CheckBox from '../../../reusable/check_box/CheckBox'
import PlacesAutocomplete from '../../../../modules/reusable/GooglePlacesAutocomplete'
import {mapProvinceCodeToName} from '../../../reusable/province_mapper/ProvinceMapper'
import {useTranslation} from '../../../../hooks/useTranslation'

const provinces = [
  {value: 0, label: 'Province'},
  {value: 'Alberta', label: 'Alberta'},
  {value: 'British Columbia', label: 'British Columbia'},
  {value: 'Manitoba', label: 'Manitoba'},
  {value: 'New Brunswick', label: 'New Brunswick'},
  {value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador'},
  {value: 'Northwest Territories', label: 'Northwest Territories'},
  {value: 'Nova Scotia', label: 'Nova Scotia'},
  {value: 'Nunavut', label: 'Nunavut'},
  {value: 'Ontario', label: 'Ontario'},
  {value: 'Prince Edward Island', label: 'Prince Edward Island'},
  {value: 'Quebec', label: 'Quebec'},
  {value: 'Saskatchewan', label: 'Saskatchewan'},
  {value: 'Yukon', label: 'Yukon'},
]

export function MailingInfo(props) {
  const {formik, customStyling} = props
  const {translate} = useTranslation()
  const {customTxtClass} = useVoterStyles()

  const [sameAddress, setSameAddress] = useState(false)

  // // Same address --- on change
  // useEffect(() => {
  //   formik.values.mailingAddress = formik.values.streetAddress
  // }, [sameAddress && formik.values.streetAddress])

  // useEffect(() => {
  //   formik.values.mailingCity = formik.values.city
  // }, [sameAddress && formik.values.city])

  // useEffect(() => {
  //   formik.values.mailingProvince = formik.values.province
  // }, [sameAddress && formik.values.province])

  // useEffect(() => {
  //   formik.values.mailingPostalCode = formik.values.postalCode
  // }, [sameAddress && formik.values.postalCode])

  // useEffect(() => {
  //   formik.values.mailingUnitNumber = formik.values.unitNumber
  // }, [sameAddress && formik.values.unitNumber])

  // // Same address change once clicked
  // const sameAddressChange = () => {
  //   formik.values.mailingAddress = formik.values.streetAddress
  //   formik.values.mailingCity = formik.values.city
  //   formik.values.mailingProvince = formik.values.province
  //   formik.values.mailingPostalCode = formik.values.postalCode
  //   formik.values.mailingUnitNumber = formik.values.unitNumber
  // }

  const [acceptPropertyTerms, setAcceptPropertyTerms] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const handleQuestion = (option) => {
    setSelectedOption(option)

    // Handle logic for the selected option
    if (option === 'Yes') {
      console.log('User selected Yes')
      formik.values.propertyTerms = true
      // Add more logic for the "Yes" scenario
    } else if (option === 'No') {
      console.log('User selected No')
      formik.values.propertyTerms = false
      // Add more logic for the "No" scenario
    }
  }

  const autofillAddress = (address) => {
    // Extract province from structured formatting
    const {structured_formatting} = address.value
    const {secondary_text} = structured_formatting

    // Split the secondary_text to get city, province, and postal code
    const [, provinceCode, postalCode] = secondary_text.split(', ')

    // Extract city from the first part of secondary_text
    const city = secondary_text.split(',')[0].trim()
    // Map the province code to the full name
    const provinceName = mapProvinceCodeToName(provinceCode)

    // Clear errors for the relevant field
    formik.setFieldError('mailingAddress', '')

    // Use setTimeout to ensure correct order of state updates
    setTimeout(() => {
      // Update your formik values or perform any other actions here
      formik.setFieldValue('mailingCity', city)
      formik.setFieldValue('mailingProvince', provinceName)
      formik.setFieldValue('mailingPostalCode', postalCode)
    }, 0)
  }

  useEffect(() => {
    console.log('formik.values.propertyTerms', formik.values.propertyTerms)

    // handle state change when clicked back to step 1
    if (formik.values.propertyTerms) {
      setSelectedOption('Yes')
    }

    if (!formik.values.propertyTerms && formik.values.propertyTerms !== null) {
      setSelectedOption('No')
    }
  }, [formik.values.propertyTerms])

  return (
    <div className='voterRegister__addressRow' style={customStyling}>
      <div className='voterRegister__divider'>
        <span
          className={
            customTxtClass
              ? 'voterRegister__dividerLabel' + ' ' + customTxtClass
              : 'voterRegister__dividerLabel'
          }
        >
          Mailing Address
        </span>
      </div>

      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          <PlacesAutocomplete
            view='voter'
            formik={formik}
            type='text'
            label={translate('Street Address')}
            isRequired={true}
            types={['address']}
            hideErrorLabel
            mailing={true}
          />

          {formik.touched['mailingAddress'] && formik.errors['mailingAddress'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['mailingAddress']}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='mailingUnitNumber'
            placeholder='Unit Number'
            label='Unit #/P.O.Box'
            view='voter'
          />
        </div>
      </div>
      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='mailingCity'
              placeholder='City'
              label='City'
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <TextField
              formik={formik}
              type='text'
              field='mailingProvince'
              placeholder='Province'
              label='State/Province'
              isRequired={true}
              options={provinces}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='mailingPostalCode'
              placeholder='Postal Code'
              label='Zip/Postal Code'
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.touched['mailingCity'] && formik.errors['mailingCity'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['mailingCity']}
              </span>
            </div>
          </div>
        )}
        {formik.touched['mailingProvince'] && formik.errors['mailingProvince'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['mailingProvince']}
              </span>
            </div>
          </div>
        )}
        {formik.touched['mailingPostalCode'] && formik.errors['mailingPostalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['mailingPostalCode']}
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Checkbox section */}
      {/* <div className='addressInfo__question'>
        <span className='addressInfo__question__label'>
          Have you moved to or purchased property in Ignace since November 2023?
        </span>
        <div className='addressInfo__question__options'>
          <CheckBox
            view='CircleCheck'
            checked={selectedOption === 'Yes'}
            onChange={() => handleQuestion('Yes')}
            error={null}
            label='Yes'
          />
          <CheckBox
            view='CircleCheck'
            checked={selectedOption === 'No'}
            onChange={() => handleQuestion('No')}
            error={null}
            label='No'
          />
        </div>
      </div> */}

      {formik.errors['propertyTerms'] && (
        <div className='voterRegister__singleInputErr'>
          <div className='TextField__voterErrWrap'>
            <span className='TextField__voterErrTxt' role='alert'>
              You must select an option.
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
