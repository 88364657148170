/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import './MEXICO__SuperAdmin__Events.scss'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {EventInfo} from './components/EventInfo'
import AdminVoterService from '../../../../../services/admin/AdminVoterService'
import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import KeycloakAdminService from '../../../../../../KeycloakAdminService'
import {Redirect} from 'react-router-dom'
import {beforeUnload} from '../../../../../App'
import {useTranslation} from '../../../../../hooks/useTranslation'

export function MEXICO__SuperAdmin__Events(props) {
  const {translate, translateObj} = useTranslation()
  const [detailedView, setDetailedView] = useState(false)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [events, setEvents] = useState([])
  const {tiviLink} = useSuperAdminUserContext()
  const contactInfoRole = KeycloakAdminService.hasRole('tivi-contactinfo')

  useEffect(() => {
    const getEvents = async () => {
      let resp = await AdminVoterService.syncEvents()
      if (resp?.events) {
        //@ts-ignore
        setEvents(resp.events)
      }
    }
    getEvents()
  }, [])

  if (contactInfoRole) {
    return <Redirect to='/admin/electors-list' />
  }
  return (
    <div className='SuperAdmin__Events'>
      <div className='superAdmin__events__header'>
        {detailedView ? (
          <>
            <span
              className='common__backBtn'
              onClick={() => {
                setDetailedView(false)
              }}
            >
              {`< ${translate('Back')}`}
            </span>
            <span className='superAdmin__events__header__txt'>{translate('Event Details')}</span>
          </>
        ) : (
          <div style={{display: 'flex'}}>
            <span className='superAdmin__events__header__txt'>{translate('Events')}</span>
            <div className='adminUsers__eventComponent__btnOuter'>
              <div
                className='adminUsers__eventComponent__btn'
                onClick={() => {
                  window.removeEventListener('beforeunload', beforeUnload)
                  window.location.href = tiviLink
                }}
              >
                <img
                  style={{paddingRight: 2}}
                  src={toAbsoluteUrl('/media/design/icon_black_gear.svg')}
                  alt='settings'
                />
                <span className='adminUsers__eventComponent__btn__txt'>
                  {translate('Event Configuration Component')}{' '}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* no events */}
      {/* <div className='superAdmin__events__content'>
        <div className='superAdmin__events__content__noEvent'>
          <img
            className='superAdmin__events__content__noEvent__icon'
            src={toAbsoluteUrl('/media/design/icon_pink_x_calendar.svg')}
          />

          <span className='superAdmin__events__content__noEvent__txt'>
            Currently there is no events been created yet.
          </span>
        </div>
      </div> */}

      {/* list of events */}

      {!detailedView ? (
        <div className='superAdmin__events__list'>
          {events.map((event) => (
            <div
              className='superAdmin__events__list__card'
              onClick={() => {
                setDetailedView(true)
                setCurrentEvent(event)
              }}
            >
              <div className='superAdmin__events__list__card__titleWrap'>
                <img
                  className='superAdmin__events__list__card__icon'
                  src={toAbsoluteUrl('/media/design/icon_pink_calendar_check.svg')}
                />
                <span className='superAdmin__events__list__card__txt'>
                  {translateObj(event.name)}
                </span>
              </div>

              <span className='superAdmin__events__list__card__link'>{translate('Details')}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className='superAdmin__eventDetail'>
          <div className='superAdmin__eventDetail__header'>
            <div className='superAdmin__dashboard__headerIconWrap'>
              <img className='' src={toAbsoluteUrl('/media/design/icon_pink_calendar_check.svg')} />
              <span className='superAdmin__events__list__card__txt'>
                {translateObj(currentEvent.name)}
              </span>
            </div>

            <div />
            {/* <img className='' src={toAbsoluteUrl('/media/design/icon_mexico_voting.svg')} /> */}
          </div>
          {/* <div className='superAdmin__eventDetail__content'>
            <div className='adminDash__statCard adminDash__regVotersCard'>
              <img
                className='adminDash__statCardIcon'
                src={toAbsoluteUrl('/media/design/admin_reg_voters_icon.svg')}
                alt='pending approve icon'
              />
              <span className='adminDash__statCardValue '>{voterStats.numRegistered}</span>
              <span className='adminDash__statCardLabel'>
                {translate('Registered Voters')}
              </span>
            </div>

            <div className='adminDash__statCard adminDash__successVotersCard adminDash__successVotersCard--eventDetail'>
              <img
                className='adminDash__statCardIcon'
                src={toAbsoluteUrl('/media/design/admin_success_icon.svg')}
                alt='pending approve icon'
              />
              <span className='adminDash__statCardValue'>{voterStats.numVoted}</span>
              <span className='adminDash__statCardLabel'>
                {translate('Voted Successfully')}
              </span>
            </div>
          </div> */}

          <EventInfo event={currentEvent} updateEvent={setCurrentEvent} />
        </div>
      )}
    </div>
  )
}
