/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'

import * as Yup from 'yup'
import AdminVoterAuthService from '../../../../../../services/auth/AdminVoterAuthService'

import {UsersListTable} from './users_list_table/UsersListTable'

export function UsersList(props) {
  let {refreshList, setRefreshList} = props

  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  const fetchUsers = async () => {
    let resp = await AdminVoterAuthService.superAdminFetchAdminUsers()
    if (resp.success) {
      // @ts-ignore
      setUsers(resp.data)
      setRefreshList(false)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [refreshList])

  return (
    <>
      <div className='UsersList' id='Voter'>
        <UsersListTable users={users} fetchUsers={fetchUsers} />
      </div>
    </>
  )
}
