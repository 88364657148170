import {useContext, useState, useEffect} from 'react'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import BackgroundBanner from '../../../reusable/background_banner/BackgroundBanner'
import './VoterRegisterFollowUp.scss'
import {ModalWrapper} from '../../reusable/modals/modal_wrapper/ModalWrapper'
import {FollowUpStep1} from './step1/FollowUpStep1'
import {FollowUpStep2} from './step2/FollowUpStep2'

import {FollowUpQuestionaireModal} from './questionaire_modal/FollowUpQuestionaireModal'

export const VoterRegisterFollowUp = (props) => {
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const [selectedOption, setSelectedOption] = useState(null)
  const [currentStep, setCurrentStep] = useState(1)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalView, setModalView] = useState('thank-you')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderView = () => {
    if (currentStep == 1) {
      return (
        <FollowUpStep1
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setCurrentStep={setCurrentStep}
        />
      )
    }
    if (currentStep == 2) {
      return (
        <FollowUpStep2
          setModalOpen={setModalOpen}
          setModalView={setModalView}
          selectedOption={selectedOption}
          setCurrentStep={setCurrentStep}
        />
      )
    }
  }

  return (
    <div className='' id='Voter'>
      <FollowUpQuestionaireModal
        modalOpen={modalOpen}
        modalView={modalView}
        setModalView={setModalView}
        setModalOpen={setModalOpen}
      />

      <div className='voterRegister__formWrapper'>
        <BackgroundBanner />

        <div
          className={
            LANGUAGE_CONTEXT.currentLanguage !== 'English'
              ? 'authMethodLogin__form authMethod__form--spanish'
              : 'phoneVerify__form'
          }
        >
          {renderView()}
        </div>
      </div>
    </div>
  )
}
