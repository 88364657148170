/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

import '@djthoms/pretty-checkbox'
import TextField from '../../../../../modules/auth/reusable/TextField'

import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'

import AdminVoterAuthService from '../../../../../services/auth/AdminVoterAuthService'

import {VoterLoginFlowContext} from '../../../../../contexts/VoterLoginFlowContext'

import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import {VoterOnboardingContext} from '../../../../../contexts/VoterOnboardingContext'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useTranslation} from '../../../../../hooks/useTranslation'
import RecapatchaDisclaimer from '../../../../../resuable/recapatcha/RecapatchaDisclaimer'

const initialValues = {
  username: '',
  password: '',
}

export function MEXICO__AdminLogin() {
  const {translate} = useTranslation()

  const history = useHistory()
  const location = useLocation()

  const {executeRecaptcha} = useGoogleReCaptcha()

  const [loading, setLoading] = useState(false)

  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()
  const [error, setError] = useState(false)

  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)
  const {setAdminUser} = useSuperAdminUserContext()
  const {authMethod, setAuthMethod} = useContext(VoterOnboardingContext)

  const [errorMessage, setErrorMessage] = useState('')

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(translate('Username is required')),
    password: Yup.string().required(translate('Password is required')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const userInput = {
            username: values.username,
            password: values.password,
          }
          if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return
          }
          const token = await executeRecaptcha('admin_login')

          let redirect = false
          if (location.pathname.includes('redirect')) {
            redirect = true
          }
          sessionStorage.setItem('redirect', redirect ? 'true' : '')
          let resp = await AdminVoterAuthService.login(
            userInput.username,
            userInput.password,
            token
          )
          // console.log('userInput', userInput)

          if (resp?.success) {
            if (resp.user) {
              setAdminUser(resp.user)
            }
            if (resp.onboarding) {
              VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Onboarding')
              setAuthMethod('SMS')
              history.push('/admin/auth/verify')
            } else if (resp.passwordResetRequired) {
              setAuthMethod('Authapp')
              VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Forgot Password')
              history.push('/admin/auth/verify')
            } else {
              setAuthMethod('Authapp')
              VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Login')
              history.push('/admin/auth/verify')
            }

            setLoading(false)
            setError(false)
          } else {
            setError(true)
            // Invalid user credentials
            // session error: only one session per user
            console.error(resp.error?.message)
            switch (resp.error?.message) {
              case 'Invalid user credentials':
                setErrorMessage(translate('Invalid password or username'))
                break
              case 'session error: only one session per user':
                setErrorMessage(
                  translate(
                    'Only one session is allowed per user, please log out of your other session to continue'
                  )
                )
                break
              default:
                setErrorMessage(translate('Authentication failed'))
                break
            }
          }

          setLoading(false)
        } catch (error) {
          console.log(error)
          setError(true)
        }
      }, 10)
    },
  })

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('')
    }
  }, [formik.values.password])

  return (
    <div className='VerifyMethod' id='Voter'>
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div className='common__form userLogin__form'>
          {/* BODY */}
          <span className='userLogin__form__header__welcomeTxt'>{translate('Login')}</span>
          {/* Password section */}
          <form
            className='userLogin__form__body'
            onSubmit={(e) => {
              e.preventDefault()
              formik.submitForm()
            }}
          >
            <div>
              <div className='mb-2'>
                <TextField
                  formik={formik}
                  type='text'
                  field='username'
                  placeholder={translate('Username')}
                  label={translate('Username')}
                  isRequired={true}
                  view='voter'
                />
              </div>

              {formik.touched['username'] && formik.errors['username'] && (
                <div className='TextField__voterErrWrap'>
                  <span className='TextField__voterErrTxt' role='alert'>
                    {formik.errors['username']}
                  </span>
                </div>
              )}

              <div className='createPassword__form__body__password__confirmInput'>
                <div className='mb-2'>
                  <TextField
                    formik={formik}
                    type='password'
                    field='password'
                    placeholder={translate('Password')}
                    label={translate('Password')}
                    isRequired={true}
                    view='voter'
                  />
                </div>
                <span className='userLogin__form__body__inputBottomTxt'>
                  {translate('Forgot password')}
                  {'? '}
                  <span
                    className='common__link userLogin__form__body__inputBottomLink'
                    onClick={() => {
                      history.push('/admin/auth/reset-password')
                    }}
                  >
                    {translate('click here')}
                  </span>
                </span>

                {formik.touched['password'] && formik.errors['password'] && (
                  <div className='TextField__voterErrWrap'>
                    <span className='TextField__voterErrTxt' role='alert'>
                      {formik.errors['password']}
                    </span>
                  </div>
                )}
              </div>
            </div>

            {error && (
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {errorMessage}
                </span>
              </div>
            )}
          </form>

          {/* FOOTER  */}
          <div className='userLogin__form__footer'>
            <button
              type='button'
              onClick={() => {
                formik.submitForm()
              }}
              className='userLogin__form__footer__btn'
            >
              {translate('Next')}
            </button>
          </div>
        </div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt400' view='mobile' />
    </div>
  )
}
