import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import ROUTE_PATHS from '../../../routing/RoutePaths'

import {useVoterStyles} from '../../../contexts/VoterStylesContext'

import '../pages/VoterRegister.scss'

import {AdminDropdown} from '../../voter_admin/reusable/admin_dropdown/AdminDropdown'
import './VoterNavbar.scss'

export function VoterNavbar(props) {
  const {customLogoImg} = useVoterStyles()

  return (
    <div className='voter__navbar'>
      <img src={toAbsoluteUrl(customLogoImg)} alt='' />

      <div className='voterNavbar__rightContainer'>
        {/* <div className='voterNavbar__right__dropdown'> */}
        <AdminDropdown view='languages' customStyling={{width: 120}} />
        {/* </div> */}
        {/* <a href={ROUTE_PATHS.HOME} className='voter__navbarBackLink'>
          {'<'} Back to <span className='civik-blue'>CiviK</span>
        </a>{' '} */}
      </div>
    </div>
  )
}
