import {useState, useContext} from 'react'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import './ModalFooter.scss'

import {useTranslation} from '../../../../../hooks/useTranslation'

export function ModalFooter(props) {
  const {translate} = useTranslation()
  const [loading, setLoading] = useState(false)
  const {customBtnClass} = useVoterStyles()

  return (
    <div className='ModalFooter'>
      <button
        type='submit'
        className={
          customBtnClass
            ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
            : 'modalFooter__primaryBtn'
        }
        onClick={props.onRequestClose}
      >
        {translate('Ok')}
      </button>
    </div>
  )
}
