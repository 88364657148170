import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import ROUTE_PATHS from '../../../routing/RoutePaths'

import {useVoterStyles} from '../../../contexts/VoterStylesContext'

import '../pages/VoterRegister.scss'

import {AdminDropdown} from '../../voter_admin/reusable/admin_dropdown/AdminDropdown'
import './VoterNavbar.scss'

export function VoterNavbarMobile(props) {
  const {customMobileLogoImg} = useVoterStyles()

  return (
    <div className='voter__navbar VoterNavbarMobile'>
      <img className='voterNavbarMobile__logo' src={customMobileLogoImg} alt='Elections Logo' />

      <div className='voterNavbarMobile__dropdownWrap'>
        <AdminDropdown view='languages' customStyling={{width: 120, height: 40, fontSize: 12}} />
      </div>
    </div>
  )
}
