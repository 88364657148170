/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

// import React, {FC} from 'react'
import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'

import {SuperAdminUserProvider, useSuperAdminUserContext} from '../contexts/SuperAdminUserContext'
import KeycloakAdminService from '../../KeycloakAdminService'
import AdminVoterAuthService from '../services/auth/AdminVoterAuthService'
import AdminVoterService from '../services/admin/AdminVoterService'
import useQuery from '../routing/UseQuery'
import {LanguageContext} from '../contexts/LanguageContext'

import {languageKeyToValue} from '../languages/languagekey'
import {beforeUnload} from '../App'

export const useAdminKeycloakAuth = ({setAdminView}) => {
  const [initialized, setInitialized] = useState(false)
  const {adminUser, setAdminUser, setTiviLink} = useSuperAdminUserContext()
  const {currentLanguage, setCurrentLangauge} = useContext(LanguageContext)

  const history = useHistory()
  const query = useQuery()

  const setLoggedInLocal = async () => {
    setAdminView(true)

    if (
      window.location.pathname.startsWith('/admin') &&
      (window.location.pathname.includes('login') ||
        window.location.pathname.includes('auth') ||
        (query?.get('session_state') && query?.get('session_state') !== ''))
    ) {
      if (KeycloakAdminService?.hasRole('tivi-contactinfo')) {
        history.push('/admin/electors-list')
      } else {
        history.push('/admin/dashboard')
      }
    }

    const parsedToken = KeycloakAdminService.getParsedToken()
    if (parsedToken) {
      setAdminUser({
        username: parsedToken.preferred_username,
        phone: parsedToken.phone_number,
        phoneNumber: parsedToken.phone_number,
        email: parsedToken.email,
      })
    }

    // get & persist language
    let savedLanguage = localStorage.getItem('language')
    if (savedLanguage) {
      setCurrentLangauge(savedLanguage)
      if (KeycloakAdminService.isLoggedIn()) {
        AdminVoterService.updateUserLanguage(currentLanguage)
      }
    } else if (parsedToken.locale) {
      setCurrentLangauge(languageKeyToValue[parsedToken.locale])
      localStorage.setItem('language', languageKeyToValue[parsedToken.locale])
    }

    const tiviLink = await AdminVoterAuthService.getTiviElectionComponentLink()
    if (
      tiviLink &&
      KeycloakAdminService?.isLoggedIn() &&
      !KeycloakAdminService?.hasRole('tivi-contactinfo')
    ) {
      setTiviLink(tiviLink)
    }
    window.addEventListener('beforeunload', beforeUnload)
  }

  const clearLocalSession = () => {
    window.removeEventListener('beforeunload', beforeUnload)

    setAdminView(false)
    if (
      window.location.pathname.startsWith('/admin') &&
      !window.location.pathname.includes('login') &&
      !window.location.pathname.includes('reset-password')
    ) {
      history.push('/admin/login')
      setAdminUser({})
    }
  }

  const checkAuth = async () => {
    // is logged in
    if (initialized && KeycloakAdminService?.isLoggedIn()) {
      setLoggedInLocal()
    }
    if (initialized && !KeycloakAdminService?.isLoggedIn()) {
      clearLocalSession()
    }
  }

  useEffect(() => {
    const initializeSSO = async () => {
      if (!initialized) {
        KeycloakAdminService.initKeycloak(() => {
          setInitialized(true)
        })
      }
      // if keycloak initialization times out in error
      setTimeout(() => {
        setInitialized(true)
      }, 5000)
    }
    initializeSSO()
  }, [])

  useEffect(() => {
    checkAuth()
  }, [initialized, KeycloakAdminService?.isLoggedIn()])
}
