import React, {useState, useCallback, useEffect} from 'react'

import './PdfViewer.scss'
// import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import {useResizeObserver} from '@wojtekmaj/react-hooks'
import {pdfjs, Document, Page} from 'react-pdf'
import type {PDFDocumentProxy} from 'pdfjs-dist'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
}

const resizeObserverOptions = {}

const maxWidth = 800

const PDFViewer = ({pdfViewURL}) => {
  useEffect(() => {
    console.log('pdfViewURL', pdfViewURL)
  }, [])

  const [numPages, setNumPages] = useState<number>()
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const [containerWidth, setContainerWidth] = useState<number>()

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries

    if (entry) {
      setContainerWidth(entry.contentRect.width)
    }
  }, [])

  useResizeObserver(containerRef, resizeObserverOptions, onResize)

  function onDocumentLoadSuccess({numPages: nextNumPages}: PDFDocumentProxy): void {
    setNumPages(nextNumPages)
  }
  return (
    <div className='Example__container__document' ref={setContainerRef}>
      <Document file={pdfViewURL} onLoadSuccess={onDocumentLoadSuccess} options={options}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </div>
  )
}

export default PDFViewer
