/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

// import React, {FC} from 'react'
import {useState, useEffect, useContext} from 'react'
import {Switch, Route, useHistory, Redirect} from 'react-router-dom'
import {VoterAdminRoutes} from './VoterAdminRoutes'
import {MasterInit} from '../../_metronic/layout/MasterInit'
// import AUTH_SERVICE from '../services/auth/AuthService'
import {VoterRegister} from '../pages/voter/pages/VoterRegister'
import {AdminSidebar} from '../pages/voter_admin/reusable/admin_sidebar/AdminSidebar'
import {AdminTopbar} from '../pages/voter_admin/reusable/admin_topbar/AdminTopbar'

import {VoterAuthContext} from '../contexts/VoterAuthContext'

import {VoterStylesProvider, useVoterStyles} from '../contexts/VoterStylesContext'

// ONBOARDING SCREENS
import {MEXICO__PhoneVerify} from '../pages/voter_admin/pages/mexico/onboarding/MEXICO__PhoneVerify'
import {MEXICO__AuthMethod} from '../pages/voter_admin/pages/mexico/onboarding/MEXICO__AuthMethod'
import {MEXICO__AuthMethodLogin} from '../pages/voter_admin/pages/mexico/onboarding/MEXICO__AuthMethodLogin'

import {MEXICO__AuthAppSetup} from '../pages/voter_admin/pages/mexico/onboarding/MEXICO__AuthAppSetup'
import {MEXICO__AuthVerify} from '../pages/voter_admin/pages/mexico/onboarding/MEXICO__AuthVerify'
import {MEXICO__CreatePassword} from '../pages/voter_admin/pages/mexico/onboarding/MEXICO__CreatePassword'
// LOGIN SCREENS
import {MEXICO__UserLogin} from '../pages/voter_admin/pages/mexico/user_login/MEXICO__UserLogin'

import {VoterOnboardingContext} from '../contexts/VoterOnboardingContext'
import {VoterUserContext} from '../contexts/VoterUserContext'
import KeycloakService from '../../Keycloak'
import {AdminNavbarMobile} from '../pages/voter_admin/reusable/admin_navbar_mobile/AdminNavbarMobile'
import {AdminSidebarMobile} from '../pages/voter_admin/reusable/admin_sidebar_mobile/AdminSidebarMobile'
import {MEXICO__ResetPassword} from '../pages/voter_admin/pages/mexico/reset_password/MEXICO__ResetPassword'
import {VoterLoginFlowContext} from '../contexts/VoterLoginFlowContext'
import {VoterNavbar} from '../pages/voter/reusable/VoterNavbar'
import {VoterNavbarMobile} from '../pages/voter/reusable/VoterNavbarMobile'
import VoterAuthService from '../services/auth/VoterAuthService'
import {languageKeyToValue} from '../languages/languagekey'
import {VoterRoutes} from './VoterRoutes'
import {useVoterKeyCloakAuth} from '../hooks/useVoterKeycloakAuth'

const VoterRegRoutes = () => {
  const [adminView, setAdminView] = useState(false)
  const [user, setUser] = useState({votingChannel: '', username: ''})

  const [loginFlow, setLoginFlow] = useState('Login')

  useVoterKeyCloakAuth({adminView, setAdminView, user, setUser})
  return (
    <div style={{maxWidth: '100%', display: 'flex', width: '100%', flexFlow: 'column'}}>
      <VoterAuthContext.Provider value={{adminView, setAdminView}}>
        <VoterLoginFlowContext.Provider value={{loginFlow, setLoginFlow}}>
          <VoterUserContext.Provider value={{user, setUser}}>
            <VoterRoutes adminView={adminView} />
          </VoterUserContext.Provider>
        </VoterLoginFlowContext.Provider>
      </VoterAuthContext.Provider>
    </div>
  )
}

export {VoterRegRoutes}
