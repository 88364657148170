import {useContext, useEffect, useState} from 'react'
import KeycloakServiceVoter from '../../Keycloak'
import KeycloakAdminService from '../../KeycloakAdminService'

import VoterAuthService from '../services/auth/VoterAuthService'
import AdminVoterAuthService from '../services/auth/AdminVoterAuthService'
import {useHistory} from 'react-router-dom'
import {VoterLoginFlowContext} from '../contexts/VoterLoginFlowContext'
import {beforeUnload} from '../App'

const AUTH_CHECK_INTERVAL = 30000

export function useKeycloakSession() {
  const history = useHistory()
  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)

  const admin = window.location.pathname?.startsWith('/admin') ? true : false
  const KeycloakService = admin ? KeycloakAdminService : KeycloakServiceVoter
  //@ts-ignore
  let flow = history?.location?.state?.flow

  const onSessionExpired = async () => {
    try {
      window.removeEventListener('beforeunload', beforeUnload)
      if (!admin) {
        await VoterAuthService.logOut(history)
        VOTER_LOGIN_CONTEXT.setLoginFlow('Login')
      } else {
        await AdminVoterAuthService.logOut(history)
        VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Login')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // for unauthenticated change password page
      KeycloakService.onTokenExpired(onSessionExpired, AUTH_CHECK_INTERVAL / 1000)
    }, AUTH_CHECK_INTERVAL)

    return () => clearInterval(interval)
  }, [])
}
