import './ParticipantContactInfo.scss'
export const ParticipantContactInfo = ({dob, phone, email}) => {
  return (
    <div className='ParticipantContactInfo'>
      <div className='participantContactInfo__dob'>
        <span className='participantContactInfo__dob__label'>Date of Birth</span>
        <span className='participantContactInfo__dob__value'>{dob}</span>
      </div>

      <div className='participantContactInfo__phone'>
        <span className='participantContactInfo__phone__label'>Phone Number:</span>
        <span className='participantContactInfo__phone__value'>{phone}</span>
      </div>

      <div className='participantContactInfo__email'>
        <span className='participantContactInfo__email__label'>Email Address</span>
        <span className='participantContactInfo__email__value'>{email}</span>
      </div>
    </div>
  )
}
