/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import './MEXICO__PhoneVerify.scss'
import '../../../../voter/pages/ConfirmCode.scss'
import '../../../../voter/pages/VerifyMethod.scss'

import './MEXICO__AuthMethod.scss'
import './Common.scss'
import '@djthoms/pretty-checkbox'

import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'

import {ProgressBar} from './reusable/ProgressBar'
import {VoterOnboardingContext} from '../../../../../contexts/VoterOnboardingContext'
import {VoterLoginFlowContext} from '../../../../../contexts/VoterLoginFlowContext'
import {VoterUserContext} from '../../../../../contexts/VoterUserContext'
import OnlineVoterAuthService from '../../../../../services/auth/OnlineVoterAuthService'

import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {ProgressBarMobile} from './reusable/ProgressBarMobile'
import {useTranslation} from '../../../../../hooks/useTranslation'
import RecapatchaDisclaimer from '../../../../../resuable/recapatcha/RecapatchaDisclaimer'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export function MEXICO__AuthMethod(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()
  const {authMethod, setAuthMethod} = useContext(VoterOnboardingContext)
  const [activeSelection, setActiveSelection] = useState(null)
  const [notValid, setNotValid] = useState(true)
  const VoterUser = useContext(VoterUserContext)

  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)

  const {currentLanguage} = useContext(LanguageContext)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneErr, setPhoneErr] = useState(false)
  const [phoneErrMsg, setPhoneErrMsg] = useState('Please enter a valid phone number')

  const handlePhoneNumberChange = (newPhoneNumber) => {
    setPhoneErr(false)
    setPhoneErrMsg('Please enter a valid phone number')
    // Handle the phone number change by updating the state
    setPhoneNumber(newPhoneNumber)
  }

  const onSubmit = async () => {
    try {
      // Validation for phonenumber

      // continue with verification
      if (authMethod === 'AuthApp') {
        // forward to that screen
        let sent = await OnlineVoterAuthService.getAuthAppQRCode()
        if (sent.success) {
          history.push('/voting/voter-onboarding/auth-app-setup', {
            qrCodeDataUrl: sent.qrCodeDataUrl,
          })
        }
      } else if (authMethod === 'Email') {
        let sent = await OnlineVoterAuthService.sendEmailCode()
        if (sent.success) {
          history.push('/voting/voter-onboarding/auth-verify')
        }
      } else {
        let sent = await OnlineVoterAuthService.sendSMSCode(currentLanguage)
        if (sent.success) {
          history.push('/voting/voter-onboarding/auth-verify')
        } else {
          setPhoneErr(true)
          setPhoneErrMsg('Something went wrong sending your SMS code.')
          return
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // console.log('props', props.history.location.state)
    // setFlow(props.history.location.state)
    // disabled button handler
    if (activeSelection) {
      setNotValid(false)
    }
  }, [activeSelection])

  return (
    <div className='VerifyMethod' id='Voter'>
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div
          className={
            currentLanguage == 'English'
              ? VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding'
                ? 'authMethod__form'
                : 'authMethodLogin__form'
              : //ELSE, SPANISH
              VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding'
              ? 'authMethodLogin__form authMethod__form--spanish'
              : 'authMethodLogin__form authMethodLogin__form--spanish'
          }
        >
          {VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding' && <ProgressBar currentStep={2} />}
          {VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding' && <ProgressBarMobile currentStep={2} />}

          {/*  */}
          {/* PHONE INFO SECTION */}
          {/* <div className='authMethod__form__body'>
          <div className='authMethod__form__body__phoneWrap'>
              <div className='authMethod__form__body__phoneWrap__txtWrap'>
                <span className='authMethod__form__body__phoneWrap__txtWrap__label'>
                  {translate('Phone Number:')}
                </span>
                <span className='authMethod__form__body__phoneWrap__txtWrap__value'>
                  {VoterUser?.user?.phone}
                </span>
              </div>

              <span className='authMethod__form__body__phoneWrap__disclaimer'>
                {translate('Not your’s?')}{' '}
                <span className='common__link'>{translate('Click here')}</span>
              </span>
            </div>
          </div> */}

          {/* USERNAME INFO SECTION */}
          <div className='authMethod__form__body authMethod__form__body__usernameWrap'>
            <div className='authMethod__form__body__usernameWrap__txtWrap'>
              <span className='authMethod__form__body__usernameWrap__txtWrap__label'>
                {translate('Your username')}:
              </span>
              <span className='authMethod__form__body__usernameWrap__txtWrap__value'>
                {VoterUser?.user?.username}
              </span>
            </div>

            <div className='authMethod__form__body__usernameWrap__banner'>
              <img className='' src={toAbsoluteUrl('/media/design/pink_hand.svg')} />
              <span className='authMethod__form__body__usernameWrap__banner__txt'>
                {translate(
                  'you will be asked for this username every time you login to the user dashboard, please write it down and keep in safe place'
                )}
              </span>
            </div>
          </div>

          {/* AUTH METHOD SECTION */}
          <div className='authMethod__form__body__methodWrap'>
            <div className='authMethod__form__body__methodWrap__txtWrap'>
              <span className='authMethod__form__body__methodWrap__txtWrap__label'>
                {translate('Choose an authentication method')}
              </span>
            </div>

            <div className='authMethod__form__body__methodWrap__selectWrap'>
              <div
                className='common__radioBtnContainer authMethod__form__body__methodWrap__select--sms'
                onClick={() => {
                  setAuthMethod('SMS')
                  setActiveSelection('SMS')
                }}
              >
                <div
                  className={
                    activeSelection == 'SMS' ? 'common__radioBtnActive' : 'common__radioBtnDefault'
                  }
                ></div>
                <span className='authMethod__form__body__methodWrap__selectTxt'>
                  {translate('I want to login using an SMS code sent to my phone')}
                </span>
              </div>

              <div
                className='common__radioBtnContainer authMethod__form__body__methodWrap__select--sms'
                onClick={() => {
                  setAuthMethod('AuthApp')
                  setActiveSelection('AuthApp')
                }}
              >
                <div
                  className={
                    activeSelection == 'AuthApp'
                      ? 'common__radioBtnActive'
                      : 'common__radioBtnDefault'
                  }
                ></div>
                <span className='authMethod__form__body__methodWrap__selectTxt'>
                  {translate('I want to login using the Auth App installed on my phone')}
                </span>
              </div>

              <div
                className='common__radioBtnContainer'
                onClick={() => {
                  setAuthMethod('Email')
                  setActiveSelection('Email')
                }}
              >
                <div
                  className={
                    activeSelection == 'Email'
                      ? 'common__radioBtnActive'
                      : 'common__radioBtnDefault'
                  }
                ></div>
                <span className='authMethod__form__body__methodWrap__selectTxt'>
                  {translate('I want to login using a code sent to my registered email address')}
                </span>
              </div>
            </div>
          </div>

          {/* <div className='authMethod__phoneInputWrap'>
            <label className={'form-label fs-6 fw-bold text-dark required'}>
              {translate('Enter your cell phone number')}
            </label>

            <PhoneInput
              defaultCountry={'CA'}
              className={
                currentLanguage == 'English'
                  ? 'authMethod__phoneConatiner'
                  : 'authMethod__phoneConatiner authMethod__phoneConatiner--French'
              }
              placeholder={translate('Enter your cell phone number')}
              value={phoneNumber}
              onChange={handlePhoneNumberChange} // Use the wrapper function here
            />

            {phoneErr && (
              <div className='authMethod__phoneErr'>
                <span role='alert'>{translate(phoneErrMsg)}</span>
              </div>
            )}
          </div> */}
          {/* <div className='authMethod__phoneInputDisclaimer'>
            <span className='authMethod__phoneInputDisclaimer__txt'>
              {translate(
                'Please make sure to select the international country code first before entering the phone number.'
              )}
            </span>
          </div> */}

          {/* DISCLAIMER SECTION */}
          <div className='authMethod__form__body__disclaimBannerOuter'>
            <div className='authMethod__form__body__disclaimBanner'>
              <img className='' src={toAbsoluteUrl('/media/design/pink_security_icon.svg')} />
              <span className='authMethod__form__body__disclaimBanner__txt'>
                {translate(
                  "Two-factor authentication adds an extra layer of security to your account. To log-in you'll need to provide a 6 digit security code."
                )}
              </span>
            </div>
          </div>
          {/*  */}
          <div className='phoneVerify__form__actionBar'>
            <button
              className={
                notValid ? 'authMethodLogin__nextBtn disabled' : 'authMethodLogin__nextBtn'
              }
              onClick={onSubmit}
              disabled={notValid}
            >
              {translate('Next')}
            </button>
          </div>
        </div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt800' view='mobile' />
    </div>
  )
}
