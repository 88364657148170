/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'

import '@djthoms/pretty-checkbox'

import './EmailSent.scss'
import {useTranslation} from '../../../../../../hooks/useTranslation'

export function RequestSent(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const {setCurrentView} = props

  return (
    <div className='EmailSent'>
      <div className='emailSent__body'>
        <img
          className='emailSent__telegramIcon'
          src={toAbsoluteUrl('/media/design/icon_pink_telegram.svg')}
        />

        <span className='emailSent__title'>
          {translate('Reset password submitted successfully')}
        </span>
        <span className='emailSent__subtitle'>
          {translate(
            'A request has been made to reset your password. You will receive an email with a temporary password if it is approved.'
          )}
        </span>
      </div>
      <div className='phoneVerify__form__actionBar'>
        <button
          className={'authMethodLogin__nextBtn'}
          onClick={() => {
            history.push('/admin/login')
          }}
        >
          {translate('Next')}
        </button>
      </div>
    </div>
  )
}
