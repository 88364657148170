/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {UserInfo} from '../components/registration/UserInfo'
import {ContactInfo} from '../components/registration/ContactInfo'
import {AddressInfo} from '../components/registration/AddressInfo'
import {MailingInfo} from '../components/registration/MailingInfo'
import {UploadPhotoID} from '../components/registration/UploadPhotoID'
import {VoterNavbar} from '../reusable/VoterNavbar'
import './VoterRegister.scss'
import {ModalWrapper} from '../reusable/modals/modal_wrapper/ModalWrapper'
import '../reusable/modals/VoterRegModals.scss'
import {
  initialValues,
  step1Schema,
  step1SchemaWithSingleName,
  step2SchemaDefault,
  step2SchemaSecondary,
} from './VoterRegisterSchema'
import {useVoterStyles} from '../../../contexts/VoterStylesContext'
import VoterRegistrationService from '../services/VoterRegistrationService'
import {CustomBtn} from '../../reusable/custom_btn/CustomBtn'
import VoterRegisterHeader from '../components/layout/VoterRegisterHeader'

import Banner from '../../reusable/background_banner/BackgroundBanner'
import BackgroundBanner from '../../reusable/background_banner/BackgroundBanner'
import VoterRegisterFooter from '../components/layout/VoterRegisterFooter'
import {VoterRegisterStep1} from './step_1/VoterRegisterStep1'
import {VoterRegisterStep2} from './step_2/VoterRegisterStep2'
import {VoterRegistrationUserContext} from '../../../contexts/RegistrationUser'
import AdminVoterService from '../../../services/admin/AdminVoterService'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function VoterRegister(props) {
  const {view} = props

  const [currentStep, setCurrentStep] = useState(1)

  const [loading, setLoading] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [singleName, setSingleName] = useState(false)
  const [contactsTermsErr, setContactTermsError] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const [photoID, setPhotoID] = useState(null)
  const [isPhotoError, setIsPhotoError] = useState(false)
  const [isEmailError, setIsEmailError] = useState(false)
  const [hasSingleName, setHasSingleName] = useState(false)
  const RegisterUser = useContext(VoterRegistrationUserContext)
  // CURRENT SHCEMA --
  const [currentSchema, setCurrentSchema] = useState(step1Schema)

  const history = useHistory()

  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()

  const submitAdmin = async (formData) => {
    let result = await AdminVoterService.registerVoter(formData)
    if (result.success) {
      let data = result.data
      history.replace(`/admin/participants/${data.voterIdentifier}`)
    } else {
      alert('An error occurred')
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: currentSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          // if step 1 is succesful
          if (currentStep == 1) {
            console.log('step 1 success')
            setCurrentSchema(step2SchemaDefault as any)
            setCurrentStep(2)
          }

          if (currentStep == 2) {
            // console.log('photoID', photoID)
            // if (photoID == null) {
            //   console.log('ERROR: photo ID not uploaded!')
            //   setIsPhotoError(true)
            //   return
            // }

            const userInput = {
              // STEP 1 --------------------------------
              // USER INFO
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              singleName: values.singleName ? true : false,
              // BIRTH DAYE
              birthMonth: values.birthMonth,
              birthDay: values.birthDay,
              birthYear: values.birthYear,
              dateOfBirth: new Date(`${values.birthDay} ${values.birthMonth} ${values.birthYear}`),

              // CONTACT INFO
              email: values.email,
              confirmEmail: values.confirmEmail,
              phone: values.phoneNumber,

              // RECEIVE SMS
              receiveSMSNotif: values.receiveSMSNotif,

              // STEP 2 -------------------------------------------
              // RESIDENTIAL ADDRESS
              address: {
                streetAddress: values.streetAddress,
                city: values.city,
                province: values.province,
                postalCode: values.postalCode,
                unitNumberPOBox: values.unitNumber,
              },

              // MAILING ADDRESS
              mailingAddress: {
                streetAddress: values.mailingAddress,
                city: values.mailingCity,
                province: values.mailingProvince,
                postalCode: values.mailingPostalCode,
                unitNumberPOBox: values.mailingUnitNumber,
              },

              // Q/ TERMS: Have you moved to or purchased property in Ignace since November 2023?
              movedOrPurchacedThisYear: values.propertyTerms ? values.propertyTerms : false,

              // photoIDs: [values.photoID, values.photoID2],
            }
            // console.log(userInput)
            const formData = new FormData()
            for (var key in userInput) {
              if (key === 'address' || key === 'mailingAddress') {
                // append nested object
                for (let addressKey in userInput[key]) {
                  formData.append(`${key}[${addressKey}]`, userInput[key][addressKey])
                }
              } else {
                formData.set(key, userInput[key])
              }
            }

            formData.append('photoIDs', values.photoID)
            values.photosList = [values.photoID]
            if (values.photoID2) {
              formData.append('photoIDs', values.photoID2)
              values.photosList.push(values.photoID2)
            }

            if (window.location.pathname.includes('admin')) {
              // submit form admin
              submitAdmin(formData)
            } else {
              RegisterUser.setRegistrationUser({formData, user: userInput})
              history.push('/voting/register-complete')
            }

            setLoading(false)
          }
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  function afterOpenModal() {}

  function onAfterCompleteModal() {}

  function closeModal() {
    setIsOpen(false)
  }

  // useEffect(() => {
  //   if (photoID) {
  //     setIsPhotoError(false)
  //   }
  // }, [photoID])

  const renderView = () => {
    if (currentStep == 1) {
      return (
        <VoterRegisterStep1
          formik={formik}
          setCurrentStep={setCurrentStep}
          contactsTermsErr={contactsTermsErr}
          hasSingleName={hasSingleName}
          setHasSingleName={setHasSingleName}
          changeSchema={(newSchema) => {
            if (newSchema == 'step1') {
              console.log('step1')
              setCurrentSchema(step1Schema as any)
            }

            if (newSchema == 'step1--singleName') {
              console.log('step1--singleName')
              setCurrentSchema(step1SchemaWithSingleName as any)
            }
          }}
        />
      )
    }

    if (currentStep == 2) {
      return (
        <VoterRegisterStep2
          formik={formik}
          currentSchema={currentSchema}
          setCurrentStep={setCurrentStep}
          hasSingleName={hasSingleName}
          changeSchema={(newSchema) => {
            if (newSchema == 'step1') {
              console.log('step1')
              setCurrentSchema(step1Schema as any)
            }

            if (newSchema == 'step1--singleName') {
              console.log('step1--singleName')
              setCurrentSchema(step1SchemaWithSingleName as any)
            }

            if (newSchema == 'step2-default') {
              console.log('step2-default')
              setCurrentSchema(step2SchemaDefault as any)
            }

            if (newSchema == 'step2-full') {
              console.log('step2-full')
              setCurrentSchema(step2SchemaSecondary as any)
            }
          }}
        />
      )
    }
  }

  useEffect(() => {
    console.log('formik.errors', formik.errors)
  }, [formik.errors])

  return (
    <div className='VoterRegister' id='Voter'>
      {/* MODAL */}
      <ModalWrapper
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      />

      <div className='voterRegister__formWrapper'>
        <BackgroundBanner />

        <form
          className='voterRegister__form'
          onSubmit={(e) => {
            e.preventDefault()
            if (acceptTerms) {
              // submit
              formik.submitForm()
            } else {
              // setContactTermsError(true)
              // console.log('YOU must accept terms')
            }
          }}
        >
          <VoterRegisterHeader setModalView={setModalView} setIsOpen={setIsOpen} />

          {/* VIEW */}
          {renderView()}
        </form>
      </div>
    </div>
  )
}
