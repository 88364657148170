/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
// import SPANISH_TRANSLATION from './translations/Spanish.json'
import './MEXICO__Dashboard.scss'
import '../common.scss'

import {LanguageContext} from '../../../../../../contexts/LanguageContext'
import {EventDisplayCard} from './components/event_display_card/EventDisplayCard'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'

import {VoterUserContext} from '../../../../../../contexts/VoterUserContext'
import VoterAuthService from '../../../../../../services/auth/VoterAuthService'
import {beforeUnload} from '../../../../../../App'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {EventVoteCard} from './components/event_vote_card/EventVoteCard'

export function MEXICO__Dashboard() {
  const {translate} = useTranslation()

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const [currentView, setCurrentView] = useState('Display')
  const [currentEvent, setCurrentEvent] = useState(null)
  const VoterUser = useContext(VoterUserContext)
  const [elections, setElections] = useState(VoterUser.user?.elections || [])
  const location = useLocation()

  const fetchEvents = async () => {
    let updatedEvents = await VoterAuthService.getUserEvents()
    if (updatedEvents) {
      setElections(updatedEvents)
    }
  }
  useEffect(() => {
    fetchEvents()
    window.addEventListener('beforeunload', beforeUnload)
  }, [location, window.location.pathname])

  const EventDisplaySection = () => (
    <>
      <div className='dashboard__body'>
        {elections.length ? (
          elections.map((event) => {
            if (event) {
              return (
                <EventDisplayCard
                  key={event.id}
                  event={event}
                  title={event.name}
                  onChangeView={() => {
                    setCurrentView('Vote Now')
                    setCurrentEvent(event)
                  }}
                  openDate={event.openDate}
                  closeDate={event.closeDate}
                  hasVoted={VoterUser.user?.votedInEvents?.includes(event.tiviId)}
                />
              )
            }
          })
        ) : (
          <div className='superAdmin__events__content'>
            <div className='superAdmin__events__content__noEvent'>
              <img
                className='superAdmin__events__content__noEvent__icon'
                src={toAbsoluteUrl('/media/design/icon_pink_x_calendar.svg')}
              />

              <span className='superAdmin__events__content__noEvent__txt'>
                {translate('No events yet')}.
              </span>
            </div>
          </div>
        )}
      </div>
      {/* <div className='dashboard__disclaimerBanner'>
        <img src={toAbsoluteUrl('/media/design/icon_pink_voter_voting.svg')} />
        <span className='dashboard__disclaimerBanner__txt'>
          {translate(
            'For better understanding about the voting process and what to expect in the real actual voting, Feel free to try this demo voting event, in This event you will see a copy of the actual ballot you are going to use in the actual voting process.'
          )}
        </span>
      </div> */}
    </>
  )

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English'
          ? 'MEXICO__Dashboard--spanish'
          : 'MEXICO__Dashboard'
      }
    >
      <div className='common__pg__header '>
        {currentView == 'Vote Now' && (
          <span
            className='common__backBtn'
            onClick={() => {
              setCurrentView('Display')
            }}
          >
            {'< ' + translate('Back')}
          </span>
        )}
        <span className='common__pg__header__title'>{translate('Elections')}</span>

        {/* TODO: change to session timer */}
        {/* <div className='common__flex__row '>
          <span className='eventDisplayCard__body__content__vtClosed__label'>
            {translate('Voting will be closed in')}:
          </span>

          <span className='eventDisplayCard__body__content__vtClosed__value'>
            08 <span className='common__fw400'>hrs</span> 25{' '}
            <span className='common__fw400'>min</span> 24 <span className='common__fw400'>sec</span>
          </span>
        </div> */}
      </div>
      <span
        className={
          LANGUAGE_CONTEXT.currentLanguage !== 'English'
            ? 'commonHeaderM__title--spanish'
            : 'commonHeaderM__title'
        }
      >
        {' '}
        {translate('Elections')}
      </span>

      {
        currentView == 'Display' ? <EventDisplaySection /> : null
        // moved to own page
        // (
        //   <div className='dashboard__eventVoteCard'>
        //     <EventVoteCard
        //       event={currentEvent}
        //       hasVoted={VoterUser.user?.votedInEvents?.includes(currentEvent.tiviId)}
        //     />
        //   </div>
        // )
      }
    </div>
  )
}
