import {useEffect, useState} from 'react'
import './PhotoIdModal.scss'
import {ModalHeaderWithLabel} from '../../../../../../voter/components/layout/ModalHeaderWithLabel'
import {ModalStyling} from '../../../../../../voter/components/registration_follow_up/ModalStyling'
import Modal from 'react-modal'
import {CustomBtn} from '../../../../../../reusable/custom_btn/CustomBtn'
import {Spinner} from 'react-bootstrap-v5'
import AdminVoterService from '../../../../../../../services/admin/AdminVoterService'

export const QRCodeModal = ({modalOpen, setModalOpen, username}) => {
  const [QRCodeSRC, setQRCodeSRC] = useState('')

  const getQR = async () => {
    let result = await AdminVoterService.getAuthQR(username)
    if (result.success) {
      setQRCodeSRC(result.qrCodeDataUrl)
    }
  }
  useEffect(() => {
    if (modalOpen) {
      getQR()
    } else {
      setQRCodeSRC('')
    }
  }, [modalOpen])

  const View = (
    <>
      <div className='photoIdModal--view photoIdModal--view-qr'>
        <img className='photoIdModal--view__photoImg' alt='identification' src={QRCodeSRC} />
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='PrimaryBtn'
          text={'Close'}
          height={48}
          onClick={() => {
            setModalOpen(false)
          }}
        />
      </div>
    </>
  )

  const renderView = () => {
    if (QRCodeSRC) {
      return View
    } else {
      return (
        <div className='photoIdModal--view'>
          <Spinner size='large' animation='border' />
        </div>
      )
    }
  }

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => {
        setModalOpen(false)
      }}
      style={ModalStyling}
    >
      <div className='ModalWrapper' style={{flexGrow: 1, maxWidth: 925, width: 300}}>
        <ModalHeaderWithLabel label={'QR Code'} />

        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </Modal>
  )
}
