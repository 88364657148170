/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
import './ImportAddressList.scss'

import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ModalWrapper} from '../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'

import {ImportAddressListModal} from './modals/ImportAddressListModal'
import {useTranslation} from '../../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function ImportAddressList(props) {
  const {translate} = useTranslation()

  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')

  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false)

  function afterOpenModal() {}
  function onAfterCompleteModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div id='ImportAddressList'>
      {/* MODAL */}
      <ModalWrapper
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
        headerLabel={'Update Electors Database'}
      />

      <ImportAddressListModal
        isOpen={photoModalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setPhotoModalIsOpen(false)
        }}
        style={customStyles}
        contentLabel='Example Modal'
        setPostURL={(imgURL, path) => {
          console.log('imgURL', imgURL)
          // setPhotoURL(imgURL)
          // setPostURL({type: 'image', url: imgURL, path: path})
        }}
        setFileData={(image) => {
          // setPhotoID(image)
          console.log(image)
        }}
        onUpdateAddressModal={() => {
          setModalView('UpdateAddressList')
          setIsOpen(true)
        }}
        modalView='photoID'
      />

      <div
        className='importAddrList__banner'
        onClick={() => {
          setPhotoModalIsOpen(true)
        }}
      >
        <div className='importAddrList__banner__imgWrap'>
          <img
            className='importAddrList__bannerImg'
            src={toAbsoluteUrl('/media/design/icon_pink_upload.svg')}
          />

          <div className='importAddrList__bannerTxtWrap'>
            <span className='importAddrList__bannerTxt__instruction'>
              {translate('Import the electors list to the registration portal (CSV)')}
            </span>
            {/* <span className='importAddrList__bannerTxt__date'>Last Imported Nov 24, 2024</span> */}
          </div>
        </div>
        <button className='importAddrList__bannerBtn'> {translate('Import List')}</button>
      </div>

      {/* <span className='importAddrList__tableTitle'>Update History</span> */}

      {/* TODO add history */}
    </div>
  )
}

// TODO add back
//  {/* CONSOLE */}
//    <div className='importAddrList__tableContanier'>
//    {/* TABLE */}
//    <div className='voterAddrList__table'>
//      <div className='voterAddrList__tableHeader'>
//        <span className='importAddrList__tableHeader__columnLabel--1'>Date Imported</span>
//        <span className='importAddrList__tableHeader__columnLabel--2'>File Name</span>
//        <span className='importAddrList__tableHeader__columnLabel--3'>Status</span>
//      </div>

//      <div className='voterAddrList__tableBody'>
//        <div className='voterAddrList__tableBody__row'>
//          <span className='importAddrList__tableBody__rowItem--1'>
//            September 22, 2024 12:00:00 PM
//          </span>
//          <span className='importAddrList__tableBody__rowItem--2'>
//            Addresslist_sep-24-2022.json
//          </span>

//          <div className='importAddrList__tableBody__rowItem--3'>
//            <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
//              Successfuly Updated
//            </span>
//          </div>
//        </div>

//        <div className='voterAddrList__tableBody__row'>
//          <span className='importAddrList__tableBody__rowItem--1'>
//            September 22, 2024 12:00:00 PM
//          </span>
//          <span className='importAddrList__tableBody__rowItem--2'>
//            Addresslist_sep-24-2022.json
//          </span>

//          <div className='importAddrList__tableBody__rowItem--3'>
//            <span className='importAddrList__statusLabel importAddrList__statusLabel--failed'>
//              Import Failed
//            </span>
//          </div>
//        </div>

//        <div className='voterAddrList__tableBody__row'>
//          <span className='importAddrList__tableBody__rowItem--1'>
//            September 22, 2024 12:00:00 PM
//          </span>
//          <span className='importAddrList__tableBody__rowItem--2'>
//            Addresslist_sep-24-2022.json
//          </span>

//          <div className='importAddrList__tableBody__rowItem--3'>
//            <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
//              Successfuly Updated
//            </span>
//          </div>
//        </div>
//      </div>
//    </div>
//  </div>
