import civikApi from '../../../../utils/civikAPI'
import environment from '../../../../utils/environment'
import axios from 'axios'
// NOTE: IN PROGRESS: MAY CHANGE

const VoterRegistrationService = {
  sendSMSCode: async (phone, recaptchaToken) => {
    try {
      await axios.post(`${environment.resolveApi().voter_reg_rest}/registration/mfa/initiate/sms`, {
        phone,
        recaptchaToken,
      })
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  sendEmailCode: async (email, recaptchaToken) => {
    try {
      await axios.post(
        `${environment.resolveApi().voter_reg_rest}/registration/mfa/initiate/email`,
        {email, recaptchaToken}
      )
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifySMSCode: async (data) => {
    try {
      const result = await axios.post(
        `${environment.resolveApi().voter_reg_rest}/registration/mfa/verify/sms`,
        data
      )
      return {success: true, data: result.data.data}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyEmailCode: async (data) => {
    try {
      const result = await axios.post(
        `${environment.resolveApi().voter_reg_rest}/registration/mfa/verify/email`,
        data
      )
      return {success: true, data: result.data.data}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  answerSurvey: async (payload) => {
    try {
      await axios.put(
        `${environment.resolveApi().voter_reg_rest}/registration/${payload.id}`,
        payload
      )
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  // TODO future feature
  // requires firebase admin auth for now
  // getRegisteredVoterList: async () => {
  //   try {
  //     const result = await civikApi.get(`/voterlist/registeredvoters`)
  //     return {success: true, data: result}
  //   } catch (error) {
  //     console.log(error)
  //   }
  // },
}

export default VoterRegistrationService
