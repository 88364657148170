import TextField from '../../../../modules/auth/reusable/TextField'
import SelectField from '../../../../modules/auth/reusable/SelectField'
import './AddressInfo.scss'
import '../../pages/VoterRegister.scss'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'

import {useEffect, useState} from 'react'
import GooglePlacesAutoCompleteInput from '../../../../modules/reusable/GoogleAutocomplete'
import PlacesAutocomplete from '../../../../modules/reusable/GooglePlacesAutocomplete'
import {mapProvinceCodeToName} from '../../../reusable/province_mapper/ProvinceMapper'
import {useTranslation} from '../../../../hooks/useTranslation'

const provinces = [
  {value: 0, label: 'Province'},
  {value: 'Alberta', label: 'Alberta'},
  {value: 'British Columbia', label: 'British Columbia'},
  {value: 'Manitoba', label: 'Manitoba'},
  {value: 'New Brunswick', label: 'New Brunswick'},
  {value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador'},
  {value: 'Northwest Territories', label: 'Northwest Territories'},
  {value: 'Nova Scotia', label: 'Nova Scotia'},
  {value: 'Nunavut', label: 'Nunavut'},
  {value: 'Ontario', label: 'Ontario'},
  {value: 'Prince Edward Island', label: 'Prince Edward Island'},
  {value: 'Quebec', label: 'Quebec'},
  {value: 'Saskatchewan', label: 'Saskatchewan'},
  {value: 'Yukon', label: 'Yukon'},
]

export function AddressInfo(props) {
  const {formik, view, customStyling, onHasPropertyIgnace} = props
  const {translate} = useTranslation()

  const autofillAddress = (address) => {
    // Extract province from structured formatting
    const {structured_formatting} = address.value
    const {secondary_text} = structured_formatting

    // Split the secondary_text to get city, province, and postal code
    const [, provinceCode, postalCode] = secondary_text.split(', ')

    // Extract city from the first part of secondary_text
    const city = secondary_text.split(',')[0].trim()
    // Map the province code to the full name
    const provinceName = mapProvinceCodeToName(provinceCode)

    // Clear errors for the relevant field
    formik.setFieldError('streetAddress', '')

    // Use setTimeout to ensure correct order of state updates
    setTimeout(() => {
      // Update your formik values or perform any other actions here
      formik.setFieldValue('city', city)
      formik.setFieldValue('province', provinceName)
      formik.setFieldValue('postalCode', postalCode)
    }, 0)
  }

  const VoterRegister = (
    <>
      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          {/* <GooglePlacesAutoCompleteInput
            view='voter'
            formik={formik}
            type='text'
            field='streetAddress'
            placeholder='Street Address'
            label='Street Address'
            isRequired={true}
            types={['address']}
            onSelect={(address) => {
              autofillAddress(address)
            }}
            hideErrorLabel
          /> */}
          <PlacesAutocomplete
            view='voter'
            formik={formik}
            type='text'
            label={translate('Street Address')}
            isRequired={true}
            types={['address']}
            hideErrorLabel
          />

          {formik.errors['streetAddress'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['streetAddress']}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='unitNumberPOBox'
            placeholder='Unit Number'
            label='Unit #/P.O.Box'
            view='voter'
          />
        </div>
      </div>

      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='city'
              placeholder='City'
              label='City'
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <TextField
              formik={formik}
              type='text'
              field='province'
              placeholder='Province'
              label='State/Province'
              isRequired={true}
              options={provinces}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='postalCode'
              placeholder='Postal Code'
              label='Zip/Postal Code'
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.errors['city'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['city']}
              </span>
            </div>
          </div>
        )}
        {formik.errors['province'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['province']}
              </span>
            </div>
          </div>
        )}
        {formik.errors['postalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['postalCode']}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
  const AdminEdit = (
    <>
      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          <GooglePlacesAutoCompleteInput
            view='voter'
            formik={formik}
            type='text'
            field='streetAddress'
            placeholder='Street Address'
            label='Street Address'
            isRequired={true}
            types={['address']}
            onSelect={(address) => {
              autofillAddress(address)
            }}
          />

          {formik.errors['streetAddress'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['streetAddress']}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='unitNumber'
            placeholder='Unit Number'
            label='Unit #/P.O.Box'
            view='voter'
          />
        </div>
      </div>

      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='city'
              placeholder='City'
              label='City'
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <SelectField
              formik={formik}
              type='text'
              field='province'
              placeholder='Province'
              label='Province'
              isRequired={true}
              options={provinces}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='postalCode'
              placeholder='Postal Code'
              label='Postal Code'
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.errors['city'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['city']}
              </span>
            </div>
          </div>
        )}
        {formik.errors['province'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['province']}
              </span>
            </div>
          </div>
        )}
        {formik.errors['postalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['postalCode']}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )

  const renderView = () => {
    if (view == 'VoterRegister') {
      return VoterRegister
    }
    if (view == 'AdminEdit') {
      return AdminEdit
    }
  }

  return (
    <div className='voterRegister__addressRow' style={customStyling}>
      <div className='voterRegister__divider'>
        <span className={'voterRegister__dividerLabel'}>Current Residential Address</span>
      </div>

      {renderView()}
    </div>
  )
}
