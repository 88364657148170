export const districts = [
  {value: 'ward1', label: 'Ward 1'},
  {value: 'ward2', label: 'Ward 2'},
  {value: 'ward3', label: 'Ward 3'},
  {value: 'ward4', label: 'Ward 4'},
  {value: 'ward5', label: 'Ward 5'},
  {value: 'ward6', label: 'Ward 6'},
  {value: 'ward7', label: 'Ward 7'},
  {value: 'ward8', label: 'Ward 8'},
  {value: 'ward9', label: 'Ward 9'},
  {value: 'ward10', label: 'Ward 10'},
  {value: 'ward11', label: 'Ward 11'},
  {value: 'ward12', label: 'Ward 12'},
  {value: 'ward13', label: 'Ward 13'},
  {value: 'ward14', label: 'Ward 14'},
  {value: 'ward15', label: 'Ward 15'},
]
