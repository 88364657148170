import {useVoterStyles} from '../../../contexts/VoterStylesContext'

import './VoterModalFooter.scss'
import {useTranslation} from '../../../hooks/useTranslation'

export function ModalBtn(props) {
  const {translate} = useTranslation()

  let {onRequestClose, view, text} = props

  const {customBtnClass} = useVoterStyles()

  const CancelBtn = (
    <button type='submit' className={'modalFooter__cancelBtn'} onClick={props.onClick}>
      {translate(text)}
    </button>
  )

  const ActionBtn = (
    <button
      type='submit'
      className={
        customBtnClass
          ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
          : 'modalFooter__primaryBtn'
      }
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const renderView = () => {
    if (view == 'ActionBtn') {
      return ActionBtn
    }
    if (view == 'CancelBtn') {
      return CancelBtn
    }
  }

  return <div className='ModalBtn'>{renderView()}</div>
}
