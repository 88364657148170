import {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

import {Link, useHistory} from 'react-router-dom'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'

export function AdminSVG(props) {
  let {icon, inactive, isMobile} = props
  const {primaryColor} = useVoterStyles()

  // const strokeColor = {
  //   nova_scotia: '#7C3242',
  //   mexico: '#3c1b98',
  //   neuvote: '#3c1b98',
  // }

  const Dashboard = (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask id='path-1-inside-1_6833_4340' fill='white'>
        <rect x='13' y='11' width='9' height='11' rx='1' />
      </mask>
      <rect
        x='13'
        y='11'
        width='9'
        height='11'
        rx='1'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width={isMobile ? '1.5' : '3'}
        mask='url(#path-1-inside-1_6833_4340)'
      />
      <mask id='path-2-inside-2_6833_4340' fill='white'>
        <rect x='2' y='15' width='9' height='7' rx='1' />
      </mask>
      <rect
        x='2'
        y='15'
        width='9'
        height='7'
        rx='1'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width={isMobile ? '1.5' : '3'}
        mask='url(#path-2-inside-2_6833_4340)'
      />
      <mask id='path-3-inside-3_6833_4340' fill='white'>
        <rect x='2' y='2' width='9' height='11' rx='1' />
      </mask>
      <rect
        x='2'
        y='2'
        width='9'
        height='11'
        rx='1'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width={isMobile ? '1.5' : '3'}
        mask='url(#path-3-inside-3_6833_4340)'
      />
      <mask id='path-4-inside-4_6833_4340' fill='white'>
        <rect x='13' y='2' width='9' height='7' rx='1' />
      </mask>
      <rect
        x='13'
        y='2'
        width='9'
        height='7'
        rx='1'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width={isMobile ? '1.5' : '3'}
        mask='url(#path-4-inside-4_6833_4340)'
      />
    </svg>
  )

  const Users = (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
      <path
        d='M14.5703 5.42813C15.0013 5.31124 15.4457 5.25136 15.8922 5.25C17.1851 5.25 18.4251 5.76361 19.3393 6.67785C20.2536 7.59209 20.7672 8.83207 20.7672 10.125C20.7672 11.4179 20.2536 12.6579 19.3393 13.5721C18.4251 14.4864 17.1851 15 15.8922 15'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.5 18.5066C2.2612 17.4234 3.27191 16.5393 4.44676 15.9289C5.6216 15.3186 6.92608 15 8.25 15C9.57392 15 10.8784 15.3186 12.0532 15.9289C13.2281 16.5393 14.2388 17.4234 15 18.5066'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.8906 15C17.2147 14.9992 18.5194 15.3174 19.6944 15.9277C20.8693 16.5381 21.8799 17.4225 22.6406 18.5063'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )

  const MyProfile = (
    <svg width='27' height='27' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.98047 18.6938C6.54476 17.5824 7.40581 16.6488 8.46816 15.9968C9.53052 15.3447 10.7527 14.9995 11.9992 14.9995C13.2457 14.9995 14.4679 15.3447 15.5303 15.9968C16.5926 16.6488 17.4537 17.5824 18.018 18.6938'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )

  const Events = (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.6875 3.59375H4.3125C3.91555 3.59375 3.59375 3.91555 3.59375 4.3125V18.6875C3.59375 19.0845 3.91555 19.4062 4.3125 19.4062H18.6875C19.0845 19.4062 19.4062 19.0845 19.4062 18.6875V4.3125C19.4062 3.91555 19.0845 3.59375 18.6875 3.59375Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.8125 2.15625V5.03125'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.1875 2.15625V5.03125'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.59375 7.90625H19.4062'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.7344 11.5L10.5387 15.4531L8.26562 13.2969'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )

  const ElectorsList = (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.5 18.75C9.15685 18.75 10.5 17.4069 10.5 15.75C10.5 14.0931 9.15685 12.75 7.5 12.75C5.84315 12.75 4.5 14.0931 4.5 15.75C4.5 17.4069 5.84315 18.75 7.5 18.75Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3 21C3.52395 20.3014 4.20336 19.7344 4.98442 19.3438C5.76549 18.9533 6.62675 18.75 7.5 18.75C8.37325 18.75 9.23451 18.9533 10.0156 19.3438C10.7966 19.7344 11.476 20.3014 12 21'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.5 9C9.15685 9 10.5 7.65685 10.5 6C10.5 4.34315 9.15685 3 7.5 3C5.84315 3 4.5 4.34315 4.5 6C4.5 7.65685 5.84315 9 7.5 9Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3 11.25C3.52395 10.5514 4.20336 9.98438 4.98442 9.59385C5.76549 9.20332 6.62675 9 7.5 9C8.37325 9 9.23451 9.20332 10.0156 9.59385C10.7966 9.98438 11.476 10.5514 12 11.25'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.5 18.75C18.1569 18.75 19.5 17.4069 19.5 15.75C19.5 14.0931 18.1569 12.75 16.5 12.75C14.8431 12.75 13.5 14.0931 13.5 15.75C13.5 17.4069 14.8431 18.75 16.5 18.75Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12 21C12.524 20.3014 13.2034 19.7344 13.9844 19.3438C14.7655 18.9533 15.6267 18.75 16.5 18.75C17.3733 18.75 18.2345 18.9533 19.0156 19.3438C19.7966 19.7344 20.476 20.3014 21 21'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.5 9C18.1569 9 19.5 7.65685 19.5 6C19.5 4.34315 18.1569 3 16.5 3C14.8431 3 13.5 4.34315 13.5 6C13.5 7.65685 14.8431 9 16.5 9Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12 11.25C12.524 10.5514 13.2034 9.98438 13.9844 9.59385C14.7655 9.20332 15.6267 9 16.5 9C17.3733 9 18.2345 9.20332 19.0156 9.59385C19.7966 9.98438 20.476 10.5514 21 11.25'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )

  const AdminUsers = (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
      <path
        d='M14.5684 5.42813C14.9993 5.31124 15.4437 5.25136 15.8902 5.25C17.1832 5.25 18.4231 5.76361 19.3374 6.67785C20.2516 7.59209 20.7652 8.83207 20.7652 10.125C20.7652 11.4179 20.2516 12.6579 19.3374 13.5721C18.4231 14.4864 17.1832 15 15.8902 15'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.5 18.5066C2.2612 17.4234 3.27191 16.5393 4.44676 15.9289C5.6216 15.3186 6.92608 15 8.25 15C9.57392 15 10.8784 15.3186 12.0532 15.9289C13.2281 16.5393 14.2388 17.4234 15 18.5066'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.8906 15C17.2147 14.9992 18.5194 15.3174 19.6944 15.9277C20.8693 16.5381 21.8799 17.4225 22.6406 18.5063'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )

  const HelpAndSuport = (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.217 4.78214C16.4228 2.98795 14.0379 2 11.5 2C8.96205 2 6.57723 2.98795 4.78304 4.78214C2.98929 6.57589 2 8.96205 2 11.5C2 14.0379 2.98795 16.4241 4.78304 18.2179C6.57812 20.0116 8.96205 21 11.5 21C14.0379 21 16.4228 20.0121 18.217 18.2179C20.0107 16.4237 21 14.0379 21 11.5C21 8.96205 20.0121 6.57589 18.217 4.78214ZM17.8469 17.1402L15.708 15.0013C16.5299 14.0165 17 12.7996 17 11.5004C17 10.2013 16.5299 8.98527 15.708 7.99955L17.8469 5.86071C19.233 7.41786 20 9.39777 20 11.5009C20 13.604 19.233 15.5839 17.8469 17.1411V17.1402ZM11.5 16C10.2978 16 9.16786 15.5321 8.31786 14.6821C7.46786 13.8321 7 12.7022 7 11.5C7 10.2978 7.46786 9.16786 8.31786 8.31786C9.16786 7.46786 10.2978 7 11.5 7C12.7022 7 13.8321 7.46786 14.6821 8.31786C15.5321 9.16786 16 10.2978 16 11.5C16 12.7022 15.5321 13.8321 14.6821 14.6821C13.8321 15.5321 12.7022 16 11.5 16ZM17.1402 5.15312L15.0013 7.29196C14.0165 6.47009 12.7996 6 11.5004 6C10.2013 6 8.98527 6.47009 7.99955 7.29196L5.86071 5.15312C7.41786 3.76607 9.39777 3 11.5009 3C13.604 3 15.5839 3.76607 17.1411 5.15312H17.1402ZM5.15312 5.86027L7.29196 7.99911C6.47009 8.98393 6 10.2009 6 11.5C6 12.7991 6.47009 14.0152 7.29196 15.0009L5.15312 17.1397C3.76696 15.5826 3 13.6027 3 11.4996C3 9.39643 3.76696 7.41652 5.15312 5.85938V5.86027ZM5.86027 17.8478L7.99911 15.7089C8.98393 16.5308 10.2009 17.0009 11.5 17.0009C12.7991 17.0009 14.0152 16.5308 15.0009 15.7089L17.1397 17.8478C15.5826 19.2348 13.6027 20.0009 11.4996 20.0009C9.39643 20.0009 7.41652 19.2348 5.85937 17.8478H5.86027Z'
        fill={!inactive ? primaryColor : '#3F4254'}
      />
    </svg>
  )

  const Reports = (
    <svg width='27' height='27' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.0938 20.125H5.03125C4.84063 20.125 4.65781 20.0493 4.52302 19.9145C4.38823 19.7797 4.3125 19.5969 4.3125 19.4062V6.46875C4.3125 6.27813 4.38823 6.09531 4.52302 5.96052C4.65781 5.82573 4.84063 5.75 5.03125 5.75H12.2188L15.8125 9.34375V19.4062C15.8125 19.5969 15.7368 19.7797 15.602 19.9145C15.4672 20.0493 15.2844 20.125 15.0938 20.125Z'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.1875 5.75V3.59375C7.1875 3.40313 7.26323 3.22031 7.39802 3.08552C7.53281 2.95073 7.71563 2.875 7.90625 2.875H15.0938L18.6875 6.46875V16.5312C18.6875 16.7219 18.6118 16.9047 18.477 17.0395C18.3422 17.1743 18.1594 17.25 17.9688 17.25H15.8125'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.90625 13.6562H12.2188'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.90625 16.5312H12.2188'
        stroke={!inactive ? primaryColor : '#3F4254'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )

  let renderSVG = () => {
    if (icon == 'Dashboard') {
      return Dashboard
    }
    if (icon == 'Users') {
      return Users
    }
    if (icon == 'My Profile') {
      return MyProfile
    }
    if (icon == 'Events') {
      return Events
    }
    if (icon == 'Electors List') {
      return ElectorsList
    }
    if (icon == 'Admin Users') {
      return AdminUsers
    }
    if (icon == 'Help & Support') {
      return HelpAndSuport
    }
    if (icon == 'Reports') {
      return Reports
    }
  }

  return <>{renderSVG()}</>
}
