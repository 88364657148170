import {useHistory} from 'react-router-dom'
import './BackBtn.scss'

export const BackBtn = ({navigateTo, customStyling = {}, customOnBack = null}) => {
  const history = useHistory()
  return (
    <div
      style={customStyling}
      className='BackBtn'
      onClick={() => {
        if (customOnBack && customOnBack()) {
        } else {
          navigateTo ? history.push(navigateTo) : history.goBack()
        }
      }}
    >
      <span className='backBtn__label'>{'<'} Back</span>
    </div>
  )
}
