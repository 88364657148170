/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import './EventDisplayCard.scss'

import {toAbsoluteUrl} from '../../../../../../../../../_metronic/helpers'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import VoterAuthService from '../../../../../../../../services/auth/VoterAuthService'
import {useTranslation} from '../../../../../../../../hooks/useTranslation'

export function EventDisplayCard(props) {
  const {translate, translateObj} = useTranslation()

  let {title, closeDate, hasVoted} = props
  let propsEvent = props.event
  const history = useHistory()
  const [currentDays, setCurrentDays] = useState(null)
  const [currentHours, setCurrentHours] = useState(null)
  const [currentMinutes, setCurrentMinutes] = useState(null)
  const [currentSeconds, setCurrentSeconds] = useState(null)
  const [votingOpen, setvotingOpen] = useState(false)
  const [event, setEvent] = useState(propsEvent || {})

  closeDate = moment(closeDate)

  const getDays = () => {
    let now = moment()
    let days = closeDate.diff(now, 'days')
    if (days !== currentDays) {
      setCurrentDays(days)
    }
    return days
  }

  const getHours = () => {
    let now = moment()
    now = now.add(getDays(), 'days')
    let hours = closeDate.diff(now, 'hours')
    if (hours !== currentHours) {
      setCurrentHours(hours)
    }
    return hours
  }
  const getMinutes = () => {
    let now = moment()
    now = now.add(getDays(), 'days').add(getHours(), 'hours')
    let minutes = closeDate.diff(now, 'minutes')
    if (minutes !== currentMinutes) {
      setCurrentMinutes(minutes)
    }
    return minutes
  }
  const getSeconds = () => {
    let now = moment()
    now = now.add(getDays(), 'days').add(getHours(), 'hours').add(getMinutes(), 'minutes')
    let seconds = closeDate.diff(now, 'seconds')

    if (seconds !== currentSeconds) {
      setCurrentSeconds(seconds)
    }
  }

  useEffect(() => {
    const interval = setInterval(getSeconds, 1000)
    return () => clearInterval(interval)
  }, [])

  const fetchEvent = async () => {
    let updatedEvent = await VoterAuthService.getEventById(propsEvent.tiviId)
    if (updatedEvent) {
      setEvent(updatedEvent)
    }
  }
  // check if event state has changed to started at scheduled event open time
  const checkIsVoteTime = async () => {
    if (event.state === 'finalized') {
      let now = moment()
      let open = moment(event.openDate)
      let dif = open.diff(now, 'seconds')
      // 9 seconds seems to be how long it takes for the api to update the event state
      if (!votingOpen && dif <= -9 && dif > -12) {
        await fetchEvent()
      }
    }
  }
  useEffect(() => {
    let totalSecsLeft = closeDate.diff(moment(), 'seconds')
    if (totalSecsLeft < 0 && votingOpen) {
      setvotingOpen(false)
    } else if (event.state === 'started' && !votingOpen && totalSecsLeft > 0) {
      setvotingOpen(true)
    } else if (event.state !== 'started' && votingOpen) {
      setvotingOpen(false)
    }
    checkIsVoteTime()
  }, [currentSeconds, event.state])

  return (
    <div className='EventDisplayCard'>
      <div className='eventDisplayCard__body'>
        <img src={toAbsoluteUrl('/media/design/icon_pink_calendar_check.svg')} />

        <div className='eventDisplayCard__body__content'>
          <span className='eventDisplayCard__body__content__label'>
            {translate('You are entitled to vote in')}:
          </span>
          <span className='eventDisplayCard__body__content__title'>{translateObj(title)}</span>
          <span className='eventDisplayCard__body__content__label'>
            {translateObj(event.description)}
          </span>

          <div className='eventDisplayCard__body__content__dividerWrap'>
            <span className='eventDisplayCard__body__content__divider'>|</span>

            {votingOpen ? (
              <span className='dashboard__body__topBanner__voteStatus__value'>
                {translate('Voting is Open')}
              </span>
            ) : (
              <span className='dashboard__body__topBanner__voteStatus__value--notOpened'>
                {translate('Voting is not opened')}
              </span>
            )}
          </div>

          {votingOpen && (
            <div className='common__flex__row dashboard__votingStatusWrap'>
              <span className='eventDisplayCard__body__content__vtClosed__label'>
                {translate('Voting will be closed in')}:
              </span>
              <span className='eventDisplayCard__body__content__vtClosed__value'>
                {currentDays} <span className='common__fw400'>{translate('days')}</span>{' '}
                {currentHours} <span className='common__fw400'>{translate('h')}</span>{' '}
                {currentMinutes} <span className='common__fw400'>{translate('min')}</span>{' '}
                {currentSeconds} <span className='common__fw400'>{translate('s')}</span>
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className='eventDisplayCard__footer'
        onClick={() => {
          // onChangeView()
          history.push(`/election`, {event, hasVoted})
        }}
      >
        <span className='eventDisplayCard__footer__link'>{translate('Details')}</span>
      </div>
    </div>
  )
}
